import React, { useEffect, useState } from "react";
import "../CSS/06ContactUs.css";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ContactUsAPI } from "../../../ApiRouter";
import { useSelector } from "react-redux";

export const ContactUs = ({ closeModel }) => {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [valdMobile, setValdMobile] = useState("");
  const [valdEmail, setValdEmail] = useState("");
  const [valdName, setValdName] = useState("");
  const [isValid, setIsValid] = useState("");
  const [valdSubject, setValdSubject] = useState("");
  const [valdMessage, setValdMessage] = useState("");
  const {userid} = useSelector((state) => state.data);


  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "scroll");
  }, []);

  const navigate = useNavigate();
  const EmailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const MobileValidation = /^\d{10}$/;

  useEffect(() => {
    const fetchData = () => {
      if (
        mobile === "" ||
        email === "" ||
        name === "" ||
        subject === "" ||
        message === ""
      ) {
        if (mobile === "") {
          setValdMobile("");
        } else if (email === "") {
          setValdEmail("");
        } else if (name === "") {
          setValdName("");
        } else if (subject === "") {
          setValdSubject("");
        } else if (message === "") {
          setValdMessage("");
        }
      }
    };
    fetchData();
  }, [email, mobile, subject, name, message]);

  const validateMobile = () => {
    setValdMobile(MobileValidation.test(mobile));
  };

  const validateEmail = () => {
    setValdEmail(EmailValidation.test(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userid) {
      if (
        name.length !== 0 &&
        MobileValidation.test(mobile) &&
        EmailValidation.test(email) &&
        subject.length >= 5 &&
        message.length >= 10
      ) {
        const body = new FormData();
        body.append("userid", userid);
        body.append("name", name);
        body.append("mobile", mobile);
        body.append("email", email);
        body.append("subject", subject);
        body.append("comments", message);
        body.append("action", 4);
        const response = await axios.post(ContactUsAPI, body);
        console.log(response);
        if (response.data.success === true) {
          alert("our team contact you soon");
          navigate("/dashboard");
          closeModel();
        }
      } else {
        if (name.length === 0) {
          setValdName("Name is Required");
        }

        if (!MobileValidation.test(mobile)) {
          setValdMobile("Enter Valid Mobile");
        }
        if (!EmailValidation.test(email)) {
          setValdEmail("Enter Valid Email");
        }
        if (subject.length <= 5) {
          setValdSubject("Subject length must 5 digit ");
        }
        if (message.length <= 10) {
          setValdMessage("Message length must 5 digit ");
        }
      }
    } else {
      alert("please Login First");
    }
  };

  return (
    <>
      <div className="CU">
        <div className="CU-wraper" onClick={closeModel}></div>
        <div className="CU-content">
          <div className="CU-1st">
            <div className="CU-Heading">Contact Us</div>
            <div className="CU-close">
              <AiOutlineClose onClick={closeModel} />
            </div>
          </div>
          <div className="CU-2nd">
            <h1>How Can We Help ?</h1>
            <h3>Send us a message!</h3>
          </div>
          <form className="CU-3rd" onSubmit={handleSubmit}>
            <label className="CU-label">Name</label>
            <input
              type="text"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              className="CU-input"
            />
            {valdName !== "" && <p className="error"> {valdName} </p>}
            <label className="CU-label">Email Id</label>
            <input
              type="text"
              placeholder="Email Id"
              onChange={(e) => {
                setEmail(e.target.value);
                setValdEmail("");
              }}
              onBlur={validateEmail}
              className="CU-input"
            />
            {valdEmail !== "" && <p className="error">Enter Valid Email</p>}
            <label className="CU-label">Contact number</label>
            <input
              type="text"
              onChange={(e) => {
                setMobile(e.target.value);
                setValdMobile("");
              }}
              placeholder="Contact number"
              className="CU-input"
              onBlur={validateMobile}
            />
            {valdMobile !== "" && <p className="error">Enter Valid Mobile</p>}
            <label className="CU-label">Subject</label>
            <input
              type="text"
              placeholder="Subject"
              onChange={(e) => setSubject(e.target.value)}
              className="CU-input"
            />
            {valdSubject !== "" && <p className="error"> {valdSubject}</p>}
            <label className="CU-label">Message</label>
            <textarea
              className="CU-input"
              style={{ resize: "vertical" }}
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
              rows="2"
              cols="30"
            ></textarea>
            {valdMessage !== "" && <p className="error"> {valdMessage}</p>}

            <div className="CU-btn-div">
              <button className="CU-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
