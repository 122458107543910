import { useEditable } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import { feedbackUpdateAPI } from "../../../../ApiRouter";
const starData = {
  star1: "",
  star2: "",
  star3: "",
  star4: "",
  star5: "",
};

export const FeedbackConponent = ({ closeModel, id }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [Feedback, setFeedback] = useState("Move");
  const [message, setMessage] = useState("");
  const [star, setStar] = useState({ starData });
  const [ratingError, setRatingError] = useState("");

  const {userid} = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = () => {
      if (rating !== 0) {
        if (rating === 1) {
          setFeedback("VERY BAD");
        } else if (rating === 2) {
          setFeedback("BAD");
        } else if (rating === 3) {
          setFeedback("AVERAGE");
        } else if (rating === 4) {
          setFeedback("GOOD");
        } else if (rating === 5) {
          setFeedback("VERY GOOD");
        }
      } else {
        setFeedback("");
      }
    };
    fetchData();
  }, [rating]);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating > 0 && rating < 5) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("orderid", id);
      body.append("fbstatus", rating);
      body.append("fbtext", message);
      const response = await axios.post(feedbackUpdateAPI, body);
      console.log(response);
      if (response.data.success === true) {
        alert("feedback is submitted");
        closeModel();
      }
    } else {
      setRatingError("Please Select Rating First");
      alert("Please Select Rating First");
    }
  };

  return (
    <div>
      <div
        className="wraper"
        onClick={closeModel}
        style={{ zIndex: "10" }}
      ></div>
      <div className="dash-content" style={{ zIndex: "10" }}>
        <form onSubmit={handleSubmit}>
          <div className="log-content-head">
            <div className="log-content-head-1st">
              <div>Feedback</div>
            </div>
            <div className="log-content-close">
              <AiOutlineClose onClick={closeModel} />
            </div>
          </div>
          <div className="Feed-main">
            <div className="feed-result">{Feedback}</div>
            <div className="feed-icons">
              {[...Array(5)].map((star, index) => {
                const starValue = index + 1;
                return (
                  <label key={index}>
                    <FaStar
                      className="feed-icon"
                      color={
                        starValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                      }
                      size={30}
                      value={starValue}
                      onClick={() => handleStarClick(starValue)}
                      onMouseEnter={() => setHover(starValue)}
                      onMouseLeave={() => setHover(null)}
                    />
                  </label>
                );
              })}
            </div>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              className="feed-input"
              style={{ resize: "vertical", width: "auto" }}
              placeholder="Tell us what you loved..."
              rows="2"

              // cols="30"
            ></textarea>
          </div>
          <div className="Feed-submit-div">
            <button className="Feed-submit">Submit Feedback</button>
          </div>
        </form>
      </div>
    </div>
  );
};
