import React, { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import MyMap from "./myMap";
import axios from "axios";
import { completeListAPI, createAddressAPI } from "../../../../ApiRouter";
import {
  MobileNumberValidator,
  PinCodeValidator,
  StringValidator,
} from "../../../../Validator";
import { useSelector } from "react-redux";
import { Input, Select } from "../../../../Comp/Input/Input";
const EditAddress = ({ data, closeEditAddress }) => {
  const [showMap, setShowMap] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const [obj, setObj] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    StateCode: "",
    District: "",
    PinCode: "",
    Addressid: "",
  });
  const [objErr, setObjErr] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    District: "",
    PinCode: "",
    Addressid: "",
  });

  const { userid } = useSelector((state) => state.data);

  const [LLDir, setLLDir] = useState([]);

  const latLong = data.latlong;
  const [lat, long] = latLong.split(",").map(parseFloat);

  const handlSeteLatlong = (e) => {
    setObj({ ...obj, Latlong: e });
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
      console.log(response);
      const arr = response.data.data.reponselist.states;
      console.log(arr);
      const st = arr.filter((item) => item.name == data.state.toUpperCase())[0];
      console.log(data);
      console.log(st.code);
      if (st) {
        const ft = st.code;
        setObj((prevObj) => ({
          ...prevObj,
          StateCode: ft,
          State: data.state.toUpperCase(),
        }));
      } else {
        console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", obj.StateCode);
      const response = await axios.post(completeListAPI, body);
      setDistrictList(response.data.data.reponselist.districts);
    };
    fetchData();
  }, [obj.StateCode]);

  useEffect(() => {
    const fetchData = () => {
      setObj((prevObj) => ({
        ...prevObj,
        InchargeName: data.name,
        InchargeMobile: data.mobile,
        Address: data.address,
        Latlong: data.latlong,
        Landmark: data.landmark,
        District: data.district,
        PinCode: data.pincode,
        Addressid: data.id,
      }));
    };
    fetchData();
  }, [stateList]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      MobileNumberValidator(obj.InchargeMobile) === true &&
      StringValidator(obj.InchargeName, "InchargeName", 3, 20) === true &&
      StringValidator(obj.Address, "Address", 24, 1000) === true &&
      StringValidator(obj.State, "State", 1, 50) === true &&
      StringValidator(obj.District, "District", 1, 50) === true &&
      PinCodeValidator(obj.PinCode, "PinCode") === true &&
      StringValidator(obj.Latlong, "Latlong", 1) === true
    ) {
      const body = new FormData();
      body.append("name", obj.InchargeName);
      body.append("mobile", obj.InchargeMobile);
      body.append("address", obj.Address);
      body.append("userid", userid);
      body.append("addressid", obj.Addressid);
      body.append("landmark", obj.Landmark);
      body.append("state", obj.State);
      body.append("district", obj.District);
      body.append("pincode", obj.PinCode);
      body.append("latlong", obj.Latlong.lat + "," + obj.Latlong.lng);
      const response = await axios.post(createAddressAPI, body);
      if (response.data.success === true) {
        closeEditAddress();
      }
    } else {
      setObjErr({
        ...objErr,
        InchargeMobile:
          MobileNumberValidator(obj.InchargeMobile) !== true
            ? MobileNumberValidator(obj.InchargeMobile)
            : "",
        InchargeName:
          StringValidator(obj.InchargeName, "InchargeName", 3, 20) !== true
            ? StringValidator(obj.InchargeName, "InchargeName", 3, 20)
            : "",
        Address:
          StringValidator(obj.Address, "Address", 24, 1000) !== true
            ? StringValidator(obj.Address, "Address", 24, 1000)
            : "",
        State:
          StringValidator(obj.State, "State", 1, 50) !== true
            ? "State is required"
            : "",
        District:
          StringValidator(obj.District, "District", 1, 50) !== true
            ? "District is required"
            : "",
        PinCode:
          PinCodeValidator(obj.PinCode, "PinCode") !== true
            ? PinCodeValidator(obj.PinCode, "PinCode")
            : "",
        Latlong:
          StringValidator(obj.Latlong, "Latlong", 1) !== true
            ? "Point Shipping Address on Map"
            : "",
      });
    }
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit} className="Add-form">
        <Input
          value={obj.InchargeName}
          onChange={(e) => {
            setObj({ ...obj, InchargeName: e.target.value });
            setObjErr({ ...objErr, InchargeName: "" });
          }}
          error={objErr.InchargeName}
          lable={"Incharge Name "}
        />
        <Input
          value={obj.InchargeMobile}
          onChange={(e) => {
            setObj({ ...obj, InchargeMobile: e.target.value });
            setObjErr({ ...objErr, InchargeMobile: "" });
          }}
          error={objErr.InchargeMobile}
          lable={"Incharge Mobile "}
        />

        <Input
          value={obj.Address}
          onChange={(e) => {
            setObj({ ...obj, Address: e.target.value });
            setObjErr({ ...objErr, Address: "" });
          }}
          error={objErr.Address}
          lable={"Address"}
        />

        <Input
          value={obj.Landmark}
          onChange={(e) => {
            setObj({ ...obj, Landmark: e.target.value });
            setObjErr({ ...objErr, Landmark: "" });
          }}
          error={objErr.Landmark}
          lable={"Landmark"}
        />

        <Select
          value={obj?.State}
          onChange={(e) => {
            const selectedState = stateList.find(
              (item) => item.name === e.target.value
            );
            const response = selectedState ? selectedState.code : "";
            setObj({ ...obj, State: response });
            setObjErr({ ...objErr, State: "" });
          }}
          lable={"State"}
          error={objErr.State}
        >
          <option hidden>
            {() => {
              const selectedState = stateList.find(
                (item) => item.name === obj.State
              );
              const response = selectedState ? selectedState.code : "";
              return response;
            }}
          </option>
          {stateList.length > 0 &&
            stateList.map((item, i) => (
              <option key={i} value={item.name}>
                {item.name}
              </option>
            ))}
        </Select>

        <Select
          value={obj?.District}
          onChange={(e) => {
            setObj({ ...obj, District: e.target.value });
            setObjErr({ ...objErr, District: "" });
          }}
          lable={"District"}
          error={objErr.District}
        >
          <option hidden>{obj.District}</option>
          {districtList.length > 0 &&
            districtList.map((item, i) => (
              <option key={i} value={item.name}>
                {item.name}
              </option>
            ))}
        </Select>

        <Input
          value={obj.PinCode}
          onChange={(e) => {
            setObj({ ...obj, PinCode: e.target.value });
            setObjErr({ ...objErr, PinCode: "" });
          }}
          error={objErr.PinCode}
          lable={"Pin Code"}
        />

        <div className="Add-form-div">
          <p className="visiblity-none">np </p>
          <button
            className="Add-map-btn"
            onClick={(e) => {
              setShowMap(!showMap);
              e.preventDefault();
            }}
          >
            <HiOutlineLocationMarker style={{ fontSize: "20px" }} />
            <p>Point on Map </p>
          </button>
          {objErr.Latlong !== "" && <p className="error">{objErr.Latlong}</p>}
        </div>
        {showMap && (
          <div className="Add-map-div-2">
            <MyMap
              handlSeteLatlong={handlSeteLatlong}
              data={{ lat: lat, long: long }}
            />
          </div>
        )}
        <div className="Add-update-div">
          <button className="Add-update-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};
export default EditAddress;

// address: "Flat No 202, Second Floor, Plot No 42 samthapuri Colony Nagole, Art Satyanarayana Bhavan, Hyderabad ";
// branch: "Hyderabad 1";
// district: "Medchal Malkajgiri";
// email: "raju.nataraju66@gmail.com";
// gst: "36AAECV8279M1ZG";
// id: 27;
// legalname: "VERAMASA IT AND EDUCATION CONSULTANCY PRIVATE LIMITED";
// mobile: "9032841548";
// pan: "AAECV8279M";
// pincode: "500035";
// state: "Telangana";
// tradename: "VERAMASA IT AND EDUCATION CONSULTANCY PRIVATE LIMITED";
// userid: "12345678";

// userid: 9
// branchid: 27
// email: raju.nataraju66@gmail.com
// mobile: 9032841548
// gst: 36AAECV8279M1ZG
// pan: AAECV8279M
// legalname: VERAMASA IT AND EDUCATION CONSULTANCY PRIVATE LIMITED
// tradename: VERAMASA IT AND EDUCATION CONSULTANCY PRIVATE LIMITED
// address: FLAT NO 301, PLOT NO 112, Gayathri Nagar Road, ART LAKSHMI RESIDENCY, Hyderabad
// state: 36
// state: Telangana
// district: Medchal
// pincode: 500018
// branch: Hyderabad
