import React, { useEffect, useState } from "react";
import { GrEdit } from "react-icons/gr";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { changePasswordAPI, profileAPI } from "../../../ApiRouter";
import { Input } from "../../../Comp/Input/Input";

export const Authentication = () => {
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [showPassword, setShowPassword] = useState("");
  const [showPassword1, setShowPassword1] = useState("");
  const [showPassword2, setShowPassword2] = useState("");
  const [regUser, setRegUser] = useState("");
  const [profileData, setProfileData] = useState({
    mobile: "",
    email: "",
    email2: "",
    userId: "",
    currPass: "",
    newPass: "",
    confPass: "",
  });
  const [objErr, setObjErr] = useState({
    mobile: "",
    email: "",
    email2: "",
    userId: "",
    currPass: "",
    newPass: "",
    confPass: "",
  });

  console.log(profileData);

  const EmailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const MobileValidation = /^\d{10}$/;

  const { userid } = useSelector((state) => state.data);
  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(profileAPI, body);
      const resData = response.data.data;
      console.log(response.data.data);
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        mobile: resData[0].mobile,
        email: resData[0].email,
        email2: resData[0].email2,
        userId: resData[0].userid,
      }));
      setRegUser(resData[0].userid);

      setObjErr((prevObjErr) => ({
        ...prevObjErr,
        mobile: "",
        email: "",
        email2: "",
        userId: "",
        currPass: "",
        newPass: "",
        confPass: "",
      }));
    };
    fetchData();
  }, [showProfileUpdate]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChangePass = async (e) => {
    e.preventDefault();
    if (
      profileData.currPass.length >= 6 &&
      profileData.newPass.length >= 6 &&
      profileData.confPass.length >= 6 &&
      profileData.newPass === profileData.confPass &&
      profileData.currPass !== profileData.confPass
    ) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("password", profileData.currPass);
      body.append("newpassword", profileData.newPass);
      body.append("ingress", 4);
      const response = await axios.post(changePasswordAPI, body);
      console.log(response);
      if (response.data.success === true) {
        alert(response.data.message);
        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          currPass: "",
          newPass: "",
          confPass: "",
        }));
      } else if (response.data.success === false) {
        alert(response.data.message);
      }
    } else {
      if (profileData.currPass.length < 6) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          currPass: "Current Password require min 6 digit",
        }));
      }
      if (profileData.newPass.length < 6) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          newPass: "New Password require min 6 digit",
        }));
      }
      if (profileData.confPass.length < 6) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          confPass: "Conform Password require min 6 digit",
        }));
      }
      if (profileData.newPass !== profileData.confPass) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          confPass: "New Passwoord or Conform Password  is not same ",
          newPass: "New Passwoord or Conform Password  is not same ",
        }));
      }
      if (profileData.currPass === profileData.confPass) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          currPass: "Current Passwoord or New Password  is same ",
        }));
      }
    }
  };
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (
      EmailValidation.test(profileData.email) &&
      MobileValidation.test(profileData.mobile) &&
      (EmailValidation.test(profileData.email2) ||
        profileData.email2.length === 0)
    ) {
      console.log(profileData);
    } else {
      if (!EmailValidation.test(profileData.email)) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          email: "Invalid Email",
        }));
      }
      if (!MobileValidation.test(profileData.mobile)) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          mobile: "Invalid Mobile",
        }));
      }
      if (
        !EmailValidation.test(profileData.email2) &&
        profileData.email2.length !== 0
      ) {
        setObjErr((prevObjErr) => ({
          ...prevObjErr,
          email2: "Invalid Email",
        }));
      }
    }
  };

  return (
    <div className="Auth">
      <div className="Auth-edit">
        <button
          className="Auth-edit-btn"
          onClick={() => {
            setShowProfileUpdate(!showProfileUpdate);
            setChangePass(false);
          }}
        >
          {showProfileUpdate ? (
            <i>Cancel</i>
          ) : (
            <>
              <GrEdit />
              <i>Edit</i>
            </>
          )}
        </button>
      </div>
      <form onSubmit={handleSubmit} className="Auth-form">
        <Input
          value={profileData.mobile}
          lable={"Mobile"}
          onChange={(e) => {
            setProfileData((prevProfileData) => ({
              ...prevProfileData,
              mobile: e.target.value,
            }));
            setObjErr((prevObjErr) => ({
              ...prevObjErr,
              mobile: "",
            }));
          }}
          readOnly={!showProfileUpdate}
          error={objErr?.mobile}
        />

        <Input
          lable={"Primary Email ID"}
          value={profileData.email}
          onChange={(e) => {
            setProfileData((prevProfileData) => ({
              ...prevProfileData,
              email: e.target.value,
            }));

            setObjErr((prevObjErr) => ({
              ...prevObjErr,
              email: "",
            }));
          }}
          readOnly={!showProfileUpdate}
          error={objErr.email}
        />
        <Input
          lable={"Secondary Email ID"}
          value={profileData.email2}
          onChange={(e) => {
            setProfileData((prevProfileData) => ({
              ...prevProfileData,
              email2: e.target.value,
            }));
            {
              setObjErr((prevObjErr) => ({
                ...prevObjErr,
                email2: "",
              }));
            }
          }}
          error={objErr.email2}
          readOnly={!showProfileUpdate}
        />
        <Input
          lable={"User ID"}
          value={profileData.userId}
          onChange={(e) =>
            setProfileData((prevProfileData) => ({
              ...prevProfileData,
              userId: e.target.value,
            }))
          }
          readOnly={showProfileUpdate ? (regUser === "" ? false : true) : true}
        />
        {showProfileUpdate && (
          <div className="Auth-update-div">
            <button className="Auth-update-btn" onClick={handleUpdateProfile}>
              Update
            </button>
          </div>
        )}
      </form>

      {!showProfileUpdate && (
        <div className="Auth-pass-div">
          <button
            className="Auth-pass-btn"
            style={{ backgroundColor: changePass ? "gray" : "" }}
            onClick={() => setChangePass(!changePass)}
          >
            {changePass
              ? "Cancel"
              : regUser !== ""
              ? "Change Password"
              : "Create Password"}
          </button>
        </div>
      )}

      {changePass && (
        <div className="CP">
          <form
            className="CP-form scroll-in-animation "
            onSubmit={handleChangePass}
          >
            {regUser !== "" && (
              <Input
                lable={"Current Password"}
                type={showPassword ? "text" : "password"}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                value={profileData.currPass}
                onChange={(e) => {
                  setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    currPass: e.target.value,
                  }));
                  {
                    setObjErr((prevObjErr) => ({
                      ...prevObjErr,
                      currPass: "",
                    }));
                  }
                }}
                error={objErr.currPass}
                pass={true}
              />
            )}

            <div className="CP-form-div-2">
              <Input
                lable={"New Password"}
                showPassword={showPassword1}
                setShowPassword={setShowPassword1}
                type={showPassword1 ? "text" : "password"}
                value={profileData.newPass}
                onChange={(e) => {
                  setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    newPass: e.target.value,
                  }));
                  {
                    setObjErr((prevObjErr) => ({
                      ...prevObjErr,
                      newPass: "",
                    }));
                  }
                }}
                error={objErr.newPass}
                pass={true}
              />

              <Input
                lable={"Confirm Password"}
                showPassword={showPassword2}
                setShowPassword={setShowPassword2}
                type={showPassword2 ? "text" : "password"}
                value={profileData.confPass}
                onChange={(e) => {
                  setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    confPass: e.target.value,
                  }));
                  {
                    setObjErr((prevObjErr) => ({
                      ...prevObjErr,
                      confPass: "",
                    }));
                  }
                }}
                error={objErr.confPass}
                pass={true}
              />
            </div>
            <div className="CP-update-div">
              <button className="CP-update-btn">
                {regUser !== "" ? "Update Password" : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
