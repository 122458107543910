import { Button, Modal } from "antd";
import React from "react";
import { useState } from "react";
import "../../App.css";
import ReactDOM from "react-dom";
import { motion, useMotionValue, useTransform } from "framer-motion";

export const LoginPopUp = ({ handleSuccessPopup, Message }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let progress = useMotionValue(90);
  return (
    <div>
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: 100 }}
        style={{ x: progress }}
        transition={{ duration: 1 }}
      />
      <div className="log-pop-ok-div">
        <CircularProgress progress={progress} />
      </div>
      <div className="log-pop-ok-div" style={{ fontSize: "20px" }}>
        {Message}
      </div>
      <div className="log-pop-ok-div">
        <button onClick={handleSuccessPopup} className="log-pop-ok">
          Ok
        </button>
      </div>
    </div>
  );
};

function CircularProgress({ progress }) {
  const circleLength = useTransform(progress, [0, 100], [0, 1]);
  const checkmarkPathLength = useTransform(progress, [0, 95, 100], [0, 0, 1]);
  const circleColor = useTransform(
    progress,
    [0, 95, 100],
    ["#FFCC66", "#FFCC66", "#66BB66"]
  );

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 258 258"
    >
      {/* Check mark  */}
      <motion.path
        transform="translate(60 85)"
        d="M3 50L45 92L134 3"
        fill="transparent"
        stroke="#7BB86F"
        strokeWidth={8}
        style={{ pathLength: checkmarkPathLength }}
      />
      {/* Circle */}
      <motion.path
        d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
        fill="transparent"
        strokeWidth="8"
        stroke={circleColor}
        style={{
          pathLength: circleLength,
        }}
      />
    </motion.svg>
  );
}

// import { Button, Modal } from "antd";
// import React from "react";
// import { useState } from "react";
// import "../../App.css";
// import ReactDOM from "react-dom";
// import { motion, useMotionValue, useTransform } from "framer-motion";

// export const LoginPopUp = ({handleLoginSuccess}) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   let progress = useMotionValue(90);
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div >
//       <Button type="primary" onClick={showModal}>
//         Open Modal
//       </Button>

//       <Modal
//       centered={true}
//       className="center"
//         footer={null}
//         header={null}
//         closeIcon={false}
//         width="30%"
//         style={{
//           translate: "transform(-50% -50%)",
//         }}
//         closable="false"
//         open={isModalOpen}
//         okButtonProps="false"
//         onCancel={() => setIsModalOpen(false)}
//       >
//         <motion.div
//           initial={{ x: 0 }}
//           animate={{ x: 100 }}
//           style={{ x: progress }}
//           transition={{ duration: 1 }}
//         />
//        <div className="log-pop-ok-div"> <CircularProgress progress={progress} /></div>
//         <div className="log-pop-ok-div"><button className="log-pop-ok">Ok</button></div>
//       </Modal>
//     </div>
//   );
// };

// function CircularProgress({ progress }) {
//   const circleLength = useTransform(progress, [0, 100], [0, 1]);
//   const checkmarkPathLength = useTransform(progress, [0, 95, 100], [0, 0, 1]);
//   const circleColor = useTransform(
//     progress,
//     [0, 95, 100],
//     ["#FFCC66", "#FFCC66", "#66BB66"]
//   );

//   return (
//     <motion.svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="120"
//       height="120"
//       viewBox="0 0 258 258"
//     >
//       {/* Check mark  */}
//       <motion.path
//         transform="translate(60 85)"
//         d="M3 50L45 92L134 3"
//         fill="transparent"
//         stroke="#7BB86F"
//         strokeWidth={8}
//         style={{ pathLength: checkmarkPathLength }}
//       />
//       {/* Circle */}
//       <motion.path
//         d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
//         fill="transparent"
//         strokeWidth="8"
//         stroke={circleColor}
//         style={{
//           pathLength: circleLength,
//         }}
//       />
//     </motion.svg>
//   );
// }
