import React, { useEffect, useRef, useState } from "react";
import { GoSearch } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { ProductSearch } from "../../ApiRouter";
import axios from "axios";
import { useSelector } from "react-redux";
import useDebounce from "../../Hook/Debouncing";

export const SearchBar = () => {
  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const {userid} = useSelector((state) => state.data);
  const debouncedSearchItem = useDebounce(inputValue, 1000);  
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setProductList([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [debouncedSearchItem]);

  const fetchData = async () => {
    if (inputValue?.length > 2) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("query", inputValue);
      const response = await axios.post(ProductSearch, body);
      setProductList(response.data.data);
    } else {
      setProductList([]);
    }
  };

  return (
    <div>
      <div className="nav-search-div">
        <input
          type="text"
          placeholder="Search Gaswale"
          className="nav-search"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />

        <div className=" nav-search-btn">
          <GoSearch />
        </div>
      </div>
      <>
        {productList.length > 0 ? (
          <div className="nav-search-drop" ref={ref}>
            {productList.map((item,i) => (
              <div
                key={i}
                onClick={() => {
                  navigate("/itemFullDetails", { state: item.uuid });
                  setProductList([]);
                  setInputValue("");
                }}
              >
                <img src={item.url} className="nav-search-img"></img>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
};
