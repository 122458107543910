import { Switch, Tooltip } from "antd";
import React from "react";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import { BsChatRightText, BsFileEarmarkPdf, BsRepeat } from "react-icons/bs";
import { FaStar } from "react-icons/fa";

export const AddressCard = ({ onEdit, onDelete, item, onChange,checked }) => {
  return (
    <div className="Ship-03rd" style={{justifyContent:"space-between"}}>
      <div >
        <div className="Ship-03rd1">
          <div className="Ship-03rd1-title">Address</div>
          <div className="Ship-03rd2-icon">
            <Tooltip title="Edit">
              <GrEdit className="Ship-icons1" onClick={onEdit} />
            </Tooltip>
            <Tooltip title="Delete">
              <RiDeleteBin5Line onClick={onDelete} className="Ship-icons2" />
            </Tooltip>
          </div>
        </div>
        <div className="Ship-03rd2">
          <div>{item.name}</div>
          <div>{item.mobile}</div>
          <div>{item.address}</div>
        </div>
      </div>
      <div className="Ship-03rd3">
        <div className="Ship-03rd3-tittle">Shipping</div>
        <div className="Ship-03rd3-switch">
          <Switch size="small" checked={checked} defaultChecked onChange={onChange} />
        </div>
      </div>
    </div>
  );
};



export const MyOrderCard = ({item,onView,onRepeat,onDownload,onRating}) => {
  return (
    <div className="AO-OL-card" >
      <div className="AO-OL-card-item">
        <p style={{ color: "rgba(0, 0, 0, 0.493)" }}>Order</p>
        <p>#{item.orderid}</p>
      </div>
      <div className="AO-OL-card-item">
        <p style={{ color: "rgba(0, 0, 0, 0.493)" }}>Date</p>
        <p> {moment(item.datetime).format("DD/MM/YYYY hh:mm A")}</p>
      </div>
      <div className="AO-OL-card-item">
        <p style={{ color: "rgba(0, 0, 0, 0.493)" }}>Order Status</p>
        <p>{item.orderstatustext}</p>
      </div>
      <div className="AO-OL-card-item">
        <p style={{ color: "rgba(0, 0, 0, 0.493)" }}>Payment Mode</p>
        <p>{item.paymentmode}</p>
      </div>
      <div className="AO-OL-card-icon123">
        <AiOutlineEye
          style={{ color: "#0d6efd", cursor: "pointer" }}
          onClick={onView}
        />
        <BsRepeat
          style={{ cursor: "pointer" }}
          onClick={onRepeat}
        />
        <BsFileEarmarkPdf
          onClick={onDownload}
          style={{ color: "#dc3545", cursor: "pointer" }}
        />
        {item.feedbackstatus != 0 ? (
          <div className="AO-star-div">
            <FaStar className="AO-star" />
            {item.feedbackstatus}
          </div>
        ) : (
          <BsChatRightText
            onClick={onRating}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
};

export const QuotationCard = ({item,onClick}) => {
  return (
    <div className="Qut-item">
    <div>
      <div className="Qut-ord">#{item.quid}</div>
      <div className="Qut-prd">
        Products : {item.products.length}
      </div>
    </div>
    <div>
      <AiOutlineEye
        className="OH-icons"
        style={{ color: "#0d6efd", cursor: "pointer" }}
        onClick={onClick}
      />
    </div>
  </div>
  )
}