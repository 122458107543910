import React, { useState } from "react";
import gaswaleLogo from "../../Assets/Images/logo/gaswale-logo1.png";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import { PiCaretDownBold, PiShoppingCartLight } from "react-icons/pi";
import { AiFillCaretRight, AiOutlineMenuUnfold } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { BsBell } from "react-icons/bs";
import "./01Header.css";
import { Location } from "./SmallComponent/Location";
import { Login } from "./SmallComponent/Login";
import { ContactUs } from "./SmallComponent/ContactUs";
import { Drawer, Menu } from "antd";
// import {
//   AppstoreOutlined,
//   MailOutlined,
//   SettingOutlined,
// } from "@ant-design/icons";
import { ProductSearch } from "../../ApiRouter";
import axios from "axios";
// import { get_show_login } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "../../Comp/SearchBar/SearchBar";
import store from "../../Redux/store";
import { get_show_login, get_userid } from "../../Redux/DataSlice";

const { SubMenu } = Menu;

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  // const [showLogin, get_show_login] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [location, setLocation] = useState(null);
  const [productHovered, setProductHovered] = useState(false);
  const [productLPGHovered, setProductLPGHovered] = useState(false);
  const [userHovered, setUserHovered] = useState(false);
  const [userHovered2, setUserHovered2] = useState(false);

  // const dispatch = useDispatch();
  const { showLogin, userid } = useSelector((state) => state.data);

  const navigate = useNavigate();
  const handleClick = (e) => {};

  const handleItemDetails = (type) => {
    setShowMenu(false);
    navigate("/itemDetails", { state: type });
  };

  const closeModel = () => {
    setShowLocation(false);
    store.dispatch(get_show_login(false));
    setShowHelp(false);
  };

  const LocationName = (value) => {
    setLocation(value);
    setShowLocation(false);
  };

  const handleLogo = () => {
    setShowMenu(false);
    if (userid) {
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const handleShowHelp = () => {
    setShowMenu(false);
    setShowHelp(!showHelp);
  };

  const handleShowLogin = () => {
    setShowMenu(false);
    store.dispatch(get_show_login(!showLogin));
  };

  const handleShowLocation = () => {
    setShowMenu(false);
    setShowLocation(!showLocation);
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
    setProductHovered(false);
    setProductLPGHovered(false);
  };

  const handleHelp = () => {
    navigate("/help");
  };

  const handleLogout = () => {
    setUserHovered(false);
    localStorage.clear();
    store.dispatch(get_userid(""));
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <div className="nav-main">
        <div className="nav-logo-div">
          <img
            src={gaswaleLogo}
            alt="logo"
            onClick={handleLogo}
            className="nav-logo"
          />
        </div>
        {/* <div className="nav-search-div">
          <input
            type="text"
            placeholder="Search Gaswale"
            className="nav-search"
            value={inputValue}
            onChange={(e) => {
              e.target.value.length > 2
                ? fetchData(e.target.value)
                : setProductList([]);
              setInputValue(e.target.value);
            }}
          />

          <div className=" nav-search-btn">
            <GoSearch />
          </div>
        </div>
        <>
          {productList.length > 0 ? (
            <div className="nav-search-drop">
              {productList.map((item) => (
                <div
                  key={item.uuid}
                  onClick={() => {
                    navigate("/itemFullDetails", { state: item.uuid });
                    setProductList([]);
                    setInputValue("");
                  }}
                >
                  <img src={item.url} className="nav-search-img"></img>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </> */}
        <SearchBar />
        <Menu
          style={{ minWidth: "40vw" }}
          onClick={handleClick}
          className="nav-link-ul"
          mode="horizontal"
        >
          <Menu.Item
            key="Location"
            onClick={handleShowLocation}
            className="nav-link-li"
            icon={<PiCaretDownBold className="caretUp" />}
          >
            {location !== null ? <>{location}</> : "Location"}
          </Menu.Item>
          <Menu.Item
            className="nav-link-li"
            key="home"
            onClick={() => navigate("/")}
          >
            Home
          </Menu.Item>
          {userid && (
            <Menu.Item
              className="nav-link-li"
              onClick={() => navigate("/dashboard/ActiveOrder")}
              key="dashboard"
            >
              Dashboard
            </Menu.Item>
          )}
          <SubMenu
            key="products"
            className="nav-link-li position"
            title="Products"
          >
            <SubMenu key="lpg" title="LPG">
              <Menu.Item key="All" onClick={() => handleItemDetails("All")}>
                All
              </Menu.Item>
              <Menu.Item key="HPCL" onClick={() => handleItemDetails("HPCL")}>
                HPCL
              </Menu.Item>
              <Menu.Item key="BPCL" onClick={() => handleItemDetails("BPCL")}>
                BPCL
              </Menu.Item>
              <Menu.Item key="IOCL" onClick={() => handleItemDetails("IOCL")}>
                IOCL
              </Menu.Item>
              <Menu.Item key="PMs" onClick={() => handleItemDetails("PMs")}>
                PMs
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="IndustrialGases">Industrial Gases</Menu.Item>
            <Menu.Item key="spares">Spares</Menu.Item>
            <Menu.Item key="equipment">Equipment</Menu.Item>
          </SubMenu>
          <Menu.Item
            className="nav-link-li"
            key="service"
            onClick={() => {
              navigate("/service");
              setShowMenu(false);
            }}
          >
            Service
          </Menu.Item>
          <Menu.Item
            className="nav-link-li"
            key="help"
            onClick={() => {
              userid ? handleHelp() : handleShowHelp();
            }}
          >
            Help
          </Menu.Item>
          {userid ? (
            <>
              <SubMenu
                key="user"
                className="nav-link-li"
                style={{ fontSize: "25px" }}
                // icon={<AppstoreOutlined />}
                title={<FaRegUserCircle />}
              >
                <Menu.Item
                  key="Profile"
                  className="nav-prod-li  hover-li"
                  onClick={() => navigate("/profile/ProfileInformation")}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                  key="LogOut"
                  className="nav-prod-li  hover-li"
                  onClick={handleLogout}
                >
                  LogOut
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="notification"
                className="nav-link-li"
                style={{ fontSize: "25px" }}
                title={<BsBell />}
              >
                <Menu.Item key="NewOrder" className="nav-prod-li  hover-li">
                  Gaswale New Order
                </Menu.Item>
                <Menu.Item
                  key="Nortification"
                  className="nav-prod-li  hover-li"
                >
                  New Nortification
                </Menu.Item>
                <Menu.Item key="ViewAll" className="nav-prod-li  hover-li">
                  <p className="head-norti-p"> View All</p>
                </Menu.Item>
              </SubMenu>

              {/* <Menu.Item
                key="notification"
                className="nav-link-li nav-link-li-icon"
              >
                <BsBell />
              </Menu.Item> */}
              <Menu.Item
                key="cart"
                className="nav-link-li nav-link-li-icon position"
                onClick={() => navigate("/cart")}
              >
                <span className="cart-pos-count">2</span>
                <PiShoppingCartLight />
              </Menu.Item>
            </>
          ) : (
            <Menu.Item
              className="nav-link-li"
              onClick={handleShowLogin}
              key="user"
              // icon={<SettingOutlined />}
            >
              Login
            </Menu.Item>
          )}
        </Menu>
        <ul className="nav-menu">
          <li className="nav-link-li ">
            <AiOutlineMenuUnfold
              onClick={handleShowMenu}
              style={{ fontSize: "25px" }}
            />
          </li>
        </ul>
      </div>

      <div className="sidebar-new">
        <Drawer
          width={"16rem"}
          placement={"right"}
          style={{ backgroundColor: "#169e49" }}
          destroyOnClose={true}
          rootClassName={"sidebar-new"}
          Header={false}
          closeIcon={<IoMdClose size={30} style={{height:"40px", width:"40px"}} color={"white"} />
          }
          onClose={() => setShowMenu(false)}
          open={showMenu}
        >
         <div style={{marginBottom:"1rem"}}>
          <img
            src={gaswaleLogo}
            alt="logo"
            onClick={handleLogo}
            className="nav-logo"
          />
        </div>
          <ul
            className={`nav-menu-dropdown open ${
              showMenu ? "nav-menu-open" : ""
            }`}
            // style={{ paddingTop: "60px" }}
          >
            <li
              style={{ color: "white" }}
              className="nav-link-li-menu more"
              onClick={handleShowLocation}
            >
              {location !== null ? <>{location}</> : "Location"}
              <PiCaretDownBold color="white" className="caretUp" />
            </li>
            {userid && (
              <li
                style={{ color: "white" }}
                className="nav-link-li-menu"
                onClick={() => {
                  navigate("/dashboard/ActiveOrder");
                  setShowMenu(false);
                }}
              >
                Dashboard
              </li>
            )}
            <li
              style={{ color: "white" }}
              className="nav-link-li-menu"
              onClick={() => {
                if (productHovered) {
                  setProductHovered(false);
                } else {
                  setProductHovered(true);
                }
                setUserHovered(false);
              }}
            >
              Product
            </li>
            {productHovered && (
              <>
                <li
                  // style={{ color: "white",backgroundColor:'#169e49' }}
                  className="nav-prod-li  hover-li"
                  onClick={() => {
                    if (productLPGHovered) {
                      setProductLPGHovered(false);
                    } else {
                      setProductLPGHovered(true);
                    }
                  }}
                >
                  LPG <AiFillCaretRight />
                </li>

                {productLPGHovered && (
                  <>
                    <li
                      style={{ }}
                      onClick={() => handleItemDetails("All")}
                      className="nav-prod-li  hover-li"
                    >
                      All
                    </li>
                    <li
                      style={{ }}
                      onClick={() => handleItemDetails("HPCL")}
                      className="nav-prod-li  hover-li"
                    >
                      HPCL
                    </li>
                    <li
                      style={{ }}
                      onClick={() => handleItemDetails("BPCL")}
                      className="nav-prod-li  hover-li"
                    >
                      BPCL
                    </li>
                    <li
                      style={{ }}
                      onClick={() => handleItemDetails("IOCL")}
                      className="nav-prod-li  hover-li"
                    >
                      IOCL
                    </li>
                    <li
                      style={{ }}
                      onClick={() => handleItemDetails("PMs")}
                      className="nav-prod-li  hover-li"
                    >
                      PMs
                    </li>
                  </>
                )}
                <li
                  style={{ color: "white" }}
                  className="nav-prod-li  hover-li"
                >
                  Industrial Gases
                </li>
                <li
                  style={{ color: "white" }}
                  className="nav-prod-li  hover-li"
                >
                  Spares
                </li>
                <li
                  style={{ color: "white" }}
                  className="nav-prod-li  hover-li"
                >
                  Equipment
                </li>
              </>
            )}

            <li
              style={{ color: "white" }}
              className="nav-link-li-menu"
              onClick={() => {
                navigate("/service");
                setShowMenu(false);
              }}
            >
              Service
            </li>
            <li
              style={{ color: "white" }}
              className="nav-link-li-menu"
              onClick={() => {
                userid ? handleHelp() : handleShowHelp();
              }}
            >
              Help
            </li>
            {userid ? (
              <>
                <li
                  style={{ color: "white" }}
                  className="nav-link-li-menu nav-link-li-icon "
                  onClick={() => {
                    setUserHovered(!userHovered);
                    setProductHovered(false);
                    setProductLPGHovered(false);
                  }}
                >
                  <FaRegUserCircle />
                </li>
                {userHovered && (
                  <>
                    <li
                      style={{ color: "white" }}
                      className="nav-link-li-menu hover-li"
                      onClick={() => navigate("/profile/ProfileInformation")}
                    >
                      Profile
                    </li>
                    <li
                      style={{ color: "white" }}
                      className="nav-link-li-menu hover-li"
                      onMouseEnter={() => setUserHovered2(true)}
                      onMouseLeave={() => {
                        setUserHovered(false);
                      }}
                      onClick={handleLogout}
                    >
                      LogOut
                    </li>
                  </>
                )}
                <li
                  style={{ color: "white" }}
                  className="nav-link-li-menu nav-link-li-icon"
                >
                  <BsBell />
                </li>
                <li
                  style={{ color: "white" }}
                  className="nav-link-li-menu nav-link-li-icon"
                  onClick={() => navigate("/cart")}
                >
                  <PiShoppingCartLight />
                </li>
              </>
            ) : (
              <li
                style={{ color: "white" }}
                className="nav-link-li-menu "
                onClick={handleShowLogin}
              >
                Login
              </li>
            )}
          </ul>
        </Drawer>
      </div>

      {/* {showMenu && (
      
      )} */}
      {showLocation && (
        <Location closeModel={closeModel} LocationName={LocationName} />
      )}
      {showLogin && <Login closeModel={closeModel} />}
      {showHelp && <ContactUs closeModel={closeModel} />}
    </>
  );
};
