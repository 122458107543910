import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import {
  createQuoteAPI,
  productListAPI,
  quotationlistAPI,
} from "../../../ApiRouter";
import { useNavigate } from "react-router-dom";
import { QuotationDetails } from "./childComponent/QuotationDetails";
import { Modal, Select } from "antd";
import { QuotationCard } from "../../../Comp/Cards";
import { NoData } from "../../../Comp/NoData";

export const Quotation = () => {
  const [showQuote, setShowQuote] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [quotationData, setQuitationData] = useState([]);
  const [showQuotation, setShowQuotation] = useState(false);
  const [size, setSize] = useState("large");
  const [productList, setProductList] = useState([]);
  const [strProd, setStrProd] = useState("");
  const [quetData, setQuetData] = useState([]);

  const navigate = useNavigate();
  const { userid } = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = () => {
      // const splitData = strProd.split(",");
      var arr = [];
      for (var i = 0; i < productList.length; i++) {
        for (var j = 0; j < strProd.length; j++) {
          if (productList[i].value === strProd[j]) {
            arr.push(productList[i]);
          }
        }
      }
      setQuetData(arr);
    };
    fetchData();
  }, [strProd]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(productListAPI, body);
      const data = response.data.data;
      const options = [];
      for (var i = 0; i < data.length; i++) {
        options.push({
          value: data[i].uuid,
          label: data[i].description,
          count: 1,
        });
        setProductList(options);
      }
    };
    fetchData();
  }, []);

  const closeModel = () => setShowQuotation(false);
  const handleChange = (value) => {
    setStrProd(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      body.append("ingress", 4);
      const response = await axios.post(quotationlistAPI, body);
      setOrderList(response.data.data);
    };
    fetchData();
  }, []);

  const handleOrderSummery = (item) => {
    setQuitationData(item);
    setShowQuotation(true);
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleGetQuotesubmit = async () => {
    console.log(quetData);
    const body = new FormData();
    body.append("userid", userid);
    body.append("ingress", 4);
    quetData.forEach((item) => body.append("productid[]", item.value));
    quetData.forEach((item) => body.append("qty[]", item.count));
    quetData.forEach((item) => body.append("productname[]", item.label));
    body.append("submitquote", "Get a Quote");
    const response = await axios.post(createQuoteAPI, body);
    console.log(response);

    if (response.data.success == true) {
      alert("Quotation saved");
      setShowQuote(false);
      setProductList([]);
    }
  };
  return (
    <>
      <div className="Qutation">
        <div className="AO-1st">
          <button
            className="get-a-qut-btn "
            style={{ backgroundColor: showQuote ? "gray" : "" }}
            onClick={() => setShowQuote(!showQuote)}
          >
            {showQuote ? "Cancel" : "Get a Quote"}
          </button>
        </div>
        {showQuote ? (
          <div>
            <div className="Quote-select">
              <label style={{ paddingRight: "1rem" }}>Select Products*</label>
              <Select
                mode="multiple"
                size={size}
                placeholder="Please select"
                onChange={handleChange}
                style={{
                  width: "50%",
                }}
                options={productList.length > 0 ? productList : ""}
              />
            </div>
            <div>
              {quetData.length > 0 ? (
                <>
                  {quetData.map((item, i) => (
                    <div className="Qut-list" key={i}>
                      <div className="Qut-list-tittle">{item.label}</div>
                      <input
                        type="text"
                        value={item.count}
                        onChange={(e) => {
                          setQuetData((pre) =>
                            pre.map((val, ind) =>
                              val.value === item.value
                                ? {
                                    ...val,
                                    count: e.target.value,
                                  }
                                : { ...val }
                            )
                          );
                        }}
                        className="Qut-list-input"
                      />
                    </div>
                  ))}
                  <div className="get-a-qut-btn-div">
                    <button
                      className="get-a-qut-btn "
                      onClick={() => handleGetQuotesubmit()}
                    >
                      Get a Quote
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <>
            {orderList.length > 0 ? (
              <div className="Qut-items">
                {orderList.map((item, i) => (
                  <QuotationCard
                    key={i}
                    item={item}
                    onClick={() => handleOrderSummery(item)}
                  />
                ))}
              </div>
            ) : (
             <NoData/>
            )}
          </>
        )}
      </div>
      {showQuotation && (
        <Modal
          footer={null}
          title=""
          className="BR-edit-modal "
          style={{
            top: 20,
          }}
          open={showQuotation}
          okButtonProps="false"
          onOk={() => setShowQuotation(false)}
          onCancel={() => setShowQuotation(false)}
        >
          <QuotationDetails closeModel={closeModel} item={quotationData} />
        </Modal>
      )}
    </>
  );
};
