import React, { useState } from "react";
import "./12Profile.css";
import { ProfileInformation } from "./smallComponent/ProfileInformation";
import { Authentication } from "./smallComponent/Authentication";
import { Branches } from "./smallComponent/Branches";
import { Address } from "./smallComponent/Address";
import { Refferal } from "./smallComponent/Refferal";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Profile = () => {
  const [itemLink, setItemLink] = useState("ProfileInformation");
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;
  return (
    <>
      <div className="Profile">
        <div className="Profile-1st">
          <ul className="Profile-1st-ul">
            <li
  style={{padding:"1rem",listStyle:'none'}}
>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" style={{width:'100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
            </div>
            </li>
            
            <li
              className={`Profile-1st-li ${
                pathname === "/profile/ProfileInformation" ? "item-link" : ""
              }`}
              onClick={() => navigate("/profile/ProfileInformation")}
            >
              <p>Profile Information</p>
              <p className="np-cut">{">"}</p>
            </li>
            <li
              className={`Profile-1st-li ${
                pathname === "/profile/Address" ? "item-link" : ""
              }`}
              onClick={() => navigate("/profile/Address")}
            >
              <p>Address</p>
              <p className="np-cut">{">"}</p>
            </li>
            <li
              className={`Profile-1st-li ${
                pathname === "/profile/Authentication" ? "item-link" : ""
              }`}
              onClick={() => navigate("/profile/Authentication")}
            >
              <p>User id & Password</p>
              <p className="np-cut">{">"}</p>
            </li>
            <li
              className={`Profile-1st-li ${
                pathname === "/profile/Branches" ? "item-link" : ""
              }`}
              onClick={() => navigate("/profile/Branches")}
            >
              <p>Branches</p>
              <p className="np-cut">{">"}</p>
            </li>
            <li
              className={`Profile-1st-li ${
                pathname === "/profile/Refferal" ? "item-link" : ""
              }`}
              onClick={() => navigate("/profile/Refferal")}
            >
              <p>Refferal</p>
              <p className="np-cut">{">"}</p>
            </li>
          </ul>
        </div>
        <div className="Profile-2nd">
          <Outlet/>
        </div>
      </div>
    </>
  );
};
