import React, { useEffect, useState } from "react";
import "./Cart.css";
import CylinderImg from "../../Assets/otherimage/cylinder.png";
import { DatePicker, Select } from "antd";
import { LiaRupeeSignSolid } from "react-icons/lia";
import axios from "axios";
import { cartAddAPI, cartListAPI } from "../../ApiRouter";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setOrderData } from "../../Redux/actions";

const Cart = () => {
  const [inputValue, setInputValue] = useState();
  const [cartData, setCartData] = useState([]);
  const [state, setState] = useState(false);
  const [noOfProduct, setNoOfProduct] = useState();
  const [totalCount, setTotalCount] = useState();
  const [total, setTotal] = useState();
  const [showSchedule, setShowSchedule] = useState(false);
  const [cartValue, setCartValue] = useState([]);
  const [secDeposite, setSecDeposite] = useState(0);
  const [sch, setSCH] = useState("");
  const [schLot, setSchLot] = useState("");

    const {userid} = useSelector((state) => state.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(cartListAPI, body);
      setCartData(response.data.data);
      setInputValue(response.data.data.count);
    };
    fetchData();
  }, [state]);

  useEffect(() => {
    const fetchData = () => {
      if (cartData.length !== 0) {
        var count = 0;
        var tot = 0;
        var SecDepo = 0;
        for (let i = 0; i < cartData.length; i++) {
          var t = cartData[i].count * cartData[i].price;
          var SD =
            (Number(cartData[i].count) - Number(cartData[i].returnclinder)) *
            Number(cartData[i].securitydeposit);
          SecDepo = SecDepo + SD;
          tot = tot + t;
          var count = count + cartData[i].count;
          t = 1;
          SD = 1;
        }
        setNoOfProduct(cartData.length);
        setTotalCount(count);
        setTotal(Number(tot.toFixed(0)));
        setSecDeposite(SecDepo);
      }
    };
    fetchData();
  }, [cartData]);

  useEffect(() => {
    const fetchData = () => {
      if (sch.length !== "" && schLot.length !== "") {
        const obj = [
          {
            secDeposite: secDeposite,
            sch: sch,
            schLot: schLot,
          },
        ];
        dispatch(setOrderData(obj));
      }
    };
    fetchData();
  }, [sch, secDeposite, schLot]);

  const handleInputValue = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "+") {
      body.append("count", count + 1);
      body.append("returncount", count + 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "-") {
      body.append("count", count - 1);
      body.append("returncount", count - 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "inputchange") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
    }
  };

  const handleOutFocus = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    }
  };
  return (
    <>
      {cartData.length > 0 ? (
        <div className="cart">
          <div className="cart-1st">
            <div className="cart-1st-heading">Cart</div>
            {cartData.map((item,i) => (
              <div className="cart-1st-1" key={i}>
                <div className="cart-img-div">
                  <img src={item.url} alt="alt" className="cart-1st-img" />

                  {item.url.includes("cylinder.png") ? (
                    <p>{item.capacity} Kg</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="cart-div-2">
                  <div>{item.type}</div>
                  <div>{item.description}</div>
                  <div>₹ {item.price.toFixed(2)}</div>
                  <div style={{ fontWeight: "200" }}>
                    <del>₹ {(item.price - item.discountprice).toFixed(2)}</del>
                  </div>
                  <div className="cart-delivery">
                    Estimate Delivery by tomorrow
                  </div>
                  {item.count > item.returnclinder &&
                    item.securitydeposit != 0 && (
                      <div>
                        Security Deposit: ₹{" "}
                        {(item.count - item.returnclinder) *
                          item.securitydeposit}
                      </div>
                    )}
                </div>
                <div className="cart-div-3">
                  <div className="cart-div-icon">
                    <div className="icon-div">
                      <p
                        className="cart-icons"
                        onClick={() =>
                          item.count == 1
                            ? handleOutFocus("remove", item.productid, 0, 0, 2)
                            : handleInputValue(
                                "-",
                                item.productid,
                                item.count,
                                item.returnclinder
                              )
                        }
                      >
                        {"-"}
                      </p>
                    </div>
                    <input
                      type="text"
                      className="cart-input"
                      value={item.count}
                      onChange={(e) => {
                        e.target.value == 0 || e.target.value.length === 0
                          ? handleOutFocus("", item.productid, 0, 0, 2)
                          : handleInputValue(
                              "inputchange",
                              item.productid,
                              Number(e.target.value),
                              item.returnclinder,
                              1
                            );

                        setCartData((pre) =>
                          pre.map((val, ind) =>
                            val.id === item.id
                              ? {
                                  ...val,
                                  count: Number(e.target.value),
                                }
                              : { ...val }
                          )
                        );
                      }}
                      onBlur={(e) => {
                        e.target.value == 0 || e.target.value.length === 0
                          ? handleOutFocus("remove", item.productid, 0, 0, 2)
                          : handleInputValue(
                              "inputchange",
                              item.productid,
                              Number(e.target.value),
                              item.returnclinder,
                              1
                            );
                      }}
                    />
                    <div className="icon-div">
                      <p
                        className="cart-icons"
                        onClick={() =>
                          handleInputValue(
                            "+",
                            item.productid,
                            item.count,
                            item.returnclinder
                          )
                        }
                      >
                        {"+"}
                      </p>
                    </div>
                  </div>
                  <div className="cart-remove-div">
                    <button
                      className="cart-remove"
                      onClick={() =>
                        handleInputValue("remove", item.productid, 0, 0, 2)
                      }
                    >
                      Remove
                    </button>
                  </div>
                  <div className="cart-empty">
                    <p>Empty Cyl. return :</p>
                    <Select
                      defaultValue={item.returnclinder}
                      style={{ width: 65, height: 35 }}
                      className="cart-empty-select"
                      value={item.returnclinder}
                      onChange={(selectedValue) => {
                        if (selectedValue == 0) {
                          handleInputValue(
                            "inputchange",
                            item.productid,
                            item.count,
                            selectedValue,
                            1
                          );
                        } else {
                          handleInputValue(
                            "inputchange",
                            item.productid,
                            item.count,
                            selectedValue,
                            1
                          );
                        }

                        setCartData((prevCartData) =>
                          prevCartData.map((val) =>
                            val.id === item.id
                              ? {
                                  ...val,
                                  returnclinder: selectedValue,
                                }
                              : val
                          )
                        );
                      }}
                      options={Array.from(
                        { length: item.count + 1 },
                        (_, index) => ({
                          value: index,
                          label: index.toString(),
                        })
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="cart-2nd">
            <div className="cart-pay-title">Order Summary</div>
            <div className="cart-pay-desc">
              <div className="cart-pay-desc-div">Number of Products</div>
              <div className="cart-pay-desc-div">{noOfProduct}</div>
            </div>
            <div className="cart-pay-desc">
              <div className="cart-pay-desc-div">Total Count</div>
              <div className="cart-pay-desc-div">{totalCount}</div>
            </div>
            {secDeposite !== 0 && (
              <>
                <div className="cart-pay-desc">
                  <div className="cart-pay-desc-div">sub total</div>
                  <div className="cart-pay-desc-div">
                    <LiaRupeeSignSolid /> <p>{total}</p>
                  </div>
                </div>
                <div className="cart-pay-desc">
                  <div className="cart-pay-desc-div">Security Deposite</div>
                  <div className="cart-pay-desc-div">
                    <LiaRupeeSignSolid /> <p>{secDeposite}</p>
                  </div>
                </div>
              </>
            )}
            <div className="cart-pay-desc">
              <div className="cart-pay-desc-div">Total Price</div>
              <div className="cart-pay-desc-div">
                <LiaRupeeSignSolid /> <p>{total + secDeposite}</p>
              </div>
            </div>
            <div className="cart-term">
              <input
                type="checkbox"
                className="cart-term-chechbox"
                onChange={(e) => setShowSchedule(!showSchedule)}
              />
              <p>Scheduled Order</p>
            </div>
            {showSchedule && (
              <>
                <div className="SCH-div">
                  <lable>Schedule Date</lable>
                  <input
                    type="date"
                    className="cart-date"
                    value={sch}
                    onChange={(e) => setSCH(e.target.value)}
                  />
                </div>
                <div className="SCH-div">
                  <lable>Schedule Date</lable>
                  <select
                    className="cart-date"
                    onChange={(e) => setSchLot(e.target.value)}
                  >
                    <option
                      defaultValue={"7:00 AM to 10:00 AM"}
                      value={"7:00 AM to 10:00 AM"}
                    >
                      7:00 AM to 10:00 AM
                    </option>
                    <option value={"10:00 AM to 1:00 PM"}>
                      10:00 AM to 1:00 PM
                    </option>
                    <option value={"1:00 PM to 4:00 PM"}>
                      1:00 PM to 4:00 PM
                    </option>
                    <option value={"4:00 PM to 7:00 PM"}>
                      4:00 PM to 7:00 PM
                    </option>
                  </select>
                </div>
              </>
            )}
            <div className="cart-Pro-btn">
              <button
                className="proceed-btn"
                onClick={() => navigate("/shippingaddress")}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      ) : (
        "No Data"
      )}
    </>
  );
};

export default Cart;
