import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./09itemFullDetails.css";
import {
  ProductList,
  itemDescription,
  cartListAPI,
  cartAddAPI,
} from "../../ApiRouter";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import store from "../../Redux/store";
import { get_show_login } from "../../Redux/DataSlice";
import { useSelector } from "react-redux";

export const ItemFullDetails = () => {
  const [item, setItem] = useState([]);
  const [itemDesc, setItemDesk] = useState([]);
  const location = useLocation();
  const itemid = location.state;
  const [cartData, setCartData] = useState([]);
  const [state, setState] = useState(false);
  const { userid } = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(ProductList);
      const dataList = response.data.data;
      const filteredItems = dataList.filter((item) => item.uuid === itemid);
      setTimeout(() => {
        setItem(filteredItems);
      }, 1000);
      const body = new FormData();
      body.append("id", itemid);
      body.append("userid", "");
      const response2 = await axios.post(itemDescription, body);
      setTimeout(() => {
        setItemDesk(response2.data.data);
      }, 1000);
    };
    fetchData();
  }, [itemid]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(cartListAPI, body);
      setCartData(response.data.data);
    };
    fetchData();
  }, [state]);

  const HandleAddToCart = async (productId) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", 1);
    body.append("productid", productId);
    body.append("count", 1);
    body.append("returncount", 1);

    const response = await axios.post(cartAddAPI, body);
    if (response.data.success == true) {
      setState(!state);
    }
  };

  const handleInputValue = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    console.log(sign, productid, count, returnclinder, (action = 1));
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "+") {
      body.append("count", count + 1);
      body.append("returncount", count + 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "-") {
      body.append("count", count - 1);
      body.append("returncount", count - 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "inputchange") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
    }
  };

  const handleOutFocus = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    }
  };

  return (
    <>
      <div className="IFD-main">
        {item.length > 0 ? (
          item.map(() => (
            <>
              {item.map((item,i) => (
                <>
                  <div className="IFD-1st" key={i}>
                    <div className="IFD-1st-div">
                      <img src={item.url} alt="" className="IFD-big-img" />
                    </div>
                    <div className="IFD-1st-div2">
                      <img src={item.url} alt="" className="IFD-small-img" />
                    </div>
                  </div>
                  <div className="IFD-2nd">
                    <div className="IFD-Card-type">{item.type}</div>
                    <div className="IFD-Card-desc">{item.description}</div>
                    <div className="IFD-Card-price">
                      <FaRupeeSign className="IFD-price-symbol" />
                      {item.price}
                    </div>
                    {/* <button className="IFD-card-btn">Add to Cart</button> */}

                    {userid && cartData.length !== 0 ? (
                      <>
                        {cartData.find(
                          (newItem) => newItem.productid === item.uuid
                        ) ? (
                          <div className="cart-div-icon">
                            <div className="icon-div">
                              <p
                                className="cart-icons"
                                onClick={() => {
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).count == 1
                                    ? handleOutFocus(
                                        "remove",
                                        cartData.find(
                                          (newItem) =>
                                            newItem.productid === item.uuid
                                        ).productid,
                                        0,
                                        0,
                                        2
                                      )
                                    : handleInputValue(
                                        "-",
                                        cartData.find(
                                          (newItem) =>
                                            newItem.productid === item.uuid
                                        ).productid,
                                        cartData.find(
                                          (newItem) =>
                                            newItem.productid === item.uuid
                                        ).count,
                                        cartData.find(
                                          (newItem) =>
                                            newItem.productid === item.uuid
                                        ).returnclinder,
                                        1
                                      );
                                }}
                              >
                                {"-"}
                              </p>
                            </div>
                            <input
                              type="text"
                              className="cart-input"
                              value={Number(
                                cartData.find(
                                  (newItem) => newItem.productid === item.uuid
                                ).count
                              )}
                              onChange={(e) => {
                                e.target.value == 0 ||
                                e.target.value.length === 0
                                  ? handleOutFocus("", item.productid, 0, 0, 2)
                                  : handleInputValue(
                                      "inputchange",
                                      cartData.find(
                                        (newItem) =>
                                          newItem.productid === item.uuid
                                      ).productid,
                                      Number(e.target.value),
                                      cartData.find(
                                        (newItem) =>
                                          newItem.productid === item.uuid
                                      ).returnclinder,
                                      1
                                    );

                                setCartData((pre) =>
                                  pre.map((val, ind) =>
                                    val.id ===
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).id
                                      ? {
                                          ...val,
                                          count: Number(e.target.value),
                                        }
                                      : { ...val }
                                  )
                                );
                              }}
                              onBlur={(e) => {
                                e.target.value == 0 ||
                                e.target.value.length === 0
                                  ? handleOutFocus(
                                      "remove",
                                      cartData.find(
                                        (newItem) =>
                                          newItem.productid === item.uuid
                                      ).productid,
                                      0,
                                      0,
                                      2
                                    )
                                  : handleInputValue(
                                      "inputchange",
                                      cartData.find(
                                        (newItem) =>
                                          newItem.productid === item.uuid
                                      ).productid,
                                      Number(e.target.value),
                                      cartData.find(
                                        (newItem) =>
                                          newItem.productid === item.uuid
                                      ).returnclinder,
                                      1
                                    );
                              }}
                            />
                            <div className="icon-div">
                              <p
                                className="cart-icons"
                                onClick={() =>
                                  handleInputValue(
                                    "+",
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).productid,
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).count,
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).returnclinder,
                                    1
                                  )
                                }
                              >
                                {"+"}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="IFD-card-btn"
                            onClick={() => HandleAddToCart(item.uuid)}
                          >
                            Add to Cart
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        className="IFD-card-btn"
                        onClick={() => {
                          store.dispatch(get_show_login(true));
                        }}
                      >
                        Add to Cart
                      </button>
                    )}

                    {itemDesc.length > 0 &&
                      itemDesc.map((item,i) => (
                        <div className="IFD-Card-desc" key={i}>
                          <p>Description</p>
                          <ul className="IFD-ul">
                            <li className="IFD-li">
                              Category - {item.category}
                            </li>
                            <li className="IFD-li">
                              Unit of measurement - {item.uom}
                            </li>
                            <li className="IFD-li">Material - {item.mat}</li>
                            <li className="IFD-li">Weight - {item.wt}</li>
                            <li className="IFD-li">Height - {item.ht} mm</li>
                            <li className="IFD-li">Diameter - {item.dia} mm</li>
                            <li className="IFD-li">
                              Thickness - {item.thick} mm
                            </li>
                            <li className="IFD-li">
                              Water Capacity - {item.wc}
                            </li>
                            <li className="IFD-li">
                              Working Pressure - {item.wp}
                            </li>
                            <li className="IFD-li">
                              Test Pressure - {item.tp}
                            </li>
                            <li className="IFD-li">
                              Valve Type - {item.valvetype}
                            </li>
                          </ul>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </>
          ))
        ) : (
          <div className="spinner-div">
            <Oval
              height={60}
              width={60}
              color="white"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#169e49"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          </div>
        )}
      </div>
    </>
  );
};
