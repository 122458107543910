import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { creditDetailsAPI } from "../../../ApiRouter";

export const Credit = () => {
  const [order, setOrder] = useState([]);

  const {userid} = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(creditDetailsAPI, body);
      setOrder(response.data.data);
      console.log(response.data.data);
    };
    fetchData();
  }, []);
  return (
    <>
      <>
        {order.length > 0 ? (
          <>
            {order.map((item,i) => (
              <div className="CRT" key={i}>
                <div className="CRT-div border">
                  <div className="CRT-div-item">
                    Credit Applied: ₹ {item.req_credit_limit}
                  </div>
                  <div className="CRT-div-item">
                    Credit Days Applied: {item.req_credit_days} days
                  </div>
                </div>
                <div className="CRT-div">
                  <div className="CRT-div-item">
                    Credit Approved: ₹ {item.credit_limit}
                  </div>
                  <div className="CRT-div-item">
                    Credit Days Approved: {item.credit_days}
                  </div>
                  <div className="CRT-div-item">
                    Available Credit: ₹ {item.credit_limit - item.current_limit}
                  </div>
                  <div className="CRT-div-item">
                    Credit Used: ₹ {item.current_limit}
                  </div>
                  <div className="CRT-div-item">
                    Available Credit Days: {item.current_days} days
                  </div>
                  <div className="CRT-div-item">
                    Interest : ₹ {item.intrest}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </>
    </>
  );
};
