import axios from "axios";
import { GSTNumberValideAPI } from "./ApiRouter";

export const MobileNumberValidator = (value) => {
    const MobileValidation = /^\d{10}$/;
    if (value.length === 0) {
        return `Mobile is required`;
    } else if (!MobileValidation.test(value)) {
        return "Mobile Number Invalid";
    } else if (value.length > 10) {
        return "mobile No. Should be contain 10 digit ";
    } else {
        return true;
    }
};

export const EmailValidator = (value) => {
    const EmailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!EmailValidation.test(value)) {
        return "Email Invalid";
    } else if (value.length < 6) {
        return "Email Should be contain minimum 6 digit";
    } else {
        return true;
    }
};

export const StringValidator = (
    value,
    Name,
    minLength = 1,
    maxLength = 100
) => {
    if (value.length === 0) {
        return `${Name} is required`;
    } else if (value.length < minLength) {
        return `${Name} Should be contain minimum ${minLength} digit`;
    } else if (value.length > maxLength) {
        return `${Name} Should be contain Maximum ${maxLength} digit`;
    } else {
        return true;
    }
};

export const PinCodeValidator = (pincode, Name) => {
    if (pincode.length == 0) {
        return `${Name} is required`;
    } else if (pincode.length == 6) {
        return true;
    } else {
        return `${Name} Should contain 6 digit only`;
    }
};

export const GSTValidator = (GSTNumber, Name) => {
    return new Promise((resolve, reject) => {
        if (GSTNumber.length === 0) {
            reject(`${Name} is required`);
        } else if (GSTNumber.length !== 15) {
            reject(`${Name} should contain 15 digits only`);
        } else {
            const body = new FormData();
            body.append('gstin', GSTNumber);
            axios
                .post(GSTNumberValideAPI, body)
                .then((response) => {
                    if (response.data.error === false) {
                        resolve(response.data);
                    } else {
                        return reject("Invalid GST no.");
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        }
    });
};

export const GSTValidator2 = (GSTNumber, Name) => {
    if (GSTNumber.length === 0) {
        return (`${Name} is required`);
    } else if (GSTNumber.length !== 15) {
        return (`${Name} should contain 15 digits only`);
    } else {
        const body = new FormData();
        body.append('gstin', GSTNumber);
        axios
            .post(GSTNumberValideAPI, body)
            .then((response) => {
                if (response.data.error === false) {
                    return true;
                } else {
                    return "Invalid GST no.";
                }
            })
            .catch((error) => {
                return "Something Went Wrong";
            });
    }
};


