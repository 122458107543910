import axios from "axios";
import React, { useEffect, useState } from "react";
import { itemDescription } from "../../../ApiRouter";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { get_show_login } from "../../../Redux/DataSlice";
import store from "../../../Redux/store";

export const CompareProduct = ({ data }) => {
  const [obj, setObj] = useState({
    Brand1: "",
    Brand2: "",
    Brand3: "",
    Product1: "",
    Product2: "",
    Product3: "",
    Product1Arr: [],
    Product2Arr: [],
    Product3Arr: [],
    ProductId: "",
    findID: "",
  });
  const { userid } = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      body.append("id", obj.ProductId);
      const response = await axios.post(itemDescription, body);
      if (response.data.success == true) {
        if (obj.findID == 1) {
          setObj({ ...obj, Product1Arr: response.data.data });
        } else if (obj.findID == 2) {
          setObj({ ...obj, Product2Arr: response.data.data });
        } else if (obj.findID == 3) {
          setObj({ ...obj, Product3Arr: response.data.data });
        }
      } else if (response.data.success == false) {
        console.log("something Went Wrong");
      }
    };
    fetchData();
  }, [obj.ProductId]);

  return (
    <div className="CP-1st scroll-in-animation">
      <div className="CompProd-main-alt">
        <div className="CompProd">
          <div className="CompProd-main">
            <div className="CompProd-item"></div>
            <div className="CompProd-item">
              {obj.Product1Arr.length == 0 ? (
                <>
                  <label className="CompProd-lable">Add a product</label>
                  <select
                    className="CompProd-select"
                    onChange={(e) =>
                      setObj({
                        ...obj,
                        Brand1: e.target.value,
                        Product1Arr: [],
                      })
                    }
                  >
                    <option hidden>Choose a Brand</option>
                    <option value="IOCL">IOCL</option>
                    <option value="BPCL">BPCL</option>
                    <option value="HPCL">HPCL</option>
                    <option value="SUPERGAS">SUPERGAS</option>
                    <option value="TOTALGAS">TOTALGAS</option>
                    <option value="Gogas">Gogas</option>
                  </select>
                  <select
                    className="CompProd-select"
                    disabled={obj.Brand1 == "" ? true : false}
                    onChange={(e) =>
                      setObj({ ...obj, ProductId: e.target.value, findID: 1 })
                    }
                  >
                    <option hidden>Select a product</option>
                    {obj.Brand1 !== "" ? (
                      <>
                        {data
                          .filter((item) => item.type == obj.Brand1)
                          .map((v, i) => (
                            <option value={v.uuid} key={i}>
                              {v.description}
                            </option>
                          ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </>
              ) : (
                <div className="CompProd-desc scroll-in-animation">
                  <div className="CompProd-img-div">
                    <img
                      src={obj.Product1Arr[0].url}
                      className="CompProd-img"
                    />
                    <div className="CompProd-tittle">
                      {obj.Product1Arr[0].description}
                    </div>
                  </div>
                  <div className="CompProd-Close">
                    <Tooltip title="Clear Product">
                      <AiOutlineCloseCircle
                        onClick={(e) =>
                          setObj({ ...obj, Product1Arr: [], Brand1: "" })
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
            <div className="CompProd-item">
              {obj.Product2Arr.length == 0 ? (
                <>
                  <label className="CompProd-lable">Add a product</label>
                  <select
                    className="CompProd-select"
                    onChange={(e) =>
                      setObj({
                        ...obj,
                        Brand2: e.target.value,
                        Product2Arr: [],
                      })
                    }
                  >
                    <option hidden>Choose a Brand</option>
                    <option value="IOCL">IOCL</option>
                    <option value="BPCL">BPCL</option>
                    <option value="HPCL">HPCL</option>
                    <option value="SUPERGAS">SUPERGAS</option>
                    <option value="TOTALGAS">TOTALGAS</option>
                    <option value="Gogas">Gogas</option>
                  </select>
                  <select
                    className="CompProd-select"
                    disabled={obj.Brand2 == "" ? true : false}
                    onChange={(e) =>
                      setObj({ ...obj, ProductId: e.target.value, findID: 2 })
                    }
                  >
                    <option hidden>Select a product</option>
                    {obj.Brand2 !== "" ? (
                      <>
                        {data
                          .filter((item) => item.type == obj.Brand2)
                          .map((v, i) => (
                            <option value={v.uuid} key={i}>
                              {v.description}
                            </option>
                          ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </>
              ) : (
                <>
                  <div className="CompProd-desc scroll-in-animation">
                    <div className="CompProd-img-div">
                      <img
                        src={obj.Product2Arr[0].url}
                        className="CompProd-img"
                      />
                      <div className="CompProd-tittle">
                        {obj.Product2Arr[0].description}
                      </div>
                    </div>
                    <div className="CompProd-Close">
                      <Tooltip title="Clear Product">
                        <AiOutlineCloseCircle
                          onClick={(e) =>
                            setObj({ ...obj, Product2Arr: [], Brand2: "" })
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="CompProd-item">
              {obj.Product3Arr.length == 0 ? (
                <>
                  <label className="CompProd-lable">Add a product</label>
                  <select
                    className="CompProd-select"
                    onChange={(e) =>
                      setObj({
                        ...obj,
                        Brand3: e.target.value,
                        Product3Arr: [],
                      })
                    }
                  >
                    <option hidden>Choose a Brand</option>
                    <option value="IOCL">IOCL</option>
                    <option value="BPCL">BPCL</option>
                    <option value="HPCL">HPCL</option>
                    <option value="SUPERGAS">SUPERGAS</option>
                    <option value="TOTALGAS">TOTALGAS</option>
                    <option value="Gogas">Gogas</option>
                  </select>
                  <select
                    className="CompProd-select"
                    disabled={obj.Brand3 == "" ? true : false}
                    onChange={(e) =>
                      setObj({ ...obj, ProductId: e.target.value, findID: 3 })
                    }
                  >
                    <option hidden>Select a product</option>
                    {obj.Brand3 !== "" ? (
                      <>
                        {data
                          .filter((item) => item.type == obj.Brand3)
                          .map((v, i) => (
                            <option value={v.uuid} key={i}>
                              {v.description}
                            </option>
                          ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </>
              ) : (
                <>
                  <div className="CompProd-desc scroll-in-animation">
                    <div className="CompProd-img-div">
                      <img
                        src={obj.Product3Arr[0].url}
                        className="CompProd-img"
                      />
                      <div className="CompProd-tittle">
                        {obj.Product3Arr[0].description}
                      </div>
                    </div>
                    <div className="CompProd-Close">
                      <Tooltip title="Clear Product">
                        <AiOutlineCloseCircle
                          onClick={(e) =>
                            setObj({ ...obj, Product3Arr: [], Brand3: "" })
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="CompProd-2nd">
          <div
            className={`CompProd-compare ${
              (obj.Product1Arr.length !== 0 ||
                obj.Product2Arr.length !== 0 ||
                obj.Product3Arr.length !== 0) &&
              "scroll-in-animation"
            }`}
          >
            {(obj.Product1Arr.length !== 0 ||
              obj.Product2Arr.length !== 0 ||
              obj.Product3Arr.length !== 0) && (
              <>
                <div className="compare-items">
                  <div className="compare-item">Price</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `₹ ${obj.Product1Arr[0].rsp}`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `₹ ${obj.Product2Arr[0].rsp}`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `₹ ${obj.Product3Arr[0].rsp}`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Offered Price</div>
                  <div className="compare-item">
                    {obj?.Product1Arr?.length > 0 ? (
                      <>
                        {userid ? (
                          obj?.Product1Arr[0]?.offeredPrice ? (
                            `₹ ${obj?.Product1Arr[0]?.offeredPrice}`
                          ) : (
                            "-"
                          )
                        ) : (
                          <div
                            onClick={() => {
                              store.dispatch(get_show_login(true));
                            }}
                            style={{ color: "#198754", cursor: "pointer" }}
                          >
                            Login To View
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="compare-item">
                    {obj?.Product2Arr?.length > 0 ? (
                      <>
                        {userid ? (
                          obj?.Product2Arr[0]?.offeredPrice ? (
                            `₹ ${obj?.Product2Arr[0]?.offeredPrice}`
                          ) : (
                            "-"
                          )
                        ) : (
                          <div
                            onClick={() => {
                              store.dispatch(get_show_login(true));
                            }}
                            style={{ color: "#198754", cursor: "pointer" }}
                          >
                            Login To View
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="compare-item">
                    {obj?.Product3Arr?.length > 0 ? (
                      <>
                        {userid ? (
                          obj?.Product3Arr[0]?.offeredPrice ? (
                            `₹ ${obj?.Product3Arr[0]?.offeredPrice}`
                          ) : (
                            "-"
                          )
                        ) : (
                          <div
                            onClick={() => {
                              store.dispatch(get_show_login(true));
                            }}
                            style={{ color: "#198754", cursor: "pointer" }}
                          >
                            Login To View
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Category</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      obj.Product1Arr[0].category}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      obj.Product2Arr[0].category}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      obj.Product3Arr[0].category}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Capacity(Net Wt.)</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `${obj.Product1Arr[0].capacity} Kg`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `${obj.Product2Arr[0].capacity} Kg`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `${obj.Product3Arr[0].capacity} Kg`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Material</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 && obj.Product1Arr[0].mat}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 && obj.Product2Arr[0].mat}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 && obj.Product3Arr[0].mat}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Tare Weight</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 && "-"}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 && "-"}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 && "-"}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Height</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `${obj.Product1Arr[0].ht} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `${obj.Product2Arr[0].ht} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `${obj.Product3Arr[0].ht} mm`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Diameter</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `${obj.Product1Arr[0].dia} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `${obj.Product2Arr[0].dia} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `${obj.Product3Arr[0].dia} mm`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Thickness</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `${obj.Product1Arr[0].thick} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `${obj.Product2Arr[0].thick} mm`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `${obj.Product3Arr[0].thick} mm`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Water Capacity</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      `${obj.Product1Arr[0].wc} Liters`}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      `${obj.Product2Arr[0].wc} Liters`}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      `${obj.Product3Arr[0].wc} Liters`}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Working Pressure</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 && obj.Product1Arr[0].wp}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 && obj.Product2Arr[0].wp}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 && obj.Product3Arr[0].wp}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Test Pressure</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 && obj.Product1Arr[0].tp}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 && obj.Product2Arr[0].tp}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 && obj.Product3Arr[0].tp}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Valve Type</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 &&
                      obj.Product1Arr[0].valvetype}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 &&
                      obj.Product2Arr[0].valvetype}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 &&
                      obj.Product3Arr[0].valvetype}
                  </div>
                </div>
                <div className="compare-items">
                  <div className="compare-item">Application of product</div>
                  <div className="compare-item">
                    {obj.Product1Arr.length !== 0 && "-"}
                  </div>
                  <div className="compare-item">
                    {obj.Product2Arr.length !== 0 && "-"}
                  </div>
                  <div className="compare-item">
                    {obj.Product3Arr.length !== 0 && "-"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
