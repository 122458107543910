import React from "react";
import './Table.css'

export const Table = ({ header_data, children }) => {
  return (
    <table className="Help-table">
      <thead className="Help-thead">
        <tr className="Help-tr-th">
          {header_data?.map((v, i) => (
            <th key={i} className="Help-th">
              {v}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
