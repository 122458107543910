import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { AiOutlineClose } from "react-icons/ai";

export const DateRange = ({ closeModel, handleSetRange }) => {
  const handleSelect = (ranges) => {
    console.log(ranges);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    const fetchData = () => {
      handleSetRange(state);
    };
    fetchData();
  }, [state]);
  return (
    <>
      <div className="wraper" onClick={closeModel}></div>
      <div className="date-content">
        <div className="log-content-head">
          <div className="log-content-head-1st">
            <div>Please Select Date</div>
          </div>
          <div className="log-content-close">
            <AiOutlineClose onClick={closeModel} />
          </div>
        </div>
        <DateRangePicker
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
        <div className="DP-done-btn">
          <button className="DP-btn" onClick={closeModel}>
            Done
          </button>
        </div>
      </div>
    </>
  );
};
