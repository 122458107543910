// import React from "react";
// import "./input.css";
// import { BsEye, BsEyeSlash } from "react-icons/bs";

// const Input = ({
//   label,
//   placeholder,
//   error,
//   mess,
//   type = "text",
//   onChange,
//   value,
//   disabled,
//   onBlur,
//   mw,
//   w,
//   onFocus,
//   onKeyDown,
//   setShowPass,
//   showPass,
//   pass = false,
//   max,
// }) => {
//   return (
//     <div className="inp-main" style={{ width: w }}>
//       {label && <div className="inp-label">{label}</div>}
//      <div className="inp-wrap" style={{ position: "relative" }}>
//         <input
//           placeholder={placeholder?.toUpperCase() || label}
//           value={value}
//           onChange={onChange}
//           className="inp"
//           type={type}
//           max={max}
//           disabled={disabled}
//           onBlur={onBlur} // it is used for input type date only
//           onFocus={onFocus} // it is Used for input type data and select both
//           onKeyDown={onKeyDown} // it is Used for input type data and select both
//         />
//         {pass && (
//           <div className="pass-show-eye">
//             {showPass ? (
//               <BsEyeSlash onClick={() => setShowPass(!showPass)} />
//             ) : (
//               <BsEye onClick={() => setShowPass(!showPass)} />
//             )}
//           </div>
//         )}
//       </div>
//       {mess && <div className="inp-mess">{mess}</div>}
//       {error && <div className="inp-error">{error}</div>}
//     </div>
//   );
// };

// export default Input;

import React from "react";

import "./input.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export const Input = ({
  onFocus,
  onKeyDown,
  lable,
  onBlur,
  onChange,
  error,
  placeholder,
  type = "text",
  readOnly,
  value,
  disabled,
  style,
  pass,
  showPassword,
  setShowPassword,
}) => {
  return (
    <div className="Add-form-div">
      <p>{lable}</p>
      {pass && (
        <>
          {showPassword ? (
            <AiOutlineEyeInvisible
              className="pass-eye"
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <AiOutlineEye
              className="pass-eye"
              onClick={() => setShowPassword(true)}
            />
          )}
        </>
      )}
      <input
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        value={value}
        placeholder={placeholder || lable}
        onChange={onChange}
        className="Add-input"
        style={{
          ...style,
          backgroundColor: readOnly || disabled ? "rgb(238, 231, 231)" : "",
        }}
      />
      {error !== "" && <p className="error">{error}</p>}
    </div>
  );
};

export const Select = ({
  onFocus,
  onKeyDown,
  lable,
  onBlur,
  onChange,
  error,
  placeholder,
  value,
  disabled,
  children,
  style,
  readOnly,
}) => {
  return (
    <div className="Add-form-div">
      <p>{lable} </p>
      <select
        className="Add-input"
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        style={{
          ...style,
          backgroundColor: readOnly || disabled ? "rgb(238, 231, 231)" : "",
        }}
      >
        {children}
      </select>
      {error !== "" && <p className="error">{error}</p>}
    </div>
  );
};