import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrEdit } from "react-icons/gr";
import { useSelector } from "react-redux";
import {
  completeListAPI,
  profileAPI,
  updateProfileAPI,
} from "../../../ApiRouter";
import { TiDocumentText } from "react-icons/ti";
import { BsInfoCircle } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Tooltip } from "antd";
import { MdContentCopy } from "react-icons/md";
import MyMap from "./childComponent/myMap";
import {
  EmailValidator,
  GSTValidator,
  GSTValidator2,
  StringValidator,
} from "../../../Validator";
import { Input, Select } from "../../../Comp/Input/Input";

export const ProfileInformation = () => {
  const [profileData, setProfileData] = useState([]);
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);
  const [isGSTAvailible, setIsGSTAvailible] = useState(false);
  const [tradeName, setTradeName] = useState("");
  const [showShipAddress, setShowShipAddress] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [shipDistrictList, setShipDistrictList] = useState([]);
  const [haveGST, setHaveGST] = useState("");
  const [havePAN, setHavePAN] = useState("");
  const [showMap, setShowMap] = useState(false);

  const [obj, setObj] = useState({
    GSTNumber: "",
    PANNumber: "",
    LegalName: "",
    TradeName: "",
    Address: "",
    State: "",
    District: "",
    PinCode: "",
    PrimaryEmail: "",
    SecondaryEmail: "",
    CreditRequired: "",
    ShipAddress: "",
    ShipState: "",
    ShipDistrict: "",
    ShipPinCode: "",
    Latlong: "",
  });
  const [objErr, setObjErr] = useState({
    GSTNumber: "",
    PANNumber: "",
    LegalName: "",
    TradeName: "",
    Address: "",
    State: "",
    District: "",
    PinCode: "",
    PrimaryEmail: "",
    SecondaryEmail: "",
    CreditRequired: "",
    ShipAddress: "",
    ShipState: "",
    ShipDistrict: "",
    ShipPinCode: "",
    Latlong: "",
  });

  const { userid } = useSelector((state) => state.data);

  const handlSeteLatlong = (e) => {
    setObj({ ...obj, Latlong: e });
    setObjErr({ ...objErr, Latlong: "" });
  };

  //  UseEffect for StateList
  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
    };
    fetchData();
  }, []);

  //  UseEffect for District List

  useEffect(() => {
    const fetchData = async () => {
      const filteredState = stateList.find((item) => item.name === obj.State);

      if (filteredState) {
        const st = filteredState.code;
        const body = new FormData();
        body.append("stateid", st);
        const response = await axios.post(completeListAPI, body);
        if (response.data.success === true) {
          setDistrictList(response.data.data.reponselist.districts);
        }
      } else {
        // console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [obj.State]);

  useEffect(() => {
    const fetchData = async () => {
      const filteredState = stateList.find(
        (item) => item.name === obj.ShipState
      );

      if (filteredState) {
        const st = filteredState.code;
        const body = new FormData();
        body.append("stateid", st);
        const response = await axios.post(completeListAPI, body);
        if (response.data.success === true) {
          setShipDistrictList(response.data.data.reponselist.districts);
        }
      } else {
        // console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [obj.ShipState]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(profileAPI, body);
      setProfileData(response.data.data);
      setTradeName(response.data.data[0].tradename);
      if (response.data.data[0].gst == "") {
        setIsGSTAvailible(true);
      }
    };
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tradeName.length > 3) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("tradename", tradeName);
      const response = await axios.post(updateProfileAPI, body);

      if (response.data.success === false) {
        alert("false");
      }
    }
  };
  const handleNoGSTSubmit = async (e) => {
    e.preventDefault();

    if (
      (GSTValidator2(obj.GSTNumber, "GST Number") !== true &&
        EmailValidator(obj.PrimaryEmail) !== true,
      StringValidator(obj.Latlong, "Latlong", 0) !== true)
    ) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("action", "update");
      body.append("checkGST", haveGST ? 1 : 0);
      body.append("gst", obj.GSTNumber);
      body.append("pan", obj.PANNumber);
      body.append("legalname", obj.LegalName);
      body.append("tradename", obj.TradeName);
      body.append("name", obj.LegalName);
      body.append("address", obj.Address);
      body.append("state", obj.State);
      body.append("district", obj.District);
      body.append("pincode", obj.PinCode);
      body.append("email", obj.PrimaryEmail);
      body.append("email1", obj.SecondaryEmail);
      body.append("credit", obj.CreditRequired ? 1 : 0);
      body.append("address1", obj.ShipAddress);
      body.append("state1", obj.ShipState);
      body.append("district1", obj.ShipDistrict);
      body.append("pincode1", obj.ShipPinCode);
      body.append("latlong", obj.Latlong.lat + "," + obj.Latlong.lng);
      body.append("userSubmit", "submit");

      console.log(body);
      console.log(obj);

      const response = await axios.post(updateProfileAPI, body);
      console.log(response);
    }
  };
  const handleGSTChange = async (e) => {
    const capitalData = e.target.value.toUpperCase();
    console.log(capitalData);
    setObj((prevObj) => {
      return { ...prevObj, GSTNumber: e.target.value };
    });
    if (e.target.value.length === 15) {
      try {
        const data = await GSTValidator(e.target.value, "GST");
        setObj({
          ...obj,
          GSTNumber: e.target.value,
          LegalName: data.legalname,
          PANNumber: data.pan,
          TradeName: data.tradename,
          Address: data.address,
          State: data.state,
          District: data.district,
          PinCode: data.pincode,
          ShipAddress: data.address,
          ShipState: data.state,
          ShipDistrict: data.district,
          ShipPinCode: data.pincode,
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setObj({
        ...obj,
        LegalName: "",
        PANNumber: "",
        TradeName: "",
        Address: "",
        State: "",
        District: "",
        PinCode: "",
        ShipAddress: "",
        ShipState: "",
        ShipDistrict: "",
        ShipPinCode: "",
      });
    }
  };
  return (
    <div className="PI">
      {!isGSTAvailible ? (
        <>
          <div className="PI-edit">
            <button
              className="PI-edit-btn"
              onClick={() => setShowProfileUpdate(!showProfileUpdate)}
            >
              {showProfileUpdate ? (
                <i>Cancel</i>
              ) : (
                <>
                  <GrEdit />
                  <i>Edit</i>
                </>
              )}
            </button>
          </div>
          {profileData.length > 0 ? (
            <form onSubmit={handleSubmit} className="PI-form">
              <Input
                value={profileData[0].gst}
                lable={"GST Number"}
                readOnly={true}
              />
              <Input
                value={profileData[0].pan}
                lable={"PAN Number"}
                readOnly={true}
              />
              <Input
                value={profileData[0].legalname}
                lable={"Legal Name"}
                readOnly={true}
              />
              <Input
                style={{
                  backgroundColor: showProfileUpdate ? "white" : "",
                  border: "1px solid rgba(0, 0, 0, 0.158)",
                }}
                lable={"Trade Name"}
                value={tradeName}
                readOnly={!showProfileUpdate}
                onChange={(e) => setTradeName(e.target.value)}
              />

              <Input
                value={profileData[0].address}
                lable={"Billing Address"}
                readOnly={true}
              />
              <Input
                value={profileData[0].legalname}
                lable={"Legal Name"}
                readOnly={true}
              />

              <Select
                value={profileData[0].state}
                lable={"State"}
                readOnly={true}
              >
                <option hidden> {profileData[0].state}</option>
              </Select>
              <Select
                value={profileData[0].district}
                lable={"District"}
                readOnly={true}
              >
                <option hidden> {profileData[0].district}</option>
              </Select>

              <Input
                value={profileData[0].pincode}
                lable={"Pin Code"}
                readOnly={true}
              />
              {showProfileUpdate && (
                <div className="PI-update-div">
                  <button className="PI-update-btn">Submit</button>
                </div>
              )}
            </form>
          ) : (
            ""
          )}
        </>
      ) : (
        <form className="NOGST-main" onSubmit={handleNoGSTSubmit}>
          <div className="NOGST-1st">
            <lable className="NOGST-lable">Do you have GST?*</lable>
            <div className="NOGST-radio-div">
              <input
                type="radio"
                className="NOGST-input-radio"
                name="doYouHaveGST"
                value={true}
                onChange={() => {
                  setHaveGST(true);
                  setHavePAN(false);
                }}
              />
              <lable className="NOGST-lable">Yes</lable>
              <input
                type="radio"
                className="NOGST-input-radio"
                name="doYouHaveGST"
                value={false}
                onChange={() => {
                  setHaveGST(false);
                  setHavePAN("");
                }}
              />
              <lable className="NOGST-lable">No</lable>
            </div>
          </div>
          {haveGST !== true && haveGST !== "" && (
            <div className="NOGST-1st">
              <lable className="NOGST-lable">Do You have PAN?*</lable>
              <div className="NOGST-radio-div">
                <input
                  type="radio"
                  className="NOGST-input-radio"
                  name="doYouHavePAN"
                  value={true}
                  onChange={() => setHavePAN(true)}
                />
                <lable className="NOGST-lable">Yes</lable>
                <input
                  type="radio"
                  className="NOGST-input-radio"
                  name="doYouHavePAN"
                  value={false}
                  onChange={() => setHavePAN(false)}
                />
                <lable className="NOGST-lable">No</lable>
              </div>
            </div>
          )}
          {haveGST === true && haveGST !== "" && (
            <div className="NOGST-bill-div">
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable">GST Number*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  // value={obj.GSTNumber}
                  onChange={handleGSTChange}
                />
              </div>
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable">PAN Number*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  readOnly={true}
                  value={obj.PANNumber}
                  onChange={(e) =>
                    setObj({ ...obj, PANNumber: e.target.value })
                  }
                />
              </div>
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable">Legal Name*</lable>
                <input
                  readOnly={true}
                  type="text"
                  className="NOGST-input"
                  value={obj.LegalName}
                  onChange={(e) =>
                    setObj({ ...obj, LegalName: e.target.value })
                  }
                />
              </div>
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable">Trade Name*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  value={obj.TradeName}
                  onChange={(e) =>
                    setObj({ ...obj, TradeName: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          {haveGST === false && havePAN === true && (
            <div className="NOGST-bill-div">
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable">PAN Number*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  onChange={(e) =>
                    setObj({ ...obj, PANNumber: e.target.value })
                  }
                />
              </div>
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable"> Name*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  onChange={(e) =>
                    setObj({ ...obj, LegalName: e.target.value })
                  }
                />
              </div>
            </div>
          )}
          {haveGST === false && havePAN === false && (
            <div className="NOGST-bill-div">
              <div className="NOGST-filed-div">
                <lable className="NOGST-lable"> Name*</lable>
                <input
                  type="text"
                  className="NOGST-input"
                  onChange={(e) =>
                    setObj({ ...obj, LegalName: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          {haveGST !== "" && havePAN !== "" && (
            <>
              <div className="NOGST-bill">
                <lable className="NOGST-bill-tittle">
                  <p>
                    <TiDocumentText />
                  </p>
                  <p>Billing Address:</p>
                </lable>
                <div className="NOGST-bill-div">
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">Address*</lable>
                    <input
                      type="text"
                      className="NOGST-bill-input"
                      value={obj.Address}
                      onChange={(e) =>
                        setObj({ ...obj, Address: e.target.value })
                      }
                    />
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">State*</lable>
                    {/* <input type="text" className="NOGST-bill-input" /> */}
                    <select
                      className="NOGST-bill-input"
                      value={obj.State}
                      onChange={(e) => {
                        setObj({ ...obj, State: e.target.value });
                        setObjErr({ ...objErr, State: "" });
                      }}
                    >
                      <option hidden>{obj.State}</option>
                      {stateList.length > 0 &&
                        stateList.map((item, i) => (
                          <option key={i} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">District*</lable>
                    {/* <input type="text" className="NOGST-bill-input" /> */}
                    <select
                      className="NOGST-bill-input"
                      value={obj.District}
                      onChange={(e) => {
                        setObj({ ...obj, District: e.target.value });
                        setObjErr({ ...objErr, District: "" });
                      }}
                    >
                      <option hidden>{obj.District}</option>
                      {districtList.length > 0 &&
                        districtList.map((item, i) => (
                          <option key={i} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">Pin Code*</lable>
                    <input
                      type="text"
                      className="NOGST-bill-input"
                      value={obj.PinCode}
                      onChange={(e) =>
                        setObj({ ...obj, PinCode: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="NOGST-bill-div NOGST-bill">
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">Primary Email*</lable>
                    <input
                      type="email"
                      className="NOGST-bill-input"
                      onChange={(e) => {
                        setObj({ ...obj, PrimaryEmail: e.target.value });
                        setObjErr({ ...objErr, PrimaryEmail: "" });
                      }}
                    />
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">Secondary Email</lable>
                    <input
                      type="email"
                      className="NOGST-bill-input"
                      onChange={(e) => {
                        setObj({ ...obj, SecondaryEmail: e.target.value });
                        setObjErr({ ...objErr, SecondaryEmail: "" });
                      }}
                    />
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-lable">
                      Credit Required?
                      <Tooltip title="Our team will contact for Approvel">
                        <BsInfoCircle
                          style={{ cursor: "pointer", paddingLeft: ".5rem" }}
                        />
                      </Tooltip>
                    </lable>
                    <div className="">
                      <input
                        type="radio"
                        className="NOGST-input-radio"
                        name="CreditRequired"
                        value={true}
                        onChange={(e) => {
                          setObj({ ...obj, CreditRequired: e.target.value });
                          setObjErr({ ...objErr, CreditRequired: "" });
                        }}
                      />
                      <lable className="NOGST-lable">Yes</lable>
                      <input
                        type="radio"
                        className="NOGST-input-radio"
                        name="CreditRequired"
                        value={false}
                      />
                      <lable className="NOGST-lable">No</lable>
                    </div>
                  </div>
                  <div className="NOGST-filed-div">
                    <lable className="NOGST-bill-lable">
                      Shipping Address is same as Billing address
                      <input
                        type="checkbox"
                        checked={showShipAddress}
                        value={showShipAddress}
                        onChange={(e) => setShowShipAddress(!showShipAddress)}
                        className=""
                      />
                    </lable>
                  </div>
                  {!showShipAddress && (
                    <div className="NOGST-bill-div NOGST-bill">
                      <div className="NOGST-filed-div">
                        <lable className="NOGST-bill-lable">Address*</lable>
                        <input
                          type="text"
                          className="NOGST-bill-input"
                          value={obj.ShipAddress}
                        />
                      </div>
                      <div className="NOGST-filed-div">
                        <lable className="NOGST-bill-lable">State*</lable>
                        <select
                          className="NOGST-bill-input"
                          value={obj.ShipState}
                          onChange={(e) => {
                            setObj({ ...obj, ShipState: e.target.value });
                            setObjErr({ ...objErr, ShipState: "" });
                          }}
                        >
                          <option hidden>{obj.ShipState}</option>
                          {stateList.length > 0 &&
                            stateList.map((item, i) => (
                              <option key={i} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="NOGST-filed-div">
                        <lable className="NOGST-bill-lable">District*</lable>
                        <select
                          className="NOGST-bill-input"
                          value={obj.ShipDistrict}
                          onChange={(e) => {
                            setObj({ ...obj, ShipDistrict: e.target.value });
                            setObjErr({ ...objErr, ShipDistrict: "" });
                          }}
                        >
                          <option hidden>{obj.ShipDistrict}</option>
                          {shipDistrictList.length > 0 &&
                            shipDistrictList.map((item, i) => (
                              <option key={i} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="NOGST-filed-div">
                        <lable className="NOGST-bill-lable">Pin Code*</lable>

                        <input
                          type="text"
                          className="NOGST-bill-input"
                          value={obj.ShipPinCode}
                        />
                      </div>
                    </div>
                  )}

                  <div className="NOGST-filed-div">
                    <button
                      className="Add-map-btn"
                      onClick={(e) => {
                        setShowMap(!showMap);
                        e.preventDefault();
                      }}
                    >
                      <HiOutlineLocationMarker style={{ fontSize: "20px" }} />
                      <p>Point on Map </p>
                    </button>
                  </div>
                  {showMap && (
                    <div className="Add-map-div-2">
                      <MyMap handlSeteLatlong={handlSeteLatlong} />
                    </div>
                  )}
                </div>
              </div>
              <div className="NOGST-submit-div">
                <button className="NOGST-submit-btn">Submit</button>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
};
