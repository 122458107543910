import React, { useEffect, useState } from "react";
import { completeListAPI, createBranchAPI } from "../../../../ApiRouter";
import axios from "axios";
import {
  EmailValidator,
  GSTValidator,
  GSTValidator2,
  MobileNumberValidator,
  PinCodeValidator,
  StringValidator,
} from "../../../../Validator";
import { useSelector } from "react-redux";
import { Input, Select } from "../../../../Comp/Input/Input";

export const EditBranche = ({ data, closeEditBranche }) => {
  const [obj, setObj] = useState({});
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [objErr, setObjErr] = useState({
    branchid: "",
    email: "",
    mobile: "",
    gst: "",
    pan: "",
    legalname: "",
    tradename: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    branch: "",
  });

  const { userid } = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = () => {
      if (data !== undefined) {
        setObj(data);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
    };
    fetchData();
  }, []);

  //  UseEffect for District List
  useEffect(() => {
    const fetchData = async () => {
      if (obj.state) {
        const filteredState = stateList.find(
          (item) => item.name === obj.state.toUpperCase()
        );
        if (filteredState) {
          const st = filteredState.code;
          const body = new FormData();
          body.append("stateid", st);
          const response = await axios.post(completeListAPI, body);
          if (response.data.success === true) {
            console.warn(response.data.data.reponselist.districts);
            setDistrictList(response.data.data.reponselist.districts);
          }
        } else {
          console.log("error");
        }
      } else {
        console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [obj.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      MobileNumberValidator(obj.mobile) === true &&
      EmailValidator(obj.email) === true &&
      StringValidator(obj.legalname, "Legal Name", 10, 100) === true &&
      StringValidator(obj.tradename, "Trade Name", 10, 100) === true &&
      StringValidator(obj.pan, "pan", 9, 11) === true &&
      StringValidator(obj.address, "Address", 24, 1000) === true &&
      StringValidator(obj.state, "State", 1, 50) === true &&
      StringValidator(obj.district, "District", 1, 50) === true &&
      StringValidator(obj.branch, "branch", 7, 13) === true &&
      PinCodeValidator(obj.pincode, "PinCode") === true &&
      GSTValidator2(obj.gst, "GST Number") !== true
    ) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("branchid", obj.id);
      body.append("email", obj.email);
      body.append("mobile", obj.mobile);
      body.append("gst", obj.gst);
      body.append("pan", obj.pan);
      body.append("legalname", obj.legalname);
      body.append("tradename", obj.tradename);
      body.append("address", obj.address);
      body.append("state", obj.state);
      body.append("district", obj.district);
      body.append("pincode", obj.pincode);
      body.append("branch", obj.branch);

      const response = await axios.post(createBranchAPI, body);
      if (response.data.success === true) {
        closeEditBranche();
      } else if (response.data.success === false) {
        alert("Branch Update Failed");
      }
    } else {
      setObjErr({
        ...objErr,
        mobile:
          MobileNumberValidator(obj.mobile) !== true
            ? MobileNumberValidator(obj.mobile)
            : "",
        email:
          EmailValidator(obj.email) !== true ? EmailValidator(obj.email) : "",
        // gst:
        //   GSTValidator2(obj.gst, "GST Number") !== true
        //     ? GSTValidator2(obj.gst, "GST Number")
        //     : "",
        pan:
          StringValidator(obj.pan, "pan", 9, 11) !== true
            ? StringValidator(obj.pan, "pan", 9, 11)
            : "",
        legalname:
          StringValidator(obj.legalname, "Legal Name", 10, 100) !== true
            ? StringValidator(obj.legalname, "Legal Name", 10, 100)
            : "",
        tradename:
          StringValidator(obj.tradename, "Trade Name", 10, 100) !== true
            ? StringValidator(obj.tradename, "Trade Name", 10, 100)
            : "",
        address:
          StringValidator(obj.address, "Address", 24, 1000) !== true
            ? StringValidator(obj.address, "Address", 24, 1000)
            : "",
        state:
          StringValidator(obj.state, "State", 1, 50) !== true
            ? "State is required"
            : "",
        district:
          StringValidator(obj.district, "District", 1, 50) !== true
            ? "District is required"
            : "",
        pincode:
          PinCodeValidator(obj.pincode, "PinCode") !== true
            ? PinCodeValidator(obj.pincode, "PinCode")
            : "",
        branch:
          StringValidator(obj.branch, "branch", 7, 13) !== true
            ? StringValidator(obj.branch, "branch", 7, 13)
            : "",
      });
    }
  };

  const handleGSTChange = async (e) => {
    const capitalData = e.target.value.toUpperCase();
    setObj((prevObj) => ({
      ...prevObj,
      gst: e.target.value.toUpperCase(),
    }));
    if (e.target.value.length === 15) {
      try {
        const data = await GSTValidator(e.target.value, "GST");
        setObj((prevObj) => ({
          ...prevObj,
          gst: e.target.value.toUpperCase(),
          legalname: data.legalname,
          pan: data.pan,
          tradename: data.tradename,
          address: data.address,
          state: data.state,
          district: data.district,
          pincode: data.pincode,
        }));
      } catch (error) {
        console.error(error);
        setObjErr({ ...objErr, gst: error });
      }
    } else {
    }
  };

  return (
    <div>
      {obj && (
        <form
          className="BR-Add-form scroll-in-animation"
          onSubmit={handleSubmit}
        >
          <Input
            lable={" Email ID"}
            value={obj.email}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                email: e.target.value,
              }));
              setObjErr({ ...objErr, email: "" });
            }}
            error={objErr.email}
          />
          <Input
            lable={"Mobile"}
            value={obj.mobile}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                mobile: e.target.value,
              }));
              setObjErr({ ...objErr, mobile: "" });
            }}
            error={objErr.mobile}
          />
          <Input
            lable={" GST Numbe"}
            value={obj.gst}
            onChange={(e) => {
              handleGSTChange(e);
              setObjErr({ ...objErr, gst: "" });
            }}
            error={objErr.gst}
          />
          <Input
            lable={"PAN Number"}
            value={obj.pan}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                pan: e.target.value,
              }));
              setObjErr({ ...objErr, pan: "" });
            }}
            error={objErr.pan}
          />
          <Input
            lable={" Legal Name"}
            value={obj.legalname}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                legalname: e.target.value,
              }));
              setObjErr({ ...objErr, legalname: "" });
            }}
            error={objErr.legalname}
          />
          <Input
            lable={" Trade Name"}
            value={obj.tradename}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                tradename: e.target.value,
              }));
              setObjErr({ ...objErr, tradename: "" });
            }}
            error={objErr.tradename}
          />

          <Input
            lable={"Address"}
            value={obj.address}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                address: e.target.value,
              }));
              setObjErr({ ...objErr, address: "" });
            }}
            error={objErr.address}
          />
          <Select
            lable={"State"}
            value={obj.state}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                state: e.target.value,
                district: "",
              }));
              setObjErr({ ...objErr, state: "" });
            }}
            error={objErr.state}
          >
            <option hidden>
              {obj.state === "" ? "Select State" : obj.state}
            </option>
            {stateList.length > 0 &&
              stateList.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
          </Select>

          <Select
            lable={"District"}
            value={obj.district}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                district: e.target.value,
              }));
              setObjErr({ ...objErr, district: "" });
            }}
            error={objErr.district}
          >
            <option hidden>
              {obj.district === "" ? "Select District" : obj.district}
            </option>
            {districtList.length > 0 &&
              districtList.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
          </Select>

          <Input
            lable={"Pin Code"}
            value={obj.pincode}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                pincode: e.target.value,
              }));
              setObjErr({ ...objErr, pincode: "" });
            }}
            error={objErr.pincode}
          />
          <Input
            lable={"Branch Code"}
            value={obj.branch}
            onChange={(e) => {
              setObj((prevObj) => ({
                ...prevObj,
                branch: e.target.value,
              }));
              setObjErr({ ...objErr, branch: "" });
            }}
            error={objErr.branch}
          />

          <div className="BR-Add-update-div">
            <button className="BR-Add-update-btn">Update</button>
          </div>
        </form>
      )}
    </div>
  );
};
