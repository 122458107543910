import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { orderByIdAPI } from "../../../../ApiRouter";
import { RaiseATicket } from "./RaiseATicket";
import cylinderImage from "../../../../Assets/otherimage/cylinder.png";
import moment from "moment";

export const OrderSummary = () => {
  const [orderData, setOrderData] = useState({});
  const location = useLocation();
  const orderId = location.state.orderid;
  const userid = location.state.userid;

  var objLength = Object.keys(orderData).length;

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      body.append("orderid", orderId);
      const response = await axios.post(orderByIdAPI, body);
      setOrderData(response.data.orders);
      console.log(response.data.orders);
    };
    fetchData();
  }, [orderId]);
  return (
    <div className="OS">
      <div className="OS-1st">
        <RaiseATicket />
      </div>
      <div className="OS-main">
        {objLength > 0 ? (
          <>
            <div className="OS-product-list">
              {orderData.products.map((item, i) => (
                <div className="OS-product-list-item" key={i}>
                  <img
                    src={orderData.image[i]}
                    alt=""
                    className="OS-list-img"
                  />
                  <div className="OS-list-content">
                    <div className="OS-list-content-item">
                      {orderData.type[i]}
                    </div>
                    <div className="OS-list-content-item">
                      {orderData.description[i]}
                    </div>
                    <div className="OS-list-content-item">
                      Price: ₹ {orderData.price[i]}
                    </div>
                    <div className="OS-list-content-item">
                      Count: {orderData.count[i]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="OS-product-ship">
              <div className="OS-product-ship-01">
                <div className="ship-01-items">
                  <div className="ship-01-item">Order# :</div>
                  <div className="ship-01-item"> {orderData.orderid}</div>
                </div>
                <div className="ship-01-items">
                  <div className="ship-01-item">Date:</div>
                  <div className="ship-01-item">
                    {moment(orderData.datetime).format("DD-MMM-YYYY hh:mm")}
                    {/* {orderData.datetime} */}
                  </div>
                </div>
                <div className="ship-01-items">
                  <div className="ship-01-item"> Payment Mode:</div>
                  <div className="ship-01-item"> {orderData.paymentmode}</div>
                </div>
                <div className="ship-01-items">
                  <div className="ship-01-item">Sub Total:</div>
                  <div className="ship-01-item"> ₹ {orderData.totalamount}</div>
                </div>
                <div className="ship-01-items">
                  <div className="ship-01-item">Total Sum:</div>
                  <div className="ship-01-item"> ₹ {orderData.totalamount}</div>
                </div>
              </div>
              <div className="OS-product-ship-02">
                <div className="ship-02-tittle">Shipping Tracking</div>
                <div className="ship-02-content">
                  Tracking : {orderData.orderstatustext}
                </div>
              </div>
              <div className="OS-product-ship-02">
                <div className="ship-02-tittle ">Shipping Address</div>
                <div className="ship-02-content">{orderData.name}</div>
                <div className="ship-02-content">{orderData.email}-</div>
                <div className="ship-02-content">{orderData.mobile}</div>
                <div className="ship-02-content">{orderData.fulladdress}</div>
                <div className="ship-02-content">{orderData.district}</div>
                <div className="ship-02-content">
                  {orderData.state}- {orderData.pincode}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
