import React, { useState, useEffect } from "react";
import { RaiseATicket } from "./childComponent/RaiseATicket";
import { Pagination } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { BsChatRightText, BsFileEarmarkPdf, BsRepeat } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  orderListAPI,
  orderPDFDownloadAPI,
  reorderAPI,
} from "../../../ApiRouter";
import { FeedbackConponent } from "./childComponent/Feedback";
import moment from "moment";
import { FaStar } from "react-icons/fa";
import useDebounce from "../../../Hook/Debouncing";
import { PaginationCreated } from "../../../Comp/Pagination";
import { NoData } from "../../../Comp/NoData";

export const OrderHistory = () => {
  const [totalActiveOrder, setTotalActiveOrder] = useState();
  const [entries, setEntries] = useState(10);
  const [searchItem, setSearchItem] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totelCount, setTotalCount] = useState();
  const [showFeed, setShowFeed] = useState(false);
  const [oid, setOid] = useState("");
  const debouncedSearchItem = useDebounce(searchItem, 1000);
  const { userid } = useSelector((state) => state.data);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [debouncedSearchItem]);

  useEffect(() => {
    fetchData();
  }, [entries, pageNo]);

  const fetchData = async () => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("page", pageNo);
    body.append("limit", entries);
    body.append("type", 2);
    body.append("search", searchItem);
    const response = await axios.post(orderListAPI, body);
    setOrderList(response.data.orders);
    setTotalActiveOrder(response.data.totalcount);
    var count =
      response.data.totalcount !== undefined ? response.data.totalcount : 0;
    var count1 = Math.ceil((count / entries) * 10);
    setTotalCount(count1);
  };

  const closeModel = () => {
    setShowFeed(false);
  };

  const onChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handlePDFDownLoad = async (orderid) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("orderid", orderid);
    const response = await axios.post(orderPDFDownloadAPI, body);
    const urlPath = response.data.path;

    axios({
      url: urlPath,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${userid}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  const handleOrderSummery = (orderid) => {
    navigate("/ordersummary", {
      state: { orderid: orderid, userid: userid },
    });
  };

  const handleReorder = async (orderid) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("orderid", orderid);
    const response = await axios.post(reorderAPI, body);
    if (response.data.success === true) {
      // navigate("/cart");
    }
  };
  const handleSearch = (e) => {
    setSearchItem(e.target.value);
  };

  return (
    <div className="Active-Order">
      <RaiseATicket />
      {/* <div className="AO-2nd">
        <div className="AO-2nd-entries">
          Show
          <select
            className="AO-2nd-ent-select"
            onChange={(e) => {
              setEntries(e.target.value);
              setPageNo(1);
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </select>
          Entries
        </div>
        <div className="AO-2nd-search">
          <input
            type="text"
            className="AO-search"
            placeholder="Search..."
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="AO-OL">
        <div className="AO-OL-1st">Previous Orders - {totalActiveOrder}</div>
      </div> */}
      <PaginationCreated
        setEntries={setEntries}
        setPageNo={setPageNo}
        pageNo={pageNo}
        onChange={onChange}
        totelCount={totelCount}
        searchChange={handleSearch}
        totalActiveOrder={totalActiveOrder}
      >
        <div className="OH">
          {orderList?.length > 0 ? (
            <table className="OH-table">
              <thead className="OH-head">
                <tr className="OH-tr-th">
                  <th className="OH-head-row OH-head-row-td1">Order ID</th>
                  <th className="OH-head-row OH-head-row-td1">Date</th>
                  <th className="OH-head-row OH-head-row-td1"> Order Status</th>
                  <th className="OH-head-row OH-head-row-td1"> Payment Mode</th>
                  <th className="OH-head-row OH-head-row-td1"> Amount</th>
                  <th className="OH-head-row OH-head-row-td1"> actions</th>
                </tr>
              </thead>
              {orderList.map((item, i) => (
                <tbody className="OH-body " key={i}>
                  <tr className="OH-tr-th">
                    <th className="OH-head-row2 OH-head-row-td1">
                      {item.orderid}
                    </th>
                    <th className="OH-head-row2 OH-head-row-td1">
                      {moment(item.datetime).format("DD/MM/YYYY hh:mm A")}
                    </th>
                    <th className="OH-head-row2 OH-head-row-td1">
                      {" "}
                      {item.orderstatustext}
                    </th>
                    <th className="OH-head-row2 OH-head-row-td1">
                      {" "}
                      {item.paymentmode}
                    </th>
                    <th className="OH-head-row2 OH-head-row-td1">
                      {" "}
                      {item.totalamount}
                    </th>
                    <th className="OH-head-row2 OH-head-row-td1">
                      <AiOutlineEye
                        className="OH-icons"
                        style={{
                          color: "#0d6efd",
                        }}
                        onClick={() => handleOrderSummery(item.orderid)}
                      />
                      <BsRepeat
                        className="OH-icons"
                        onClick={() => handleReorder(item.orderid)}
                      />
                      <BsFileEarmarkPdf
                        onClick={() => handlePDFDownLoad(item.orderList)}
                        className="OH-icons"
                        style={{
                          color: "#dc3545",
                        }}
                      />
                      <>
                        {item.feedbackstatus != 0 ? (
                          <span className="OH-icons">
                            <FaStar className="AO-star" />
                            {item.feedbackstatus}
                          </span>
                        ) : (
                          <BsChatRightText
                            onClick={() => {
                              setShowFeed(true);
                              setOid(item.orderid);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    </th>
                  </tr>
                </tbody>
              ))}
            </table>
          ) : (
            <NoData />
          )}
        </div>
      </PaginationCreated>

      {showFeed && <FeedbackConponent closeModel={closeModel} id={oid} />}
    </div>
  );
};
