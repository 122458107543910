import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function MyMap({ data, handlSeteLatlong }) {
  const [latDir, setLatDir] = useState(22.7196);
  const [longDir, setLongDir] = useState(75.8577);
  const [latLong, setLatLong] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      if (data !== undefined && data.lat !== null && data.long !== undefined) {
        setLatDir(data.lat);
        setLongDir(data.long);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    if (latLong && !isNaN(latLong.lat) && !isNaN(latLong.lng)) {
      setLatDir(latLong.lat);
      setLongDir(latLong.lng);
    }
  }, [latLong]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDF2pgkAq9rIaeQGY7HL89D6fZe70XIY2I",
  });

  const center = {
    lat: latDir,
    lng: longDir,
  };

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback(map) {
    // Handle unmounting here if needed
  }, []);

  return isLoaded ? (
    <GoogleMap
      className="Add-map-div-2"
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
      onClick={(e) => {
        setLatLong(e.latLng.toJSON());
        handlSeteLatlong(e.latLng.toJSON());
      }}
    >
      <>
        <Marker position={center} />

        <input
          type="text"
          placeholder="Customized your placeholder"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `27px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default MyMap;

// import React from "react";
// import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

// const center = {
//   lat: 22.7196,
//   lng: 75.8577,
// };

// function MyMap() {
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyDF2pgkAq9rIaeQGY7HL89D6fZe70XIY2I",
//   });

//   const [map, setMap] = React.useState(null);

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map);
//   }, []);

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null);
//   }, []);

//   return isLoaded ? (
//     <GoogleMap
//       className="Add-map-div-2"
//       mapContainerStyle={containerStyle}
//       center={center}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//       onClick={(e) => console.log(e.latLng.toString())}
//     >
//       <></>

//       <Marker position={center} />
//     </GoogleMap>
//   ) : (
//     <></>
//   );
// }

// export default React.memo(MyMap);
