import React, { useEffect, useState } from "react";
import "../01header/CSS/06ContactUs.css";
import axios from "axios";
import { ContactUsAPI, ticketListAPI } from "../../ApiRouter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../02Dashboard/11Dashboard.css";
import { Table } from "../../Comp/Table/Table";

export const Help = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [valdMobile, setValdMobile] = useState("");
  const [valdEmail, setValdEmail] = useState("");
  const [valdName, setValdName] = useState("");
  const [valdSubject, setValdSubject] = useState("");
  const [valdMessage, setValdMessage] = useState("");
  const [showTicket, setShowTicket] = useState(false);
  const [ticketList, setTicketList] = useState([]);

  const navigate = useNavigate();
  const EmailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const MobileValidation = /^\d{10}$/;

  const {userid} = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(ticketListAPI, body);
      setTicketList(response.data.data);
    };
    fetchData();
  }, [showTicket]);

  useEffect(() => {
    const fetchData = () => {
      if (
        mobile === "" ||
        email === "" ||
        name === "" ||
        subject === "" ||
        message === ""
      ) {
        if (mobile === "") {
          setValdMobile("");
        } else if (email === "") {
          setValdEmail("");
        } else if (name === "") {
          setValdName("");
        } else if (subject === "") {
          setValdSubject("");
        } else if (message === "") {
          setValdMessage("");
        }
      }
    };
    fetchData();
  }, [email, mobile, subject, name, message]);

  const validateMobile = () => {
    setValdMobile(MobileValidation.test(mobile));
  };

  const validateEmail = () => {
    setValdEmail(EmailValidation.test(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userid) {
      if (
        name.length !== 0 &&
        MobileValidation.test(mobile) &&
        EmailValidation.test(email) &&
        subject.length >= 5 &&
        message.length >= 10
      ) {
        const body = new FormData();
        body.append("userid", userid);
        body.append("name", name);
        body.append("mobile", mobile);
        body.append("email", email);
        body.append("subject", subject);
        body.append("comments", message);
        body.append("action", 4);
        const response = await axios.post(ContactUsAPI, body);
        console.log(response);
        if (response.data.success === true) {
          alert("our team contact you soon");
          navigate("/dashboard");
        }
      } else {
        if (name.length === 0) {
          setValdName("Name is Required");
        }

        if (!MobileValidation.test(mobile)) {
          setValdMobile("Enter Valid Mobile");
        }
        if (!EmailValidation.test(email)) {
          setValdEmail("Enter Valid Email");
        }
        if (subject.length <= 5) {
          setValdSubject("Subject length must 5 digit ");
        }
        if (message.length <= 10) {
          setValdMessage("Message length must 5 digit ");
        }
      }
    } else {
      alert("please Login First");
    }
  };

  return (
    <>
      <div className="Help-main">
        <div className="Help-div-btn">
          <button
            className="CU-btn"
            style={{ backgroundColor: showTicket ? "gray" : "blue" }}
            onClick={() => setShowTicket(!showTicket)}
          >
            {showTicket ? "Cancel" : "Raise Ticket"}
          </button>
        </div>
        {showTicket ? (
          <form
            className={`Help-form ${showTicket ? "scroll-in-animation" : ""}`}
            onSubmit={handleSubmit}
          >
            <div className="Help-form-div">
              <label className="CU-label">Name</label>
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                className="form-input"
              />
              {valdName !== "" && <p className="error"> {valdName} </p>}
            </div>
            <div className="Help-form-div">
              <label className="CU-label">Email Id</label>
              <input
                type="text"
                placeholder="Email Id"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setValdEmail("");
                }}
                onBlur={validateEmail}
                className=" form-input"
              />
              {valdEmail !== "" && <p className="error">Enter Valid Email</p>}
            </div>
            <div className="Help-form-div">
              <label className="CU-label">Contact number</label>
              <input
                type="text"
                onChange={(e) => {
                  setMobile(e.target.value);
                  setValdMobile("");
                }}
                placeholder="Contact number"
                className=" form-input"
                onBlur={validateMobile}
              />
              {valdMobile !== "" && <p className="error">Enter Valid Mobile</p>}
            </div>
            <div className="Help-form-div">
              <label className="CU-label">Subject</label>
              <input
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                className=" form-input"
              />
              {valdSubject !== "" && <p className="error"> {valdSubject}</p>}
            </div>

            <div className="Help-form-textarea">
              <label className="CU-label">Message</label>
              <textarea
                className=" form-input"
                style={{ resize: "vertical" }}
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
                rows="2"
                cols="30"
              ></textarea>
              {valdMessage !== "" && <p className="error"> {valdMessage}</p>}
            </div>
            <div className="CU-btn-div">
              <button className="CU-btn">Submit</button>
            </div>
          </form>
        ) : (
          <div
            className={`Help-content ${
              showTicket ? "" : "scroll-in-animation"
            }`}
          >
            {ticketList.length > 0 ? (
              // <table className="Help-table">
              //   <thead className="Help-thead">
              //     <tr className="Help-tr-th">
              //       <th className="Help-th">Ticket Id</th>
              //       <th className="Help-th">Raised on</th>
              //       <th className="Help-th">Subject</th>
              //       <th className="Help-th">Status</th>
              //       <th className="Help-th">Action</th>
              //     </tr>
              //     </thead>
              //     <tbody>

              //     {ticketList.map((item,i) => (
              //       <tr className="Help-tr-td" key={i}>
              //         <td className="Help-th">9137851200 </td>
              //         <td className="Help-th">13/06/23 14:17</td>
              //         <td className="Help-th">SSL installation</td>
              //         <td className="Help-th">in progress</td>
              //         <td className="Help-th">View</td>
              //       </tr>
              //     ))}
              //     </tbody>
              // </table>
              <Table
              header_data={["Ticket Id",
                "Raised on",
                "Subjec",
                "Status",
                "Action",]}>
                   {ticketList.map((item,i) => (
                    <tr className="Help-tr-td" key={i}>
                      <td className="Help-th">9137851200 </td>
                      <td className="Help-th">13/06/23 14:17</td>
                      <td className="Help-th">SSL installation</td>
                      <td className="Help-th">in progress</td>
                      <td className="Help-th">View</td>
                    </tr>
                  ))}
              </Table>
            ) : (
              "No Ticket"
            )}
          </div>
        )}
      </div>
    </>
  );
};
