import React, { useEffect, useState } from "react";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useSelector } from "react-redux";

export const PODetails = ({ item }) => {
  const [POData, setPOData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [product, setProduct] = useState([]);
  const [totel, setTotel] = useState(0);

  console.log(item);

  const {userid} = useSelector((state) => state.data);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "scroll");
  }, []);

  useEffect(() => {
    const fetchData = () => {
      setPOData(item);
      setProduct(item.products);
      setShowTable(true);
    };
    fetchData();
  }, [item]);

  useEffect(() => {
    const fetchData = () => {
      var totelAmm = 0;
      for (var i = 0; i < product.length; i++) {
        let amount = product[i].price * product[i].qty;
        totelAmm = totelAmm + amount;
        amount = 1;
      }
      setTotel(totelAmm);
    };
    fetchData();
  }, [product]);
  return (
    <>
      <div className="scroll-in-animation">
        <>
          {showTable ? (
            <>
              <div className="PO-order">
                <div className="PO-order1">PO Number : #{POData.quid}</div>
                <div className="PO-order2">
                  Date: {moment(POData.datetime).format("DD/MM/YYYY hh:mm:ss")}
                </div>
              </div>
              <div className="Quote-table-div">
                <table className="OH-table">
                  <tr className="OH-tr-th">
                    <th className="OH-head-row OH-head-row-td1">Name</th>
                    <th className="OH-head-row OH-head-row-td1">Total Qty</th>
                    <th className="OH-head-row OH-head-row-td1">
                      Avalible Qty
                    </th>
                    <th className="OH-head-row OH-head-row-td1">Price/Qty</th>
                    <th className="OH-head-row OH-head-row-td1">Total Price</th>
                  </tr>
                  {POData.products.map((item,i) => (
                    <tr className="OH-tr-th" key={i}>
                      <td className="OH-head-row2 OH-head-row-td1">
                        {item.description}
                      </td>
                      <td className="OH-head-row2 OH-head-row-td1">
                        {item.qty}
                      </td>
                      <td className="OH-head-row2 OH-head-row-td1">
                        {item.qty}
                      </td>
                      <td className="OH-head-row2 OH-head-row-td1">
                        {item.price}
                      </td>
                      <td className="OH-head-row2 OH-head-row-td1">
                        {item.price * item.qty}
                      </td>
                    </tr>
                  ))}
                  <tr className="OH-tr-th">
                    <td className="OH-head-row2 OH-head-row-td1"></td>
                    <td className="OH-head-row2 OH-head-row-td1"></td>
                    <td className="OH-head-row2 OH-head-row-td1"></td>
                    <td
                      className="OH-head-row2 OH-head-row-td1"
                      style={{
                        textAlign: "right",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Total:
                    </td>
                    <td
                      className="OH-head-row2 OH-head-row-td1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      <LiaRupeeSignSolid />
                      <p> {totel.toFixed(2)}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};
