import "./App.css";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Home } from "./Components/05Home/Home";
import { Dashboard } from "./Components/02Dashboard/Dashboard";
import { ItemDetails } from "./Components/06Product/ItemDetails";
import { ItemFullDetails } from "./Components/06Product/itemFullDetails";
import { useEffect, useLayoutEffect } from "react";
import { Navbar } from "./Components/01header/Navbar";
import { Service } from "./Components/04Service/Service";
import { OrderSummary } from "./Components/02Dashboard/smallComponent/childComponent/OrderSummary.jsx";
import { Help } from "./Components/07Help/Help";
import { Profile } from "./Components/03Profile/Profile";
import Cart from "./Components/08Cart/Cart";
import { ShippingAddress } from "./Components/08Cart/smallComponent/ShippingAddress";
import { CartPurchageOrder } from "./Components/08Cart/smallComponent/CartPurchaseOrder";
// import { Footer } from './Components/05Home/smallComponent/Footer';
import { Footer } from "./Components/05Home/smallComponent/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setShowLogin } from "./Redux/actions.js";
import {
  get_login_data,
  get_show_login,
  get_userid,
} from "./Redux/DataSlice.js";
import store from "./Redux/store.js";
import { Layout } from "./Comp/Layout.js";
import { ActiveOrder } from "./Components/02Dashboard/smallComponent/ActiveOrder.jsx";
import { OrderHistory } from "./Components/02Dashboard/smallComponent/OrderHistory.jsx";
import { Account } from "./Components/02Dashboard/smallComponent/Account.jsx";
import { Credit } from "./Components/02Dashboard/smallComponent/Credit.jsx";
import { Quotation } from "./Components/02Dashboard/smallComponent/Quotation.jsx";
import { PurchaseOrder } from "./Components/02Dashboard/smallComponent/PurchaseOrder.jsx";
import { ProfileInformation } from "./Components/03Profile/smallComponent/ProfileInformation.jsx";
import { Authentication } from "./Components/03Profile/smallComponent/Authentication.jsx";
import { Branches } from "./Components/03Profile/smallComponent/Branches.jsx";
import { Refferal } from "./Components/03Profile/smallComponent/Refferal.jsx";
import { Address } from "./Components/03Profile/smallComponent/Address.jsx";
function App() {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  const navigate = useNavigate();
  const { showLogin, userid } = useSelector((state) => state.data);

  useEffect(() => {
    if (userid) {
      store.dispatch(get_show_login(false));
      navigate("/");
    } else {
      navigate("/");
    }
  }, [userid]);

  useEffect(() => {
    store.dispatch(get_userid());
    if (!userid) {
      store.dispatch(get_show_login(true));
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/itemDetails" element={<ItemDetails />} />
          <Route path="/itemFullDetails" element={<ItemFullDetails />} />
          <Route path="/service" element={<Service />} />

          {/* Protected Path */}
          {userid && (
            <>
              <Route path="/help" element={<Help />} />
              <Route path="/" element={<Dashboard />}>
                <Route path="dashboard" element={<Outlet />}>
                  <Route path="ActiveOrder" element={<ActiveOrder />} />
                  <Route path="OrderHistory" element={<OrderHistory />} />
                  <Route path="Account" element={<Account />} />
                  <Route path="Credit" element={<Credit />} />
                  <Route path="Quotation" element={<Quotation />} />
                  <Route path="PurchaseOrder" element={<PurchaseOrder />} />
                </Route>
              </Route>
              <Route path="/ordersummary" element={<OrderSummary />} />
              <Route path="/" element={<Profile />}>
                <Route path="profile" element={<Outlet />}>
                  <Route
                    path="ProfileInformation"
                    element={<ProfileInformation />}
                  />
                  <Route path="Address" element={<Address />} />
                  <Route path="Authentication" element={<Authentication />} />
                  <Route path="Branches" element={<Branches />} />
                  <Route path="Refferal" element={<Refferal />} />
                </Route>
              </Route>
              <Route path="/cart" element={<Cart />} />
              <Route path="/shippingaddress" element={<ShippingAddress />} />
              <Route path="/purchageorder" element={<CartPurchageOrder />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
}

export default App;
