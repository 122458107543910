import React from "react";
import { BiCopyright } from "react-icons/bi";
import "../CSS/07Footer.css";
import facebookImg from "../../../Assets/Images/SocialMedia/fb.png";
import instaImg from "../../../Assets/Images/SocialMedia/Insta.png";
import youtubeImg from "../../../Assets/Images/SocialMedia/youtube.png";
import linkedinImg from "../../../Assets/Images/SocialMedia/linkedin.png";

export const Footer = () => {
  return (
    <div className="FT-main">
      <div className="FT-1st">
        <ul className="FT-1st-ul">
          <li className="FT-1st-li"> About Us</li>
          <li className="FT-1st-li"> Contact Us</li>
          <li className="FT-1st-li"> Terms of Use</li>
          <li className="FT-1st-li"> Privacy Policy</li>
        </ul>

        <ul className="FT-1st-ul">
          <li className="FT-1st-li" style={{ color: "black" }}>
            Follow Us on:
          </li>
          <li className="FT-1st-li">
            <img src={facebookImg} alt="" className="FT-social" />
          </li>
          <li className="FT-1st-li">
            <img src={instaImg} alt="" className="FT-social" />
          </li>
          <li className="FT-1st-li">
            <img src={youtubeImg} alt="" className="FT-social" />
          </li>
          <li className="FT-1st-li">
            <img src={linkedinImg} alt="" className="FT-social" />
          </li>
        </ul>
      </div>
      <div className="FT-copyright">
        Gaswale {<BiCopyright style={{ padding: "0 .25rem" }} />} 2023, ALL
        RIGHTS RESERVED
      </div>
    </div>
  );
};
