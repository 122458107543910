import React, { useEffect, useState } from "react";
import "./10Service.css";
import axios from "axios";
import { serviceContact } from "../../ApiRouter";
import Input from "../../Comp/Input/Input";
const objData = {
  cname: "",
  location: "",
  nature: "",
  fueltype: "",
  Eqt: "",
  fueluse: "",
  name: "",
  mobile: "",
  designation: "",
  floors: "",
  kitchens: "",
  gasAvail: "",
  banksize: "",
  mservice: "",
};

export const Service = () => {
  const [serv, setServ] = useState();
  const [selectServ, setSelectServ] = useState();
  const [obj, setObj] = useState(objData);
  const [objErr, setObjErr] = useState(objData);

  useEffect(() => {
    const fetchData = () => {
      setSelectServ("");
      setObj({
        ...obj,
        ...objData,
      });
      setObjErr({
        ...obj,
        ...objData,
      });
    };
    fetchData();
  }, [serv]);

  useEffect(() => {
    const fetchData = () => {
      setObj({
        ...obj,
        ...objData,
      });
      setObjErr({
        ...obj,
        ...objData,
      });
    };
    fetchData();
  }, [selectServ]);

  const validateMobile = (value) => {
    const mobilePattern = /^[0-9]{10}$/;

    if (!mobilePattern.test(value)) {
      return "Please enter a valid 10-digit mobile number";
    }
    return ""; // No error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = new FormData();
    body.append("serviceType", serv);
    body.append("service", selectServ);
    body.append("cname", obj.cname);
    body.append("location", obj.location);
    body.append("name", obj.name);
    body.append("mobile", obj.mobile);
    body.append("designation", obj.designation);

    if (serv === "LPG") {
      if (selectServ === "1") {
        body.append("nature", obj.nature);
        body.append("fueltype", obj.fueltype);
        body.append("Eqt", obj.Eqt);
        body.append("fueluse", obj.fueluse);
      } else if (selectServ === "2") {
        body.append("floors", obj.floors);
        body.append("kitchens", obj.kitchens);
        body.append("gasAvail", obj.gasAvail);
      } else if (selectServ === "3") {
        body.append("nature", obj.nature);
        body.append("Eqt", obj.Eqt);
        body.append("fueluse", obj.fueluse);
        body.append("banksize", obj.banksize);
      } else if (selectServ === "4") {
        body.append("nature", obj.nature);
        body.append("Eqt", obj.Eqt);
        body.append("banksize", obj.banksize);
        body.append("mservice", obj.mservice);
      }
    } else if (serv === "Others") {
      body.append("nature", obj.nature);
      body.append("fueluse", obj.fueluse);
      body.append("banksize", obj.banksize);
    }

    const response = await axios.post(serviceContact, body);
    const data = response.data.message[0];
    console.log(data);

    if (response.data.success === false) {
      setObjErr({ ...objErr, ...data });
    } else if (response.data.success === false) {
      setObjErr({
        ...objErr,
        ...objData,
      });
    }
  };
  return (
    <div className="serv">
      <div className="serv-main">
        <div className="serv-head">Services</div>
        <p className="serv-heading">
          Type of Service <i style={{ color: "red" }}>*</i>
        </p>
        <div className="serv-1st   ">
          <div className="radio">
            <input
              type="radio"
              className="serv-radio"
              name="service"
              value="LPG"
              onChange={(e) => setServ(e.target.value)}
            />
            <label className="serv-lable">LPG</label>
          </div>
          <div className="radio ">
            <input
              type="radio"
              name="service"
              className="serv-radio"
              value="Others"
              onChange={(e) => setServ(e.target.value)}
            />
            <label className="serv-lable">Nitrogen/Oxygen/Others</label>
          </div>
        </div>
        {serv === "LPG" && (
          <div>
            <p className="serv-heading">
              Select Service<i style={{ color: "red" }}>*</i>
            </p>
            <div className="radio radio2">
              <input
                type="radio"
                className="serv-radio"
                name="selectService"
                value="1"
                onChange={(e) => setSelectServ("1")}
              />
              <label className="serv-lable">
                1. Industrial Fuel Conversion Projects on Turnkey Basis
                (HSD-LPG, FO/LDO-LPG)
              </label>
            </div>
            <div className="radio radio2">
              <input
                type="radio"
                name="selectService"
                className="serv-radio"
                value="2"
                onChange={(e) => setSelectServ("2")}
              />
              <label className="serv-lable">
                2. LPG pipeline works for Multi-storeyed Apartments / Gated
                Community projects (Reticulated Systems).
              </label>
            </div>
            <div className="radio radio2">
              <input
                type="radio"
                name="selectService"
                className="serv-radio"
                value="3"
                onChange={(e) => setSelectServ("3")}
              />
              <label className="serv-lable">
                3. Annual Maintenance Contracts of LPG Installations
              </label>
            </div>
            <div className="radio radio2">
              <input
                type="radio"
                name="selectService"
                className="serv-radio"
                value="4"
                onChange={(e) => setSelectServ("4")}
              />
              <label className="serv-lable">4. LPG Mechanic services</label>
            </div>
          </div>
        )}
        {serv === "Others" && (
          <div>
            <p className="serv-heading">
              Select Service<i style={{ color: "red" }}>*</i>
            </p>
            <div className="radio radio2">
              <input
                type="radio"
                className="serv-radio"
                name="selectService"
                value="1"
                onChange={(e) => setSelectServ(e.target.value)}
              />
              <label className="serv-lable">
                1.N2/O2 pipeline works for Industries.
              </label>
            </div>
            <div className="radio radio2">
              <input
                type="radio"
                name="selectService"
                className="serv-radio"
                value="2"
                onChange={(e) => setSelectServ(e.target.value)}
              />
              <label className="serv-lable">
                2. Annual Maintenance Contracts of N2/O2 Installations
              </label>
            </div>
            <div className="radio radio2">
              <input
                type="radio"
                name="selectService"
                className="serv-radio"
                value="3"
                onChange={(e) => setSelectServ(e.target.value)}
              />
              <label className="serv-lable">3. Mechanic services</label>
            </div>
          </div>
        )}
        {(selectServ === "1" || selectServ === "2" || selectServ === "3") &&
          serv === "Others" && (
            <form className="form-main" onSubmit={handleSubmit}>
              <div className="form-main-1st">
                <div className="form-1st">
                  <label className="form-lable">
                    Name of the Company <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder="Name of the Company"
                    type="text"
                    className="form-input"
                    value={obj.cname}
                    onChange={(e) => {
                      setObj({ ...obj, cname: e.target.value });
                      setObjErr({ ...objErr, cname: "" });
                    }}
                  />
                  {objErr.cname.length !== 0 && (
                    <p className="Error">{objErr.cname}</p>
                  )}
                  {/* <Input
                    label={"Name of the Company"}
                    error={objErr.cname}
                    value={obj.cname}
                    onChange={(e) => {
                      setObj({ ...obj, cname: e.target.value });
                      setObjErr({ ...objErr, cname: "" });
                    }}
                  /> */}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Location<i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Location"}
                    type="text"
                    className="form-input"
                    onChange={(e) => {
                      setObj({ ...obj, location: e.target.value });
                      setObjErr({ ...objErr, location: "" });
                    }}
                  />
                  {objErr.location.length !== 0 && (
                    <p className="Error">{objErr.location}</p>
                  )}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Nature of Product<i style={{ color: "red" }}>*</i>
                  </label>
                  <select
                    className="form-input select-one"
                    onChange={(e) => {
                      setObj({ ...obj, nature: e.target.value });
                      setObjErr({ ...objErr, nature: "" });
                    }}
                  >
                    <option className="select-one" hidden>
                      Product Nature
                    </option>
                    <option className="select-one" value="Food Processing">
                      Food Processing
                    </option>
                    <option className="select-one" value="Ceramics">
                      Ceramics
                    </option>
                    <option className="select-one" value="Pharma">
                      Pharma
                    </option>
                    <option className="select-one" value="Aluminium">
                      Aluminium
                    </option>
                    <option className="select-one" value="Others">
                      Others
                    </option>
                  </select>
                  {objErr.nature.length !== 0 && (
                    <p className="Error">{objErr.nature}</p>
                  )}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Avg Monthly Consumption of N2/O2/Others:
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="input-kg-div">
                    <input
                      placeholder={"Avg Monthly Consumption of N2/O2/Others:"}
                      type="text"
                      className="form-input form-input2 "
                      onChange={(e) => {
                        setObj({ ...obj, fueluse: e.target.value });
                        setObjErr({ ...objErr, fueluse: "" });
                      }}
                    />
                    <p className="input-kg-div-item">Kg</p>
                  </div>
                  {objErr.fueluse.length !== 0 && (
                    <p className="Error">{objErr.fueluse}</p>
                  )}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Cylinder Bank Size req
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={" Cylinder Bank Size req"}
                    type="text"
                    className="form-input"
                    onChange={(e) => {
                      setObj({ ...obj, banksize: e.target.value });
                      setObjErr({ ...objErr, banksize: "" });
                    }}
                  />
                  {objErr.banksize.length !== 0 && (
                    <p className="Error">{objErr.banksize}</p>
                  )}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Concern Person Name<i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Concern Person Name"}
                    type="text"
                    className="form-input"
                    onChange={(e) => {
                      setObj({ ...obj, name: e.target.value });
                      setObjErr({ ...objErr, name: "" });
                    }}
                  />
                  {objErr.name.length !== 0 && (
                    <p className="Error">{objErr.name}</p>
                  )}
                </div>

                <div className="form-1st">
                  <label className="form-lable">
                    Contact No<i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Contact No"}
                    type="text"
                    className="form-input"
                    onChange={(e) => {
                      setObj({ ...obj, mobile: e.target.value });
                      setObjErr({ ...objErr, mobile: "" });
                    }}
                  />
                  {objErr.mobile.length !== 0 && (
                    <p className="Error">{objErr.mobile}</p>
                  )}
                </div>
                <div className="form-1st">
                  <label className="form-lable">
                    Designation<i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Designation"}
                    type="text"
                    className="form-input"
                    onChange={(e) => {
                      setObj({ ...obj, designation: e.target.value });
                      setObjErr({ ...objErr, designation: "" });
                    }}
                  />
                  {objErr.designation.length !== 0 && (
                    <p className="Error">{objErr.designation}</p>
                  )}
                </div>
              </div>
              <button className="form-submit-btn">Submit</button>
            </form>
          )}
        {selectServ !== "" && serv === "LPG" && (
          <form className="form-main" onSubmit={handleSubmit}>
            <div className="form-main-1st">
              <div className="form-1st">
                <label className="form-lable">
                Designation <i style={{ color: "red" }}>*</i>
                </label>
                <input
                  placeholder={"Designation"}
                  type="text"
                  className="form-input"
                  value={obj.cname}
                  onChange={(e) => {
                    setObj({ ...obj, cname: e.target.value });
                    setObjErr({ ...objErr, cname: "" });
                  }}
                />
                {objErr.cname.length !== 0 && (
                  <p className="Error">{objErr.cname}</p>
                )}
              </div>
              <div className="form-1st">
                <label className="form-lable">
                  Location<i style={{ color: "red" }}>*</i>
                </label>
                <input
                  placeholder={"Location"}
                  type="text"
                  className="form-input"
                  value={obj.location}
                  onChange={(e) => {
                    setObj({ ...obj, location: e.target.value });
                    setObjErr({ ...objErr, location: "" });
                  }}
                />
                {objErr.location.length !== 0 && (
                  <p className="Error">{objErr.location}</p>
                )}
              </div>
              {(selectServ === "1" || selectServ === "3") && (
                <>
                  <div className="form-1st">
                    <label className="form-lable">
                      Nature of Product<i style={{ color: "red" }}>*</i>
                    </label>
                    <select
                      className="form-input"
                      value={obj.nature}
                      onChange={(e) => {
                        setObj({ ...obj, nature: e.target.value });
                        setObjErr({ ...objErr, nature: "" });
                      }}
                    >
                      <option hidden> Product Nature</option>
                      <option value="Food Processing"> Food Processing </option>
                      <option value="Ceramics"> Ceramics</option>
                      <option value="Pharma"> Pharma </option>
                      <option value="Aluminium"> Aluminium </option>
                      <option value="Others"> Others </option>
                    </select>
                    {objErr.nature.length !== 0 && (
                      <p className="Error">{objErr.nature}</p>
                    )}
                  </div>
                </>
              )}
              {selectServ === "1" && (
                <div className="form-1st">
                  <label className="form-lable">
                    Select Fuel<i style={{ color: "red" }}>*</i>
                  </label>
                  <select
                    className="form-input"
                    value={obj.fueltype}
                    onChange={(e) => {
                      setObj({ ...obj, fueltype: e.target.value });
                      setObjErr({ ...objErr, fueltype: "" });
                    }}
                  >
                    <option hidden> Select Fuel</option>
                    <option value="FO"> FO </option>
                    <option value="LDO"> LDO</option>
                    <option value="HSD"> HSD </option>
                    <option value="BRICKETS"> BRICKETS </option>
                    <option value="COAL"> COAL </option>
                    <option value="HUSK"> HUSK </option>
                  </select>
                  {objErr.fueltype.length !== 0 && (
                    <p className="Error">{objErr.fueltype}</p>
                  )}
                </div>
              )}

              {(selectServ === "1" ||
                selectServ === "3" ||
                selectServ === "4") && (
                <div className="form-1st">
                  <label className="form-lable">
                    type of Eqt<i style={{ color: "red" }}>*</i>
                  </label>
                  <select
                    className="form-input"
                    value={obj.Eqt}
                    onChange={(e) => {
                      setObj({ ...obj, Eqt: e.target.value });
                      setObjErr({ ...objErr, Eqt: "" });
                    }}
                  >
                    <option hidden> Select Eqt</option>
                    <option value="Furnace"> Furnace </option>
                    <option value="Oven"> Oven</option>
                    <option value="Boiler"> Boiler </option>
                    <option value="Dryer"> Dryer </option>
                    <option value="Heater"> Heater </option>
                  </select>
                  {objErr.Eqt.length !== 0 && (
                    <p className="Error">{objErr.Eqt}</p>
                  )}
                </div>
              )}
              {(selectServ === "1" || selectServ === "3") && (
                <div className="form-1st">
                  <label className="form-lable">
                    Avg Monthly Consumption of Current fuel
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Avg Monthly Consumption of Current fuel"}
                    type="text"
                    className="form-input"
                    value={obj.fueluse}
                    onChange={(e) => {
                      setObj({ ...obj, fueluse: e.target.value });
                      setObjErr({ ...objErr, fueluse: "" });
                    }}
                  />
                  {objErr.fueluse.length !== 0 && (
                    <p className="Error">{objErr.fueluse}</p>
                  )}
                </div>
              )}
              {selectServ === "2" && (
                <>
                  <div className="form-1st">
                    <label className="form-lable">
                      No of Floors<i style={{ color: "red" }}>*</i>
                    </label>
                    <input
                      placeholder={"No of Floors"}
                      type="text"
                      className="form-input"
                      value={obj.floors}
                      onChange={(e) => {
                        setObj({ ...obj, floors: e.target.value });
                        setObjErr({ ...objErr, floors: "" });
                      }}
                    />
                    {objErr.floors.length !== 0 && (
                      <p className="Error">{objErr.floors}</p>
                    )}
                  </div>
                  <div className="form-1st">
                    <label className="form-lable">
                      No of Kitchens<i style={{ color: "red" }}>*</i>
                    </label>
                    <input
                      placeholder={" No of Kitchens"}
                      type="text"
                      className="form-input"
                      value={obj.kitchens}
                      onChange={(e) => {
                        setObj({ ...obj, kitchens: e.target.value });
                        setObjErr({ ...objErr, kitchens: "" });
                      }}
                    />
                    {objErr.kitchens.length !== 0 && (
                      <p className="Error">{objErr.kitchens}</p>
                    )}
                  </div>
                  <div className="form-1st">
                    <label className="form-lable">
                      Near by Natural Gas availability
                      <i style={{ color: "red" }}>*</i>
                    </label>
                    <select
                      className="form-input"
                      value={obj.gasAvail}
                      onChange={(e) => {
                        setObj({ ...obj, gasAvail: e.target.value });
                        setObjErr({ ...objErr, gasAvail: "" });
                      }}
                    >
                      <option hidden> Select Distance</option>
                      <option value="Less than 4 Km"> Less than 4 Km </option>
                      <option value="4 Km to 10 Km"> 4 Km to 10 Km</option>
                      <option value="no Natural Gas"> no Natural Gas </option>
                    </select>
                    {objErr.gasAvail.length !== 0 && (
                      <p className="Error">{objErr.gasAvail}</p>
                    )}
                  </div>
                </>
              )}
              {(selectServ === "3" || selectServ === "4") && (
                <div className="form-1st">
                  <label className="form-lable">
                    Cylinder Bank Size req
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Cylinder Bank Size req"}
                    type="text"
                    className="form-input"
                    value={obj.banksize}
                    onChange={(e) => {
                      setObj({ ...obj, banksize: e.target.value });
                      setObjErr({ ...objErr, banksize: "" });
                    }}
                  />
                  {objErr.banksize.length !== 0 && (
                    <p className="Error">{objErr.banksize}</p>
                  )}
                </div>
              )}
              {selectServ === "4" && (
                <div className="form-1st">
                  <label className="form-lable">
                    Specify Service Required<i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    placeholder={"Specify Service Required"}
                    type="text"
                    className="form-input"
                    value={obj.mservice}
                    onChange={(e) => {
                      setObj({ ...obj, mservice: e.target.value });
                      setObjErr({ ...objErr, mservice: "" });
                    }}
                  />
                  {objErr.mservice.length !== 0 && (
                    <p className="Error">{objErr.mservice}</p>
                  )}
                </div>
              )}

              <div className="form-1st">
                <label className="form-lable">
                  Concern Person Name<i style={{ color: "red" }}>*</i>
                </label>
                <input
                  placeholder={"Concern Person Name"}
                  type="text"
                  className="form-input"
                  value={obj.name}
                  onChange={(e) => {
                    setObj({ ...obj, name: e.target.value });
                    setObjErr({ ...objErr, name: "" });
                  }}
                />
                {objErr.name.length !== 0 && (
                  <p className="Error">{objErr.name}</p>
                )}
              </div>

              <div className="form-1st">
                <label className="form-lable">
                  Contact No<i style={{ color: "red" }}>*</i>
                </label>
                <input
                  placeholder={"Contact No"}
                  type="text"
                  className="form-input"
                  // onChange={(e) => setObj({ ...obj, mobile: e.target.value })}
                  value={obj.mobile}
                  onChange={(e) => {
                    const value = e.target.value;
                    const error = validateMobile(value);
                    setObj({ ...obj, mobile: value });
                    setObjErr({ ...objErr, mobile: error });
                  }}
                />
                {objErr.mobile.length !== 0 && (
                  <p className="Error"> {objErr.mobile}</p>
                )}
              </div>
              <div className="form-1st">
                <label className="form-lable">
                  Designation<i style={{ color: "red" }}>*</i>
                </label>
                <input
                  placeholder={"Designation"}
                  type="text"
                  className="form-input"
                  value={obj.designation}
                  onChange={(e) => {
                    setObj({ ...obj, designation: e.target.value });
                    setObjErr({ ...objErr, designation: "" });
                  }}
                />
                {objErr.designation.length !== "" && (
                  <p className="Error">{objErr.designation}</p>
                )}
              </div>
            </div>
            <button className="form-submit-btn">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};