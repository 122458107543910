import React from "react";
import { useNavigate } from "react-router-dom";

export const RaiseATicket = () => {
  const navigate = useNavigate();
  return (
    <div className="AO-1st">
      <button className="AO-1st-btn" onClick={() => navigate("/help")}>
        Raise a ticket
      </button>
    </div>
  );
};
