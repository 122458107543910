import React, { useEffect, useState } from "react";
import "../../03Profile/12Profile.css";
import { AiOutlineEye } from "react-icons/ai";
import {
  purchaseOrderListAPI,
  profileAPI,
  addressListAPI,
  paymentListAPI,
  createOrderAPI,
} from "../../../ApiRouter";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const CartPurchageOrder = () => {
  const [itemLink, setItemLink] = useState("PurchageOrder");
  const [purChaseOrderList, setPurchaseOrderList] = useState([]);
  const [obj, setObj] = useState({
    paymentmode: "",
    paymentstatus: "",
    paymentid: "",
  });
  const [payList, setPayList] = useState([]);
    const {userid} = useSelector((state) => state.data);

  const orderData = useSelector((state) => state.orderData.orderData[0]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const body = new FormData();
  //     body.append("userid", userid);
  //     const response = await axios.post(profileAPI, body);

  //     var data = response.data.data;
  //     if (response.data.success === true) {
  //       setObj({
  //         ...obj,
  //         email: data[0].email,
  //       });
  //     }
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const body = new FormData();
  //     body.append("userid", userid);
  //     const AddResponse = await axios.post(addressListAPI, body);
  //     const newData = AddResponse.data.data;
  //     if (AddResponse.data.success === true) {
  //       const filterData = newData.filter((item) => item.shipping == 1);

  //       setObj({
  //         ...obj,
  //         mobile: filterData[0].mobile,
  //         address: filterData[0].id,
  //       });
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
    handlePayList();
  }, []);

  const fetchData = async () => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("ingress", 4);
    const response = await axios.post(purchaseOrderListAPI, body);
    setPurchaseOrderList(response.data.data);
  };
  const handlePayList = async () => {
    const body = new FormData();
    body.append("userid", userid);
    const response = await axios.post(paymentListAPI, body);
    if (response.data.success === true) {
      setPayList(response.data.paylist);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = new FormData();
    body.append("userid", userid);
    body.append("email", payList.email);
    body.append("mobile", payList.mobile);
    body.append("orderid", payList.orderid);
    body.append("address", payList.address);
    payList.itemcodes.forEach((item) => body.append("itemcodes[]", item));
    payList.count.forEach((item) => body.append("count[]", item));
    payList.price.forEach((item) => body.append("price[]", item));
    payList.returncylinder.forEach((item) =>
      body.append("returncylinder[]", item)
    );
    payList.securitydeposit.forEach((item) =>
      body.append("securitydeposit[]", item)
    );
    body.append("totalamount", payList.totalamount);
    body.append("paymentmode", obj.paymentmode);
    body.append("paymentstatus", obj.paymentstatus);
    body.append("paymentid", obj.paymentid);
    body.append("schedule", orderData.sch);
    body.append("scheduleslot", orderData.schLot);
    body.append("totalamount", payList.totalamount);

    const response = await axios.post(createOrderAPI, body);
    if (response.data.success === true) {
      navigate("/");
    }
  };

  return (
    <>
      <div className="Purchase">
        <div className="Purchase-1st">
          <ul className="Purchase-1st-ul">
            <li
              className={`Purchase-1st-li`} // ${itemLink === "ProfileInformation" ? "item-link" : ""}
              onClick={() => {
                setItemLink("PurchageOrder");
                setObj({
                  ...obj,
                  paymentmode: "",
                  paymentstatus: "",
                  paymentid: "",
                });
              }}
            >
              <input
                type="radio"
                name="l1"
                className="Purchase-radio"
                value="PurchageOrder"
                checked={itemLink === "PurchageOrder" ? true : false}
                onChange={(e) => {
                  setItemLink(e.target.value);
                  setObj({
                    ...obj,
                    paymentmode: "",
                    paymentstatus: "",
                    paymentid: "",
                  });
                }}
              />
              <p>Purchage Order</p>
            </li>
            <li
              className={`Purchase-1st-li `} // ${ itemLink === "Address" ? "item-link" : ""}
              onClick={() => {
                setItemLink("COD");
                setObj({
                  ...obj,
                  paymentmode: "COD",
                  paymentstatus: 2,
                  paymentid: 1,
                });
              }}
            >
              <input
                type="radio"
                name="l1"
                className="Purchase-radio"
                value="COD"
                checked={itemLink === "COD" ? true : false}
                onChange={(e) => {
                  setItemLink(e.target.value);
                  setObj({
                    ...obj,
                    paymentmode: "COD",
                    paymentstatus: 2,
                    paymentid: 1,
                  });
                }}
              />
              <p>Cash on Delivery</p>
            </li>
          </ul>
        </div>

        <div className="Purchase-2nd">
          {itemLink === "PurchageOrder" ? (
            <>
              <div className="Purchage-tittle">
                Select purchase order against which this order is placed
              </div>
              <div className="Purchase-items">
                {purChaseOrderList &&
                  purChaseOrderList.map((item,i) => (
                    <div className="Purchase-item" key={i}>
                      <div className="Purchase-left">
                        <div className="Purchase-ord">#{item.ponum}</div>
                        <div className="Purchase-prd">
                          {moment(item.createdat).format("DD/MM/YYYY")}
                        </div>
                        <div className="Purchase-prd">
                          Products : {item.products.length}
                        </div>
                      </div>
                      <div className="Purchase-right">
                        <AiOutlineEye
                          className="OH-icons Purchase-icons"
                          style={{
                            fontSize: "25px",
                            color: "#0d6efd",
                            cursor: "pointer",
                          }}
                        />
                        <input
                          type="radio"
                          className="PO-radio"
                          name="r2"
                          onClick={() => {
                            setObj({
                              ...obj,
                              paymentid: item.id,
                              paymentmode: "PO",
                              paymentstatus: 1,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="Purchase-PO-div">
                <button
                  className="Ship-02nd-btn"
                  style={{ backgroundColor: "#198754" }}
                  onClick={handleSubmit}
                >
                  Place Order
                </button>
              </div>
            </>
          ) : (
            ""
          )}
          {itemLink === "COD" ? (
            <>
              <div className="Purchase-COD-div">
                <button
                  className="Ship-02nd-btn"
                  style={{ backgroundColor: "#198754" }}
                  onClick={handleSubmit}
                >
                  Place Order
                </button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
