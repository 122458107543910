import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { base_url } from "../../utils/utils";

export const get_login_data = createAsyncThunk("login_data", async (data) => {
  return data;
});
export const get_order_data = createAsyncThunk("order_data", async (data) => {
  return data;
});

export const get_show_login = createAsyncThunk("show_login", async (data) => {
  return data;
});
export const get_userid = createAsyncThunk("userid", async (data) => {
  if (data?.length > 0) {
    return data;
  } else if (localStorage.getItem("userid")) {
    return localStorage.getItem("userid");
  } else {
    return "";
  }
});

const initialState = {
  showLogin: false,
  login_data: {},
  order_data: [],
  order_loading: false,
  userid: "",
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(get_login_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.reciept_list = data.data;
    });
    builder.addCase(get_show_login.fulfilled, (state, action) => {
      const data = action.payload;
      state.showLogin = data;
    });
    builder.addCase(get_userid.fulfilled, (state, action) => {
        const data = action.payload;
        state.userid = data;
      });

    builder.addCase(get_order_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.order_loading = false;

      if (data.success) {
        state.order_data = data.data;
      } else {
        state.order_data = [];
      }
    });
    builder.addCase(get_order_data.pending, (state, action) => {
      state.order_loading = true;
    });
    builder.addCase(get_order_data.rejected, (state, action) => {
      state.order_loading = false;
    });
  },
});

export default dataSlice.reducer;
