import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { Switch, Tooltip } from "antd";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  addressListAPI,
  completeListAPI,
  createAddressAPI,
  deleteAddressAPI,
  updateShippingAPI,
} from "../../../ApiRouter";
import MyMap from "./childComponent/myMap";
import { Modal } from "antd";
import EditAddress from "./childComponent/EditAddress";
import {
  MobileNumberValidator,
  PinCodeValidator,
  StringValidator,
} from "../../../Validator";
import { LoginPopUp } from "../../Cards/LoginPopUp";
import { AddressCard } from "../../../Comp/Cards";
import { Input, Select } from "../../../Comp/Input/Input";

export const Address = () => {
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [newState, setNewState] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [AddressId, setAddressId] = useState("");
  const [updateShip, setUpdateShip] = useState({
    id: "",
    value: "",
  });
  const { userid } = useSelector((state) => state.data);
  const [obj, setObj] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    District: "",
    PinCode: "",
  });

  const [objErr, setObjErr] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    District: "",
    PinCode: "",
  });
  const [modal1Open, setModal1Open] = useState(false);
  const [updateShipSuccess, setUpdateShipSuccesss] = useState(false);
  const [updateShipSuccesMessage, setUpdateShipSuccesMessage] = useState("");

  const closeEditAddress = () => {
    setModal1Open(false);
  };

  const handleSuccessPopup = () => {
    setUpdateShipSuccesss(false);
  };

  const handlSeteLatlong = (e) => {
    setObj({ ...obj, Latlong: e });
    setObjErr({ ...objErr, Latlong: "" });
  };

  //  UseEffect for StateList
  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
    };
    fetchData();
  }, []);

  //  UseEffect for District List
  useEffect(() => {
    const fetchData = async () => {
      const filteredState = stateList.find((item) => item.name === obj.State);

      if (filteredState) {
        const st = filteredState.code;
        const body = new FormData();
        body.append("stateid", st);
        const response = await axios.post(completeListAPI, body);
        if (response.data.success === true) {
          setDistrictList(response.data.data.reponselist.districts);
        }
      } else {
        // console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [obj.State]);

  //  UseEffect for Address List
  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(addressListAPI, body);
      setAddressList(response.data.data);
    };
    fetchData();
  }, [newState, updateShipSuccess]);

  const handleDeleteAdd = async (AddId) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("addressid", AddId);
    const response = await axios.post(deleteAddressAPI, body);
    if (response.data.success === true) {
      alert("address Deleted");
      setNewState(!newState);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      MobileNumberValidator(obj.InchargeMobile) === true &&
      StringValidator(obj.InchargeName, "InchargeName", 3, 20) === true &&
      StringValidator(obj.Address, "Address", 24, 1000) === true &&
      StringValidator(obj.State, "State", 1, 50) === true &&
      StringValidator(obj.District, "District", 1, 50) === true &&
      PinCodeValidator(obj.PinCode, "PinCode") === true &&
      StringValidator(obj.Latlong, "Latlong", 1) === true
    ) {
      const body = new FormData();
      body.append("name", obj.InchargeName);
      body.append("mobile", obj.InchargeMobile);
      body.append("address", obj.Address);
      body.append("userid", userid);
      body.append("action", "addAddress");
      body.append("landmark", obj.Landmark);
      body.append("state", obj.State);
      body.append("district", obj.District);
      body.append("pincode", obj.PinCode);
      body.append("latlong", obj.Latlong.lat + "," + obj.Latlong.lng);

      const response = await axios.post(createAddressAPI, body);
      if (response.data.success === true) {
        setShowAddAddress(false);
        setNewState(!newState);
      }
    } else {
      setObjErr({
        ...objErr,
        InchargeMobile:
          MobileNumberValidator(obj.InchargeMobile) !== true
            ? MobileNumberValidator(obj.InchargeMobile)
            : "",
        InchargeName:
          StringValidator(obj.InchargeName, "InchargeName", 3, 20) !== true
            ? StringValidator(obj.InchargeName, "InchargeName", 3, 20)
            : "",
        Address:
          StringValidator(obj.Address, "Address", 24, 1000) !== true
            ? StringValidator(obj.Address, "Address", 24, 1000)
            : "",
        State:
          StringValidator(obj.State, "State", 1, 50) !== true
            ? "State is required"
            : "",
        District:
          StringValidator(obj.District, "District", 1, 50) !== true
            ? "District is required"
            : "",
        PinCode:
          PinCodeValidator(obj.PinCode, "PinCode") !== true
            ? PinCodeValidator(obj.PinCode, "PinCode")
            : "",
        Latlong:
          StringValidator(obj.Latlong, "Latlong", 1) !== true
            ? "Point Shipping Address on Map"
            : "",
      });
    }
  };

  const onChange = async (SwitchId, shipStatus) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("id", SwitchId);
    if (shipStatus == 0) {
      body.append("value", 1);
    } else if (shipStatus == 1) {
      body.append("value", 0);
    }

    const response = await axios.post(updateShippingAPI, body);
    if (response.data.success == true) {
      setUpdateShipSuccesss(true);
      setUpdateShipSuccesMessage(response.data.message);
    } else {
      console.log("np");
    }
  };

  return (
    <div className="Add">
      <div className="Add-01st">Shipping Address</div>
      <div className="Add-02nd">
        <button
          className="Add-02nd-btn"
          style={{ backgroundColor: showAddAddress ? "gray" : "" }}
          onClick={() => setShowAddAddress(!showAddAddress)}
        >
          {showAddAddress ? "Cancel" : "Add Address"}
        </button>
      </div>
      {showAddAddress ? (
        <form onSubmit={handleSubmit} className="Add-form scroll-in-animation">
          <Input
            value={obj?.InchargeName}
            onChange={(e) => {
              setObj({ ...obj, InchargeName: e.target.value });
              setObjErr({ ...objErr, InchargeName: "" });
            }}
            lable={"Incharge Name"}
            error={objErr.InchargeName}
          />
          <Input
            value={obj?.InchargeMobile}
            onChange={(e) => {
              setObj({ ...obj, InchargeMobile: e.target.value });
              setObjErr({ ...objErr, InchargeMobile: "" });
            }}
            lable={"Incharge Mobile"}
            error={objErr.InchargeMobile}
          />

          <Input
            value={obj?.Address}
            onChange={(e) => {
              setObj({ ...obj, Address: e.target.value });
              setObjErr({ ...objErr, Address: "" });
            }}
            lable={"Address"}
            error={objErr.Address}
          />

          <Input
            value={obj?.Landmark}
            onChange={(e) => {
              setObj({ ...obj, Landmark: e.target.value });
              setObjErr({ ...objErr, Landmark: "" });
            }}
            lable={"Landmark"}
            error={objErr.Landmark}
          />

          <Select
            value={obj?.State}
            onChange={(e) => {
              setObj({ ...obj, State: e.target.value });
              setObjErr({ ...objErr, State: "" });
            }}
            lable={"State"}
            error={objErr.State}
          >
            <option hidden>Select State</option>
            {stateList.length > 0 &&
              stateList.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
          </Select>

          <Select
            value={obj?.District}
            onChange={(e) => {
              setObj({ ...obj, District: e.target.value });
              setObjErr({ ...objErr, District: "" });
            }}
            lable={"District"}
            error={objErr.District}
          >
            <option hidden>Select District</option>
            {districtList.length > 0 &&
              districtList.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
          </Select>

          <Input
            value={obj?.PinCode}
            onChange={(e) => {
              setObj({ ...obj, PinCode: e.target.value });
              setObjErr({ ...objErr, PinCode: "" });
            }}
            lable={"Pin Code"}
            error={objErr.PinCode}
          />

          <div className="Add-form-div">
            <p className="visiblity-none">np </p>
            <button
              className="Add-map-btn"
              onClick={(e) => {
                setShowMap(!showMap);
                e.preventDefault();
              }}
            >
              <HiOutlineLocationMarker style={{ fontSize: "20px" }} />
              <p>Point on Map </p>
            </button>
            {objErr.Latlong !== "" && <p className="error">{objErr.Latlong}</p>}
          </div>
          {showMap && (
            <div className="Add-map-div-2">
              <MyMap handlSeteLatlong={handlSeteLatlong} />
            </div>
          )}
          <div className="Add-update-div">
            <button className="Add-update-btn">Submit</button>
          </div>
        </form>
      ) : (
        <>
          {addressList.length > 0 ? (
            <div className="grid-container">
              {addressList.map((item, i) => (
                <AddressCard
                  key={i}
                  checked={item.shipping === 1 ? true : false}
                  onEdit={() => {
                    setModal1Open(true);
                    setAddressId(item);
                  }}
                  onDelete={() => handleDeleteAdd(item.id)}
                  onChange={() => onChange(item.id, item.shipping)}
                  item={item}
                />
              ))}
            </div>
          ) : (
            "No Address Saved"
          )}
        </>
      )}

      {modal1Open && (
        <Modal
          footer={null}
          title="Address"
          className="Add-edit-modal"
          style={{
            top: 20,
          }}
          open={modal1Open}
          okButtonProps="false"
          onOk={() => setModal1Open(false)}
          onCancel={() => setModal1Open(false)}
        >
          <EditAddress data={AddressId} closeEditAddress={closeEditAddress} />
        </Modal>
      )}

      {updateShipSuccess == true && (
        <Modal
          centered={true}
          className="center"
          footer={null}
          header={null}
          closeIcon={false}
          style={{
            translate: "transform(-50% -50%)",
          }}
          closable="false"
          open={updateShipSuccess}
          okButtonProps="false"
          // onCancel={() => setIsModalOpen(false)}
        >
          <LoginPopUp
            handleSuccessPopup={handleSuccessPopup}
            Message={updateShipSuccesMessage}
          />
        </Modal>
      )}
    </div>
  );
};

// export const Address = () => {
//   const [showAddAddress, setShowAddAddress] = useState(false);
//   const [newState, setNewState] = useState(false);
//   const [addressList, setAddressList] = useState([]);
//   const [stateList, setStateList] = useState([]);
//   const [districtList, setDistrictList] = useState([]);
//   const [showMap, setShowMap] = useState(false);
//   const [AddressId, setAddressId] = useState("");
//   const [updateShip, setUpdateShip] = useState({
//     id: "",
//     value: "",
//   });
//   const { userid } = useSelector((state) => state.data);
//   const [obj, setObj] = useState({
//     InchargeName: "",
//     InchargeMobile: "",
//     Address: "",
//     Latlong: "",
//     Landmark: "",
//     State: "",
//     District: "",
//     PinCode: "",
//   });

//   const [objErr, setObjErr] = useState({
//     InchargeName: "",
//     InchargeMobile: "",
//     Address: "",
//     Latlong: "",
//     Landmark: "",
//     State: "",
//     District: "",
//     PinCode: "",
//   });
//   const [modal1Open, setModal1Open] = useState(false);
//   const [updateShipSuccess, setUpdateShipSuccesss] = useState(false);
//   const [updateShipSuccesMessage, setUpdateShipSuccesMessage] = useState("");

//   const closeEditAddress = () => {
//     setModal1Open(false);
//   };

//   const handleSuccessPopup = () => {
//     setUpdateShipSuccesss(false);
//   };

//   const handlSeteLatlong = (e) => {
//     setObj({ ...obj, Latlong: e });
//     setObjErr({ ...objErr, Latlong: "" });
//   };

//   //  UseEffect for StateList
//   useEffect(() => {
//     const fetchData = async () => {
//       const body = new FormData();
//       body.append("stateid", "");
//       const response = await axios.post(completeListAPI, body);
//       setStateList(response.data.data.reponselist.states);
//     };
//     fetchData();
//   }, []);

//   //  UseEffect for District List
//   useEffect(() => {
//     const fetchData = async () => {
//       const filteredState = stateList.find((item) => item.name === obj.State);

//       if (filteredState) {
//         const st = filteredState.code;
//         const body = new FormData();
//         body.append("stateid", st);
//         const response = await axios.post(completeListAPI, body);
//         if (response.data.success === true) {
//           setDistrictList(response.data.data.reponselist.districts);
//         }
//       } else {
//         // console.error("State not found in stateList");
//       }
//     };
//     fetchData();
//   }, [obj.State]);

//   //  UseEffect for Address List
//   useEffect(() => {
//     const fetchData = async () => {
//       const body = new FormData();
//       body.append("userid", userid);
//       const response = await axios.post(addressListAPI, body);
//       setAddressList(response.data.data);
//     };
//     fetchData();
//   }, [newState, updateShipSuccess]);

//   const handleDeleteAdd = async (AddId) => {
//     const body = new FormData();
//     body.append("userid", userid);
//     body.append("addressid", AddId);
//     const response = await axios.post(deleteAddressAPI, body);
//     if (response.data.success === true) {
//       alert("address Deleted");
//       setNewState(!newState);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (
//       MobileNumberValidator(obj.InchargeMobile) === true &&
//       StringValidator(obj.InchargeName, "InchargeName", 3, 20) === true &&
//       StringValidator(obj.Address, "Address", 24, 1000) === true &&
//       StringValidator(obj.State, "State", 1, 50) === true &&
//       StringValidator(obj.District, "District", 1, 50) === true &&
//       PinCodeValidator(obj.PinCode, "PinCode") === true &&
//       StringValidator(obj.Latlong, "Latlong", 1) === true
//     ) {
//       const body = new FormData();
//       body.append("name", obj.InchargeName);
//       body.append("mobile", obj.InchargeMobile);
//       body.append("address", obj.Address);
//       body.append("userid", userid);
//       body.append("action", "addAddress");
//       body.append("landmark", obj.Landmark);
//       body.append("state", obj.State);
//       body.append("district", obj.District);
//       body.append("pincode", obj.PinCode);
//       body.append("latlong", obj.Latlong.lat + "," + obj.Latlong.lng);

//       const response = await axios.post(createAddressAPI, body);
//       if (response.data.success === true) {
//         setShowAddAddress(false);
//         setNewState(!newState);
//       }
//     } else {
//       setObjErr({
//         ...objErr,
//         InchargeMobile:
//           MobileNumberValidator(obj.InchargeMobile) !== true
//             ? MobileNumberValidator(obj.InchargeMobile)
//             : "",
//         InchargeName:
//           StringValidator(obj.InchargeName, "InchargeName", 3, 20) !== true
//             ? StringValidator(obj.InchargeName, "InchargeName", 3, 20)
//             : "",
//         Address:
//           StringValidator(obj.Address, "Address", 24, 1000) !== true
//             ? StringValidator(obj.Address, "Address", 24, 1000)
//             : "",
//         State:
//           StringValidator(obj.State, "State", 1, 50) !== true
//             ? "State is required"
//             : "",
//         District:
//           StringValidator(obj.District, "District", 1, 50) !== true
//             ? "District is required"
//             : "",
//         PinCode:
//           PinCodeValidator(obj.PinCode, "PinCode") !== true
//             ? PinCodeValidator(obj.PinCode, "PinCode")
//             : "",
//         Latlong:
//           StringValidator(obj.Latlong, "Latlong", 1) !== true
//             ? "Point Shipping Address on Map"
//             : "",
//       });
//     }
//   };

//   const onChange = async (SwitchId, shipStatus) => {
//     const body = new FormData();
//     body.append("userid", userid);
//     body.append("id", SwitchId);
//     if (shipStatus == 0) {
//       body.append("value", 1);
//     } else if (shipStatus == 1) {
//       body.append("value", 0);
//     }

//     const response = await axios.post(updateShippingAPI, body);
//     if (response.data.success == true) {
//       setUpdateShipSuccesss(true);
//       setUpdateShipSuccesMessage(response.data.message);
//     } else {
//       console.log("np");
//     }
//   };

//   return (
//     <div className="Add">
//       <div className="Add-01st">Shipping Address</div>
//       <div className="Add-02nd">
//         <button
//           className="Add-02nd-btn"
//           style={{ backgroundColor: showAddAddress ? "gray" : "" }}
//           onClick={() => setShowAddAddress(!showAddAddress)}
//         >
//           {showAddAddress ? "Cancel" : "Add Address"}
//         </button>
//       </div>
//       {showAddAddress ? (
//         <form onSubmit={handleSubmit} className="Add-form scroll-in-animation">
//           {/* <div className="Add-form-div">
//             <p>Incharge Name </p>
//             <input
//               type="text"
//               onChange={(e) => {
//                 setObj({ ...obj, InchargeName: e.target.value });
//                 setObjErr({ ...objErr, InchargeName: "" });
//               }}
//               className="Add-input"
//             />
//             {objErr.InchargeName !== "" && (
//               <p className="error">{objErr.InchargeName}</p>
//             )}
//           </div> */}
//           <Input
//             onChange={(e) => {
//               setObj({ ...obj, InchargeName: e.target.value });
//               setObjErr({ ...objErr, InchargeName: "" });
//             }}
//             lable={"Incharge Name"}
//             error={objErr.InchargeName}
//           />
//           <div className="Add-form-div">
//             <p>Incharge Mobile </p>
//             <input
//               type="text"
//               onChange={(e) => {
//                 setObj({ ...obj, InchargeMobile: e.target.value });
//                 setObjErr({ ...objErr, InchargeMobile: "" });
//               }}
//               className="Add-input"
//             />
//             {objErr.InchargeMobile !== "" && (
//               <p className="error">{objErr.InchargeMobile}</p>
//             )}
//           </div>
//           <div className="Add-form-div">
//             <p>Address </p>
//             <input
//               type="text"
//               onChange={(e) => {
//                 setObj({ ...obj, Address: e.target.value });
//                 setObjErr({ ...objErr, Address: "" });
//               }}
//               className="Add-input"
//             />
//             {objErr.Address !== "" && <p className="error">{objErr.Address}</p>}
//           </div>

//           <div className="Add-form-div">
//             <p>Landmark </p>
//             <input
//               type="text"
//               onChange={(e) => setObj({ ...obj, Landmark: e.target.value })}
//               className="Add-input"
//             />
//           </div>
//           <div className="Add-form-div">
//             <p>State </p>
//             <select
//               className="Add-input"
//               onChange={(e) => {
//                 setObj({ ...obj, State: e.target.value });
//                 setObjErr({ ...objErr, State: "" });
//               }}
//             >
//               <option hidden>Select State</option>
//               {stateList.length > 0 &&
//                 stateList.map((item, i) => (
//                   <option key={i} value={item.name}>
//                     {item.name}
//                   </option>
//                 ))}
//             </select>
//             {objErr.State !== "" && <p className="error">{objErr.State}</p>}
//           </div>
//           <div className="Add-form-div">
//             <p>District </p>
//             <select
//               className="Add-input"
//               onChange={(e) => {
//                 setObj({ ...obj, District: e.target.value });
//                 setObjErr({ ...objErr, District: "" });
//               }}
//             >
//               <option hidden>Select District</option>
//               {districtList.length > 0 &&
//                 districtList.map((item, i) => (
//                   <option key={i} value={item.name}>
//                     {item.name}
//                   </option>
//                 ))}
//             </select>
//             {objErr.District !== "" && (
//               <p className="error">{objErr.District}</p>
//             )}
//           </div>
//           <div className="Add-form-div">
//             <p>Pin Code </p>
//             <input
//               type="text"
//               className="Add-input"
//               onChange={(e) => {
//                 setObj({ ...obj, PinCode: e.target.value });
//                 setObjErr({ ...objErr, PinCode: "" });
//               }}
//             />
//             {objErr.PinCode !== "" && <p className="error">{objErr.PinCode}</p>}
//           </div>
//           <div className="Add-form-div">
//             <p className="visiblity-none">np </p>
//             <button
//               className="Add-map-btn"
//               onClick={(e) => {
//                 setShowMap(!showMap);
//                 e.preventDefault();
//               }}
//             >
//               <HiOutlineLocationMarker style={{ fontSize: "20px" }} />
//               <p>Point on Map </p>
//             </button>
//             {objErr.Latlong !== "" && <p className="error">{objErr.Latlong}</p>}
//           </div>
//           {showMap && (
//             <div className="Add-map-div-2">
//               <MyMap handlSeteLatlong={handlSeteLatlong} />
//             </div>
//           )}
//           <div className="Add-update-div">
//             <button className="Add-update-btn">Submit</button>
//           </div>
//         </form>
//       ) : (
//         <>
//           {addressList.length > 0 ? (
//             <div className="grid-container">
//               {addressList.map((item, i) => (

//                 <AddressCard
//                   key={i}
//                   checked={item.shipping === 1 ? true : false}
//                   onEdit={() => {
//                     setModal1Open(true);
//                     setAddressId(item);
//                   }}
//                   onDelete={() => handleDeleteAdd(item.id)}
//                   onChange={() => onChange(item.id, item.shipping)}
//                   item={item}
//                 />
//               ))}
//               {modal1Open && (
//                 <Modal
//                   footer={null}
//                   title="Address"
//                   className="Add-edit-modal"
//                   style={{
//                     top: 20,
//                   }}
//                   open={modal1Open}
//                   okButtonProps="false"
//                   onOk={() => setModal1Open(false)}
//                   onCancel={() => setModal1Open(false)}
//                 >
//                   <EditAddress
//                     data={AddressId}
//                     closeEditAddress={closeEditAddress}
//                   />
//                 </Modal>
//               )}
//             </div>
//           ) : (
//             "No Address Saved"
//           )}
//         </>
//       )}
//       {updateShipSuccess == true && (
//         <Modal
//           centered={true}
//           className="center"
//           footer={null}
//           header={null}
//           closeIcon={false}
//           style={{
//             translate: "transform(-50% -50%)",
//           }}
//           closable="false"
//           open={updateShipSuccess}
//           okButtonProps="false"
//           // onCancel={() => setIsModalOpen(false)}
//         >
//           <LoginPopUp
//             handleSuccessPopup={handleSuccessPopup}
//             Message={updateShipSuccesMessage}
//           />
//         </Modal>
//       )}
//     </div>
//   );
// };
