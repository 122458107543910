import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  generatePurchaseOrderAPI,
  purchaseOrderListAPI,
  quotationlistAPI,
} from "../../../ApiRouter";
import { PODetails } from "./childComponent/PODetails";
import { Modal } from "antd";
import { MobileNumberValidator, StringValidator } from "../../../Validator";
import { NoData } from "../../../Comp/NoData";

export const PurchaseOrder = () => {
  const [showQuote, setShowQuote] = useState(false);
  const [purChaseOrderList, setPurchaseOrderList] = useState([]);
  const [QuotationList, setQuotationList] = useState([]);
  const [POData, setPOData] = useState([]);

  const [file, setFile] = useState();
  const [ShowPO, setShowPO] = useState(false);

  const [obj, setObj] = useState({
    id: "",
    recursive: "",
    ponumber: "",
    podate: "",
    products: [],
    document: null,
  });
  const [objErr, setObjErr] = useState({
    id: "",
    recursive: "",
    ponumber: "",
    podate: "",
    products: "",
    document: null,
  });
  console.log(objErr);

  const {userid} = useSelector((state) => state.data);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      body.append("ingress", 4);
      const response = await axios.post(quotationlistAPI, body);
      setQuotationList(response.data.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      body.append("ingress", 4);
      const response = await axios.post(purchaseOrderListAPI, body);
      setPurchaseOrderList(response.data.data);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      StringValidator(obj.id, "id", 1, 10) === true &&
      StringValidator(obj.podate, "Purchase order Date", 9, 11) === true &&
      StringValidator(obj.ponumber, "Purchase Order Number", 9, 11) === true &&
      StringValidator(obj.recursive, "Recursive ", 1, 15) === true &&
      obj.products.length !== 0
    ) {
      const body = new FormData();
      body.append("id", obj.id);
      body.append("userid", userid);
      body.append("ingress", 4);
      body.append("recursive", obj.recursive);
      body.append("ponumber", obj.ponumber);
      body.append("podate", obj.podate);
      body.append("document", obj.document);
      obj.products.forEach((item) => body.append("productid[]", item.uuid));
      obj.products.forEach((item) =>
        body.append("productname[]", item.description)
      );
      obj.products.forEach((item) => body.append("qty[]", item.qty));

      const response = await axios.post(generatePurchaseOrderAPI, body);
      console.log(response);
      if (response.data.success == true) {
        setObj((prevObj) => ({
          ...prevObj,
          id: "",
          products: [],
        }));
      } else {
        alert("Error");
      }
    } else {
      setObjErr({
        ...objErr,
        id:
          StringValidator(obj.id, "id", 1, 10) !== true
            ? StringValidator(obj.id, "id", 1, 10)
            : "",
        podate:
          StringValidator(obj.podate, "Purchase order Date", 9, 11) !== true
            ? StringValidator(obj.podate, "Purchase order Date", 9, 11)
            : "",
        ponumber:
          StringValidator(obj.ponumber, "Purchase Order Number", 9, 11) !== true
            ? "District is required"
            : "",
        recursive:
          StringValidator(obj.recursive, "Recursive ", 1, 15) !== true
            ? StringValidator(obj.recursive, "Recursive ", 1, 15)
            : "",

        products: obj.products.length == 0 ? "Please Select a Quotation" : "",
      });
    }
  };
  const handleOrderSummery = (item) => {
    setPOData(item);
    setShowPO(true);
  };
  return (
    <>
      <div className="Qutation">
        <div className="AO-1st">
          <button
            style={{ backgroundColor: showQuote ? "gray" : "" }}
            className="AO-1st-btn Qutation-btn "
            onClick={() => setShowQuote(!showQuote)}
          >
            {showQuote ? "Cancel" : "Generate PO"}
          </button>
        </div>
        {showQuote ? (
          <div
            className={
              showQuote
                ? "scroll-in-animation PO-form-main"
                : "scroll-out-animation"
            }
          >
            <form className="PO-form-main">
              <div className="PO-form-1st">
                <label className="PO-form-lable">
                  Select Quotation: <i style={{ color: "red" }}>*</i>
                </label>
                <select
                  className="PO-form-input"
                  style={{ width: "45%" }}
                  onChange={(e) => {
                    const selectedItemId = e.target.value;
                    const selectedItem = QuotationList.filter(
                      (item) => item.id == selectedItemId
                    )[0];
                    if (selectedItem) {
                      {
                        setObj((prevObj) => ({
                          ...prevObj,
                          id: selectedItem.id,
                          products: selectedItem.products,
                        }));
                        setObjErr({ ...objErr, products: "" });
                      }
                    }
                  }}
                >
                  <option hidden>Select Quotation</option>
                  {QuotationList.length > 0 &&
                    QuotationList.map((item,i) => (
                      <option key={i} value={item.id}>
                        {item.quid}
                      </option>
                    ))}
                </select>
                {objErr.products !== "" && (
                  <p className="error">{objErr.products}</p>
                )}
              </div>
              <div className="PO-form-1st">
                <label className="">
                  Do you want to receive Quotation whenever the price is
                  changed? <i style={{ color: "red" }}>*</i>
                </label>
                <div className="PO-form-1st-div">
                  <div className="radio">
                    <input
                      type="radio"
                      className="serv-radio"
                      name="service"
                      value={1}
                      onChange={(e) => {
                        setObj((prevObj) => ({
                          ...prevObj,
                          recursive: e.target.value,
                        }));
                        setObjErr({ ...objErr, recursive: "" });
                      }}
                    />
                    <label className="serv-lable">Yes</label>
                  </div>
                  <div className="radio ">
                    <input
                      type="radio"
                      name="service"
                      className="serv-radio"
                      value={2}
                      onChange={(e) => {
                        setObj((prevObj) => ({
                          ...prevObj,
                          recursive: e.target.value,
                        }));
                        setObjErr({ ...objErr, recursive: "" });
                      }}
                    />
                    <label className="serv-lable">No</label>
                  </div>
                </div>
                {objErr.recursive !== "" && (
                  <p className="error">{objErr.recursive}</p>
                )}
              </div>
              <div className="PO-form">
                <div className="form-1st">
                  <label className="form-lable">
                    Purchase Order Number <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    type="text"
                    className="PO-form-input"
                    onChange={(e) => {
                      setObj((prevObj) => ({
                        ...prevObj,
                        ponumber: e.target.value,
                      }));
                      setObjErr({ ...objErr, ponumber: "" });
                    }}
                  />
                </div>
                {objErr.ponumber !== "" && (
                  <p className="error">{objErr.ponumber}</p>
                )}
                <div className="form-1st">
                  <label className="form-lable">
                    Purchase Order Date <i style={{ color: "red" }}>*</i>
                  </label>
                  <input
                    type="date"
                    className="PO-form-input"
                    onChange={(e) => {
                      setObj((prevObj) => ({
                        ...prevObj,
                        podate: e.target.value,
                      }));
                      setObjErr({ ...objErr, podate: "" });
                    }}
                  />
                </div>
                {objErr.podate !== "" && (
                  <p className="error">{objErr.podate}</p>
                )}
              </div>
              <div className="form-1st">
                <label className="form-lable">PO Document</label>
                <input
                  type="file"
                  className="PO-form-input"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setObj((prevObj) => ({
                      ...prevObj,
                      document: file,
                    }));
                  }}
                />
              </div>
            </form>
            {obj.products.length > 0 && (
              <div className="PO-form">
                <table className="PO-table">
                  <tr className="PO-tr">
                    <td className="PO-td">Products</td>
                    <td className="PO-td">Quantity</td>
                  </tr>
                  {obj.products.map((item,i) => (
                    <tr className="PO-tr" key={i}>
                      <td className="PO-td">
                        <input
                          type="text"
                          value={item.description}
                          className="PO-form-input"
                        />
                      </td>
                      <td className="PO-td">
                        <input
                          type="text"
                          value={item.qty}
                          onChange={(e) => {
                            setObj((pre) => {
                              const updatedProducts = pre.products.map((val) =>
                                val.uuid === item.uuid
                                  ? { ...val, qty: e.target.value }
                                  : val
                              );
                              return {
                                ...pre,
                                products: updatedProducts,
                              };
                            });
                          }}
                          className="PO-form-input"
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}

            <div className="get-a-qut-btn-div ">
              <button className="get-a-qut-btn " onClick={handleSubmit}>
                Generate PO
              </button>
            </div>
          </div>
        ) : (
          <div className="PO-items">
            {purChaseOrderList?.length > 0 ?
              purChaseOrderList.map((item,i) => (
                <div className="PO-item" key={i}>
                  <div className="PO-left">
                    <div className="PO-ord">#{item.ponum}</div>
                    <div className="PO-prd">{item.createdat}</div>
                    <div className="PO-prd">
                      Products : {item.products.length}
                    </div>
                  </div>
                  <div className="PO-right">
                    <AiOutlineEye
                      className="OH-icons PO-icons"
                      style={{
                        fontSize: "25px",
                        color: "#0d6efd",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOrderSummery(item)}
                      // onClick={() => handleOrderSummery(item.orderid)}
                    />
                    <BsCircleFill
                      className="OH-icons PO-icons"
                      style={{
                        fontSize: "13px",
                        color: "green",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              )):<NoData/>}
          </div>
        )}
      </div>
      {ShowPO === true && (
        // <PODetails closeModel={closeModel} item={POData} />

        <Modal
          footer={null}
          title="Purchase Order"
          className="BR-edit-modal "
          style={{
            top: 20,
          }}
          open={ShowPO}
          okButtonProps="false"
          onOk={() => setShowPO(false)}
          onCancel={() => setShowPO(false)}
        >
          <PODetails item={POData} />
        </Modal>
      )}
    </>
  );
};
