import React, { useEffect } from "react";
import "../CSS/04Location.css";
import HyderabadImg from "../../../Assets/Images/location/hyderabad.png";
import bangloreImg from "../../../Assets/Images/location/bangalore.png";

import chennaiImg from "../../../Assets/Images/location/chennai.png";

export const Location = ({ closeModel, LocationName }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "scroll");
  }, []);
  return (
    <div>
      <div className="wraper" onClick={closeModel}></div>
      <div className="loc-content">
        <div className="loc-head">Select Your Location</div>
        <div className="loc-items">
          <div className="loc-item" onClick={() => LocationName("Hyderabad")}>
            <img src={HyderabadImg} alt="" className="loc-img" />
            <p>Hyderabad</p>
            <p></p>
          </div>
          <div className="loc-item" onClick={() => LocationName("Bangalore")}>
            <img src={bangloreImg} alt="" className="loc-img" />
            <p>Bangalore</p>
            <p>(comming soon)</p>
          </div>
          <div className="loc-item" onClick={() => LocationName("Chennai")}>
            <img src={chennaiImg} alt="" className="loc-img" />
            <p>Chennai</p>
            <p>(comming soon)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
