import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  branchListAPI,
  completeListAPI,
  deleteBranchAPI,
  createBranchAPI,
} from "../../../ApiRouter";
import { Tooltip, Modal } from "antd";
import { EditBranche } from "./childComponent/EditBranche";
import { ShowBranche } from "./childComponent/ShowBranche";
import {
  EmailValidator,
  GSTValidator,
  GSTValidator2,
  MobileNumberValidator,
  PinCodeValidator,
  StringValidator,
} from "../../../Validator";
import { Input, Select } from "../../../Comp/Input/Input";

export const Branches = () => {
  const [showAddBranch, setShowAddBranch] = useState(false);
  const [branchesList, setBranchesList] = useState([]);
  const { userid } = useSelector((state) => state.data);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal1Open2, setModal1Open2] = useState(false);
  const [branchItem, setBranchItem] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [obj, setObj] = useState({
    branch: "",
    gst: "",
    pan: "",
    legalname: "",
    tradename: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    email: "",
    mobile: "",
    branchid: "",
    id: "",
    password: "",
  });
  const [objErr, setObjErr] = useState({
    branch: "",
    gst: "",
    pan: "",
    legalname: "",
    tradename: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    email: "",
    mobile: "",
    branchid: "",
    id: "",
    password: "",
  });

  const closeEditBranche = () => {
    setModal1Open(false);
  };
  const closeShowBranche = () => {
    setModal1Open(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (obj.state) {
        const filteredState = stateList.find(
          (item) => item.name === obj.state.toUpperCase()
        );
        if (filteredState) {
          const st = filteredState.code;
          const body = new FormData();
          body.append("stateid", st);
          const response = await axios.post(completeListAPI, body);
          if (response.data.success === true) {
            console.warn(response.data.data.reponselist.districts);
            setDistrictList(response.data.data.reponselist.districts);
          }
        } else {
          console.log("error");
        }
      } else {
        console.error("State not found in stateList");
      }
    };
    fetchData();
  }, [obj.state]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(branchListAPI, body);
      setBranchesList(response.data.data);
    };
    fetchData();
  }, []);

  const handleBranchDelete = async (brid) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("branchid", brid);
    const response = await axios.post(deleteBranchAPI, body);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(obj);

    if (
      MobileNumberValidator(obj.mobile) === true &&
      EmailValidator(obj.email) === true &&
      StringValidator(obj.legalname, "Legal Name", 10, 100) === true &&
      StringValidator(obj.tradename, "Trade Name", 10, 100) === true &&
      StringValidator(obj.pan, "pan", 9, 11) === true &&
      StringValidator(obj.address, "Address", 24, 1000) === true &&
      StringValidator(obj.state, "State", 1, 50) === true &&
      StringValidator(obj.district, "District", 1, 50) === true &&
      StringValidator(obj.branch, "Branch Name", 1, 50) === true &&
      StringValidator(obj.id, "Branch Id", 7, 13) === true &&
      StringValidator(obj.password, "Password", 5, 13) === true &&
      PinCodeValidator(obj.pincode, "PinCode") === true 
      &&
      await GSTValidator2(obj.gst, "GST Number") !== true
    ) {
      const body = new FormData();
      body.append("userid", userid);
      body.append("action", "branch");
      body.append("branchid", "");
      body.append("gst", obj.gst);
      body.append("branch", obj.branch);
      body.append("pan", obj.pan);
      body.append("legalname", obj.legalname);
      body.append("tradename", obj.tradename);
      body.append("address", obj.address);
      body.append("state", obj.state);
      body.append("district", obj.district);
      body.append("pincode", obj.pincode);
      body.append("mobile", obj.mobile);
      body.append("email", obj.email);
      body.append("id", obj.id);
      body.append("password", obj.password);

      const response = await axios.post(createBranchAPI, body);
      if (response.data.success === true) {
        closeEditBranche();
      } else if (response.data.success === false) {
        alert("Branch Update Failed");
      }
    } else {
      setObjErr({
        ...objErr,
        password:
          StringValidator(obj.password, "Password", 5, 13) !== true
            ? StringValidator(obj.password, "Password", 5, 13)
            : "",
        id:
          StringValidator(obj.id, "Branch Id", 7, 13) !== true
            ? StringValidator(obj.id, "Branch Id", 7, 13)
            : "",
        mobile:
          MobileNumberValidator(obj.mobile) !== true
            ? MobileNumberValidator(obj.mobile)
            : "",
        email:
          EmailValidator(obj.email) !== true ? EmailValidator(obj.email) : "",
        gst:
          await GSTValidator2(obj.gst, "GST Number") !== true
            ? await GSTValidator2(obj.gst, "GST Number")
            : "",
        pan:
          StringValidator(obj.pan, "pan", 9, 11) !== true
            ? StringValidator(obj.pan, "pan", 9, 11)
            : "",
        legalname:
          StringValidator(obj.legalname, "Legal Name", 10, 100) !== true
            ? StringValidator(obj.legalname, "Legal Name", 10, 100)
            : "",
        tradename:
          StringValidator(obj.tradename, "Trade Name", 10, 100) !== true
            ? StringValidator(obj.tradename, "Trade Name", 10, 100)
            : "",
        address:
          StringValidator(obj.address, "Address", 24, 1000) !== true
            ? StringValidator(obj.address, "Address", 24, 1000)
            : "",
        state:
          StringValidator(obj.state, "State", 1, 50) !== true
            ? "State is required"
            : "",
        district:
          StringValidator(obj.district, "District", 1, 50) !== true
            ? "District is required"
            : "",
        pincode:
          PinCodeValidator(obj.pincode, "PinCode") !== true
            ? PinCodeValidator(obj.pincode, "PinCode")
            : "",
        branch:
          StringValidator(obj.branch, "branch", 7, 13) !== true
            ? StringValidator(obj.branch, "branch", 7, 13)
            : "",
      });
    }
  };

  const handleGSTChange = async (e) => {
    const capitalData = e.target.value.toUpperCase();
    setObj((prevObj) => ({
      ...prevObj,
      gst: e.target.value.toUpperCase(),
    }));
    if (e.target.value.length === 15) {
      try {
        const data = await GSTValidator(e.target.value, "GST");
        console.log(data);
        setObj((prevObj) => ({
          ...prevObj,
          gst: e.target.value.toUpperCase(),
          legalname: data.legalname,
          pan: data.pan,
          tradename: data.tradename,
          address: data.address,
          state: data.state,
          district: data.district,
          pincode: data.pincode,
        }));
      } catch (error) {
        console.error(error);
        setObjErr({ ...objErr, gst: error });
      }
    } else {
    }
  };
  return (
    <div className="BR">
      <div className="BR-01st">
        <button
          className="BR-01st-btn"
          style={{ backgroundColor: showAddBranch ? "gray" : "" }}
          onClick={() => setShowAddBranch(!showAddBranch)}
        >
          {showAddBranch ? "Cancel" : "Add Branch"}
        </button>
      </div>
      <div className="BR-Add" style={{ display: showAddBranch ? "" : "none" }}>
        {showAddBranch && (
          <form
            onSubmit={handleSubmit}
            className="BR-Add-form scroll-in-animation "
          >
            <Input
              lable={"Branch Code/Name"}
              value={obj.branch}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  branch: e.target.value,
                }));
                setObjErr({ ...objErr, branch: "" });
              }}
              error={objErr.branch}
            />
            <Input
              lable={"Branch GST Number"}
              value={obj.gst}
              onChange={(e) => {
                handleGSTChange(e);
                setObjErr({ ...objErr, gst: "" });
              }}
              error={objErr.gst}
            />
            <Input
              lable={"Branch PAN Number"}
              value={obj.pan}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  pan: e.target.value,
                }));
                setObjErr({ ...objErr, pan: "" });
              }}
              error={objErr.pan}
            />
            <Input
              lable={"Branch Legal Name"}
              value={obj.legalname}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  legalname: e.target.value,
                }));
                setObjErr({ ...objErr, legalname: "" });
              }}
              error={objErr.legalname}
            />
            <Input
              lable={"Branch Trade Name"}
              value={obj.tradename}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  tradename: e.target.value,
                }));
                setObjErr({ ...objErr, tradename: "" });
              }}
              error={objErr.tradename}
            />
            <Input
              lable={"Branch Address"}
              value={obj.address}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  address: e.target.value,
                }));
                setObjErr({ ...objErr, address: "" });
              }}
              error={objErr.address}
            />
            <Select
              lable={"State"}
              value={obj.state}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  state: e.target.value,
                }));
                setObjErr({ ...objErr, state: "" });
              }}
              error={objErr.state}
            >
              <option hidden>
                {obj.state === "" ? "Select State" : obj.state}
              </option>
              {stateList.length > 0 &&
                stateList.map((item, i) => (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </Select>

            <Select
              lable={"District"}
              value={obj.district}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  district: e.target.value,
                }));
                setObjErr({ ...objErr, district: "" });
              }}
              error={objErr.district}
            >
              <option hidden>
                {obj.district === "" ? "Select District" : obj.district}
              </option>
              {districtList.length > 0 &&
                districtList.map((item, i) => (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </Select>

            <Input
              lable={"Branch Pin Code"}
              value={obj.pincode}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  pincode: e.target.value,
                }));
                setObjErr({ ...objErr, pincode: "" });
              }}
              error={objErr.pincode}
            />
            <Input
              lable={"Branch Email ID"}
              value={obj.email}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  email: e.target.value,
                }));
                setObjErr({ ...objErr, email: "" });
              }}
              error={objErr.email}
            />
            <Input
              lable={"Branch User ID"}
              value={obj.id}
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  id: e.target.value,
                }));
                setObjErr({ ...objErr, id: "" });
              }}
              error={objErr.id}
            />
            <Input
              lable={"Branch Password"}
              value={obj.password}
              type="password"
              onChange={(e) => {
                setObj((prevObj) => ({
                  ...prevObj,
                  password: e.target.value,
                }));
                setObjErr({ ...objErr, password: "" });
              }}
              error={objErr.password}
            />
            {/* <div className="BR-Add-form-div">
              <p>Branch Code/Name</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.branch}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    branch: e.target.value,
                  }));
                  setObjErr({ ...objErr, branch: "" });
                }}
              />
              {objErr.branch !== "" && <p className="error">{objErr.branch}</p>}
            </div> */}
            {/* <div className="BR-Add-form-div">
              <p>Branch GST Number</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.gst}
                onChange={(e) => {
                  handleGSTChange(e);
                  setObjErr({ ...objErr, gst: "" });
                }}
              />
              {objErr.gst !== "" && <p className="error">{objErr.gst}</p>}
            </div> */}
            {/* <div className="BR-Add-form-div">
              <p>Branch PAN Number</p>
              <input
                type="text"
                className="BR-Add-input"
                value={obj.pan}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    pan: e.target.value,
                  }));
                  setObjErr({ ...objErr, pan: "" });
                }}
              />
              {objErr.pan !== "" && <p className="error">{objErr.pan}</p>}
            </div> */}
            {/* <div className="BR-Add-form-div">
              <p>Branch Legal Name</p>
              <input
                type="text"
                className="BR-Add-input"
                value={obj.legalname}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    legalname: e.target.value,
                  }));
                  setObjErr({ ...objErr, legalname: "" });
                }}
              />
              {objErr.legalname !== "" && (
                <p className="error">{objErr.legalname}</p>
              )}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Trade Name</p>
              <input
                type="text"
                className="BR-Add-input"
                value={obj.tradename}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    tradename: e.target.value,
                  }));
                  setObjErr({ ...objErr, tradename: "" });
                }}
              />
              {objErr.tradename !== "" && (
                <p className="error">{objErr.tradename}</p>
              )}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Address</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.address}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    address: e.target.value,
                  }));
                  setObjErr({ ...objErr, address: "" });
                }}
              />
              {objErr.address !== "" && (
                <p className="error">{objErr.address}</p>
              )}
            </div>
            <div className="BR-Add-form-div">
              <p>State</p>
              <select
                className="BR-Add-input-edit"
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    state: e.target.value,
                    district: "",
                  }));
                  setObjErr({ ...objErr, state: "" });
                }}
              >
                <option hidden>
                  {obj.state === "" ? "Select State" : obj.state}
                </option>
                {stateList.length > 0 &&
                  stateList.map((item, i) => (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
              {objErr.state !== "" && <p className="error">{objErr.state}</p>}
            </div>
            <div className="BR-Add-form-div">
              <p>District</p>
              <select
                className="BR-Add-input-edit"
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    district: e.target.value,
                  }));
                  setObjErr({ ...objErr, district: "" });
                }}
              >
                <option hidden>
                  {obj.district === "" ? "Select District" : obj.district}
                </option>
                {districtList.length > 0 &&
                  districtList.map((item, i) => (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
              {objErr.district !== "" && (
                <p className="error">{objErr.district}</p>
              )}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Pin Code</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.pincode}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    pincode: e.target.value,
                  }));
                  setObjErr({ ...objErr, pincode: "" });
                }}
              />
              {objErr.pincode !== "" && (
                <p className="error">{objErr.pincode}</p>
              )}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Mobile</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.mobile}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    mobile: e.target.value,
                  }));
                  setObjErr({ ...objErr, mobile: "" });
                }}
              />
              {objErr.mobile !== "" && <p className="error">{objErr.mobile}</p>}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Email ID</p>
              <input
                type="text"
                value={obj.email}
                className="BR-Add-input-edit"
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    email: e.target.value,
                  }));
                  setObjErr({ ...objErr, email: "" });
                }}
              />
              {objErr.email !== "" && <p className="error">{objErr.email}</p>}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch User ID</p>
              <input
                type="text"
                className="BR-Add-input-edit"
                value={obj.id}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    id: e.target.value,
                  }));
                  setObjErr({ ...objErr, id: "" });
                }}
              />
              {objErr.id !== "" && <p className="error">{objErr.id}</p>}
            </div>
            <div className="BR-Add-form-div">
              <p>Branch Password</p>
              <input
                type="password"
                className="BR-Add-input-edit"
                value={obj.password}
                onChange={(e) => {
                  setObj((prevObj) => ({
                    ...prevObj,
                    password: e.target.value,
                  }));
                  setObjErr({ ...objErr, password: "" });
                }}
              />
              {objErr.password !== "" && (
                <p className="error">{objErr.password}</p>
              )}
            </div> */}
            <div className="BR-Add-update-div">
              <button className="BR-Add-update-btn">Add</button>
            </div>
          </form>
        )}
      </div>
      <div className="BR-tittle">Branch List</div>
      <div className="BR-list">
        <table className="BR-table">
          <tr className="BR-tr-th">
            <th className="BR-th">Sl. No</th>
            <th className="BR-th">Location Code</th>
            <th className="BR-th"> Email</th>
            <th className="BR-th"> Mobile</th>
            <th className="BR-th"> Action</th>
          </tr>
          {branchesList.length > 0 ? (
            <>
              {branchesList.map((item, index) => (
                <tr className="BR-tr-td" key={index}>
                  <td className="BR-th">{index + 1}</td>
                  <td className="BR-th">{item.branch}</td>
                  <td className="BR-th"> {item.email}</td>
                  <td className="BR-th"> {item.mobile}</td>
                  <td className="BR-th BR-th-icons">
                    <Tooltip title="view" zIndex="2">
                      <AiOutlineEye
                        className="BR-icons"
                        onClick={() => {
                          setModal1Open2(true);
                          setBranchItem(item);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Edit" zIndex="2">
                      <GrEdit
                        className="BR-icons"
                        onClick={() => {
                          setModal1Open(true);
                          setBranchItem(item);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <RiDeleteBin5Line
                        className="BR-icons"
                        onClick={() => handleBranchDelete(item.id)}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            ""
          )}
        </table>
        {modal1Open && (
          <Modal
            footer={null}
            title="Branch Edit"
            className="BR-edit-modal"
            style={{
              top: 20,
            }}
            open={modal1Open}
            okButtonProps="false"
            onOk={() => setModal1Open(false)}
            onCancel={() => setModal1Open(false)}
          >
            <EditBranche
              data={branchItem}
              closeEditBranche={closeEditBranche}
            />
          </Modal>
        )}
        {modal1Open2 && (
          <Modal
            footer={null}
            title="Branch View"
            className="BR-edit-modal"
            style={{
              top: 20,
            }}
            open={modal1Open2}
            okButtonProps="false"
            onOk={() => setModal1Open2(false)}
            onCancel={() => setModal1Open2(false)}
          >
            <ShowBranche
              data={branchItem}
              closeShowBranche={closeShowBranche}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

// userid: 9

// action: branch

// gst: 23AAGCT6933J1Z4
// branch: Apps4all
// pan: AAGCT6933J
// legalname: TROVON INDUSTRIES PRIVATE LIMITED
// tradename: TROVON INDUSTRIES PRIVATE LIMITED
// address: 172/2/4, 306, SHIVAMPURI COLONY, MANAS APARTMENT, INDORE
// state: Madhya Pradesh
// district: Indore
// pincode: 452014
// mobile: 7770842288
// email: navinpatidar954@gmail.com
// id: VeramasaIND
// password: 123456
