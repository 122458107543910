import { Modal, Pagination } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsChatRightText, BsFileEarmarkPdf, BsRepeat } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  orderListAPI,
  orderPDFDownloadAPI,
  reorderAPI,
} from "../../../ApiRouter";
import { useNavigate } from "react-router-dom";
import { RaiseATicket } from "./childComponent/RaiseATicket";
import { FeedbackConponent } from "./childComponent/Feedback";
import moment from "moment";
import { FaStar } from "react-icons/fa";
import { LoginPopUp } from "../../Cards/LoginPopUp";
// import { MyOrderCard } from "../../../Comp/MyOrderCard";
import { PaginationCreated } from "../../../Comp/Pagination";
import useDebounce from "../../../Hook/Debouncing";
import { NoData } from "../../../Comp/NoData";
import { MyOrderCard } from "../../../Comp/Cards";

export const ActiveOrder = () => {
  const [entries, setEntries] = useState(10);
  const [totalActiveOrder, setTotalActiveOrder] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totelCount, setTotalCount] = useState("");
  const [showFeed, setShowFeed] = useState(false);
  const [oid, setOid] = useState("");
  const [reorderSuccess, setReorderSuccess] = useState(false);
  const debouncedSearchItem = useDebounce(searchItem, 1000);
  const { userid } = useSelector((state) => state.data);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [debouncedSearchItem]);

  const closeModel = () => {
    setShowFeed(false);
  };
  const handleSuccessPopup = () => {
    setReorderSuccess(false);
    closeModel();
  };

  const fetchData = async () => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("page", pageNo);
    body.append("limit", entries);
    body.append("type", 1);
    body.append("search", searchItem);
    const response = await axios.post(orderListAPI, body);
    setOrderList(response.data.orders);
    setTotalActiveOrder(response.data.totalcount);
    var count =
      response.data.totalcount !== undefined ? response.data.totalcount : 0;
    var count1 = Math.ceil((count / entries) * 10);
    setTotalCount(count1);
  };

  useEffect(() => {
    fetchData();
  }, [entries, pageNo]);

  const handlePDFDownLoad = async (orderid) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("orderid", orderid);
    const response = await axios.post(orderPDFDownloadAPI, body);
    const urlPath = response.data.path;

    axios({
      url: urlPath,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${userid}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const onChange = (pageNumber) => {
    setPageNo(pageNumber);
  };
  const handleOrderSummery = (orderid) => {
    navigate("/ordersummary", {
      state: { orderid: orderid, userid: userid },
    });
  };

  const handleReorder = async (orderid) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("orderid", orderid);
    const response = await axios.post(reorderAPI, body);
    if (response.data.success === true) {
      setReorderSuccess(true);
    }
  };
  const handleSearch = (e) => {
    setSearchItem(e.target.value);
  };
  return (
    <div className="Active-Order">
      <RaiseATicket />
      <div>
        <h3>My Order</h3>
      </div>

      <PaginationCreated
        setEntries={setEntries}
        setPageNo={setPageNo}
        pageNo={pageNo}
        onChange={onChange}
        totelCount={totelCount}
        searchChange={handleSearch}
        totalActiveOrder={totalActiveOrder}
      >
        {orderList.length > 0 ? (
          <div className="AO-OL-cards">
            {orderList.map((item, i) => (
              <MyOrderCard
                key={i}
                item={item}
                onView={() => handleOrderSummery(item.orderid)}
                onRepeat={() => handleReorder(item.orderid)}
                onDownload={() => handlePDFDownLoad(item.orderList)}
                onRating={() => {
                  setShowFeed(true);
                  setOid(item.orderid);
                }}
              />
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </PaginationCreated>
      {showFeed && <FeedbackConponent closeModel={closeModel} id={oid} />}
      {reorderSuccess == true && (
        <Modal
          centered={true}
          className="centernow"
          footer={null}
          header={null}
          closeIcon={false}
          width="30%"
          style={{
            translate: "transform(-50% -50%)",
          }}
          closable="false"
          open={reorderSuccess}
          okButtonProps="false"
          // onCancel={() => setIsModalOpen(false)}
        >
          <LoginPopUp
            handleSuccessPopup={handleSuccessPopup}
            Message={"Product(s) added to cart "}
          />
        </Modal>
      )}
    </div>
  );
};
