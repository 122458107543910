import prodItemImg1 from "../Assets/Images/product/Todays_Deal.png";
import prodItemImg2 from "../Assets/Images/product/GW-Cat-hpc.png";
import prodItemImg3 from "../Assets/Images/product/GW-Cat-Bharat.jpeg";
import prodItemImg4 from "../Assets/Images/product/GW-Cat-Indane.png";
import prodItemImg5 from "../Assets/Images/product/GW-Cat-SUPERGAS.png";
import prodItemImg6 from "../Assets/Images/product/GW-Cat-Total.png";
import prodItemImg7 from "../Assets/Images/product/GW-Cat-GoGas.png";
import prodItemImg8 from "../Assets/Images/product/GW-Cat-O2.png";
import prodItemImg9 from "../Assets/Images/product//GW-Cat-N2.png";
import prodItemImg10 from "../Assets/Images/product/GW-Cat-2.png";
import prodItemImg11 from "../Assets/Images/product/GW-Cat-3.png";
import prodItemImg12 from "../Assets/Images/product/GW-Cat-4.png";

export const HomeData = [
  {
    lable: "Today Deals",
    img: prodItemImg1,
    click: "",
  },
  {
    lable: "HPCL",
    img: prodItemImg2,
    click: "HPCL",
  },
  {
    lable: "BPCL",
    img: prodItemImg3,
    click: "BPCL",
  },
  {
    lable: "IOCL",
    img: prodItemImg4,
    click: "IOCL",
  },
  {
    lable: "Super Gas",
    img: prodItemImg5,
    click: "SUPERGAS",
  },
  {
    lable: "Total Gas",
    img: prodItemImg6,
    click: "TOTALGAS",
  },
  {
    lable: "Gogas",
    img: prodItemImg7,
    click: "Gogas",
  },
  {
    lable: "Oxygen",
    img: prodItemImg8,
    click: "",
  },
  {
    lable: "Nitrogen",
    img: prodItemImg9,
    click: "",
  },
  {
    lable: "Equipment",
    img: prodItemImg10,
    click: "",
  },
  {
    lable: "Spares",
    img: prodItemImg11,
    click: "",
  },
  {
    lable: "Services",
    img: prodItemImg12,
    click: "",
  },
];
