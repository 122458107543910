import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrEdit, GrLocation } from "react-icons/gr";
import { Switch } from "antd";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  addressListAPI,
  completeListAPI,
  deleteAddressAPI,
} from "../../../ApiRouter";
import MyMap from "../../03Profile/smallComponent/childComponent/myMap";
import { Modal } from "antd";
import EditAddress from "../../03Profile/smallComponent/childComponent/EditAddress";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AddressCard } from "../../../Comp/Cards";

export const ShippingAddress = () => {
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [newState, setNewState] = useState("new");
  const [addressList, setAddressList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [AddressId, setAddressId] = useState("");
  const { userid } = useSelector((state) => state.data);

  const [obj, setObj] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    District: "",
    PinCode: "",
  });
  const [objErr, setErrObj] = useState({
    InchargeName: "",
    InchargeMobile: "",
    Address: "",
    Latlong: "",
    Landmark: "",
    State: "",
    District: "",
    PinCode: "",
  });
  const [modal1Open, setModal1Open] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", "");
      const response = await axios.post(completeListAPI, body);
      setStateList(response.data.data.reponselist.states);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("stateid", obj.State);
      const response = await axios.post(completeListAPI, body);
      setDistrictList(response.data.data.reponselist.districts);
    };
    fetchData();
  }, [obj.State]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(addressListAPI, body);
      setAddressList(response.data.data);
    };
    fetchData();
  }, []);

  const handleDeleteAdd = async (AddId) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("addressid", AddId);
    const response = await axios.post(deleteAddressAPI, body);
    if (response.data.success === true) {
      alert("address Deleted");
    }
  };
  const validateMobile = (value) => {
    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(value)) {
      return "Please enter a valid 10-digit mobile number";
    }
    return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <div className="ShipAdd">
      <div className="Ship">
        <div className="Ship-01st">Shipping Address</div>
        <div className="Ship-02nd">
          <button
            className="Ship-02nd-btn"
            style={{ backgroundColor: showAddAddress ? "gray" : "" }}
            onClick={() => setShowAddAddress(!showAddAddress)}
          >
            {showAddAddress ? "Cancel" : "Add Shipping Address"}
          </button>
        </div>
        {showAddAddress ? (
          <form
            onSubmit={handleSubmit}
            className="Ship-form scroll-in-animation"
          >
            <div className="Ship-form-div">
              <p>Incharge Name </p>
              <input
                type="text"
                onChange={(e) =>
                  setObj({ ...obj, InchargeName: e.target.value })
                }
                className="Ship-input"
              />
            </div>
            <div className="Ship-form-div">
              <p>Incharge Mobile </p>
              <input
                type="text"
                onChange={(e) =>
                  setObj({ ...obj, InchargeMobile: e.target.value })
                }
                className="Ship-input"
              />
            </div>
            <div className="Ship-form-div">
              <p>Address </p>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, Address: e.target.value })}
                className="Ship-input"
              />
            </div>
            <div className="Ship-form-div">
              <p>Landmark </p>
              <input
                type="text"
                onChange={(e) => setObj({ ...obj, Landmark: e.target.value })}
                className="Ship-input"
              />
            </div>
            <div className="Ship-form-div">
              <p>State </p>
              {/* <input type="text" className="Ship-input" /> */}
              <select
                className="Ship-input"
                onChange={(e) => setObj({ ...obj, State: e.target.value })}
              >
                <option hidden>Select State</option>
                {stateList.length > 0 &&
                  stateList.map((item, i) => (
                    <option key={i} value={item.code}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className="Ship-form-div"
              onChange={(e) => setObj({ ...obj, District: e.target.value })}
            >
              <p>District </p>
              <select className="Ship-input">
                <option hidden>Select District</option>
                {districtList.length > 0 &&
                  districtList.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="Ship-form-div">
              <p>Pin Code </p>
              <input
                type="text"
                className="Ship-input"
                onChange={(e) => setObj({ ...obj, PinCode: e.target.value })}
              />
            </div>
            <div className="Ship-form-div">
              <p className="visiblity-none">np </p>
              <button
                className="Ship-map-btn"
                onClick={() => setShowMap(!showMap)}
              >
                <HiOutlineLocationMarker style={{ fontSize: "20px" }} />
                <p>Point on Map </p>
              </button>
            </div>
            {showMap && (
              <div className="Ship-map-div-2">
                <MyMap />
              </div>
            )}
            <div className="Ship-update-div">
              <button className="Ship-update-btn">Submit</button>
            </div>
          </form>
        ) : (
          <>
            {addressList.length > 0 ? (
              <div
                 className="grid-container"
              >
                {addressList.map((item, i) => (
                  <AddressCard
                  key={i}
                    onEdit={() => {
                      setModal1Open(true);
                      setAddressId(item);
                    }}
                    onDelete={() => handleDeleteAdd(item.id)}
                    onChange={onChange}
                    item={item}
                  />
                ))}
              </div>
            ) : (
              "No Address Saved"
            )}
          </>
        )}
        {!showAddAddress && (
          <div className="Ship-02nd">
            <button
              className="Ship-02nd-btn"
              style={{ backgroundColor: "#198754" }}
              onClick={() => navigate("/purchageorder")}
            >
              <p>Continue</p> <BsArrowRight className=" Ship-arr" />
            </button>
          </div>
        )}
        {modal1Open && (
          <Modal
            footer={null}
            title="Address"
            width="60%"
            style={{
              top: 20,
            }}
            open={modal1Open}
            okButtonProps="false"
            onOk={() => setModal1Open(false)}
            onCancel={() => setModal1Open(false)}
          >
            <EditAddress data={AddressId} />
          </Modal>
        )}
      </div>
    </div>
  );
};
