import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ProductList, cartAddAPI, cartListAPI } from "../../ApiRouter";
import "./08ItemDetails.css";
import { FaRupeeSign } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { Modal } from "antd";
import { CompareProduct } from "./childComponent/Compare";
import { useDispatch, useSelector } from "react-redux";
import { setShowLogin } from "../../Redux/actions";
import store from "../../Redux/store";
import { get_show_login } from "../../Redux/DataSlice";

export const ItemDetails = () => {
  const [item, setItem] = useState([]);
  const navigation = useNavigate();
  const [state, setState] = useState(false);
  const location = useLocation();
  const type = location.state;
  const [cartData, setCartData] = useState([]);
    const {userid} = useSelector((state) => state.data);

  const [showCompare, setShowCompare] = useState(false);
  const [fullProductList, setFullProductList] = useState([]);

  const dispatch = useDispatch()

  const handleitemFullDetails = (itemid) => {
    navigation("/itemFullDetails", { state: itemid });
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(cartListAPI, body);
      setCartData(response.data.data);
      // setInputValue(response.data.data.count);
    };
    fetchData();
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(ProductList);
      const dataList = response.data.data;
      setFullProductList(dataList);
      if (type == "All") {
        setItem(dataList);
      } else if (type === "PMs") {
        const filteredItems = dataList.filter((item) => {
          return (
            item.type === "SUPERGAS" ||
            item.type === "TOTALGAS" ||
            item.type === "Gogas"
          );
        });
        setTimeout(() => {
          setItem(filteredItems);
        }, 1000);
      } else {
        const filteredItems = dataList.filter((item) => item.type === type);
        setTimeout(() => {
          setItem(filteredItems);
        }, 1000);
      }
    };
    fetchData();
  }, [type]);

  const handleInputValue = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "+") {
      body.append("count", count + 1);
      body.append("returncount", count + 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "-") {
      body.append("count", count - 1);
      body.append("returncount", count - 1);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    } else if (sign === "inputchange") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
    }
  };

  const handleOutFocus = async (
    sign,
    productid,
    count,
    returnclinder,
    action = 1
  ) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("productid", productid);
    body.append("action", action);
    if (sign === "remove") {
      body.append("count", count);
      body.append("returncount", returnclinder);
      const response = await axios.post(cartAddAPI, body);
      setState(!state);
    }
  };

  const HandleAddToCart = async (productId) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", 1);
    body.append("productid", productId);
    body.append("count", 1);
    body.append("returncount", 1);

    const response = await axios.post(cartAddAPI, body);
    if (response.data.success == true) {
      setState(!state);
    }
  };

  return (
    <div>
      <div className="ID">
        {item.length > 0 ? (
          <>
            {item.map((item,i) => (
              <div className="ID-card" key={i}>
                <div className="ID-card-img">
                  <img
                    src={item.url}
                    alt=""
                    className="ID-img"
                    onClick={() => handleitemFullDetails(item.uuid)}
                  />
                  {item.url.includes("cylinder.png") ? (
                    <p className="ID-card-capacity">{item.capacity} Kg</p>
                  ) : (
                    ""
                  )}
                </div>
                {item.discountprice !== "" ? (
                  <div className="ID-Card-discount">
                    <p>
                      <FaRupeeSign />
                      {item.discountprice}
                    </p>
                  </div>
                ) : (
                  <p></p>
                )}
                <div className="ID-Card-type">{item.type}</div>
                <div className="ID-Card-desc">{item.description}</div>
                <div className="ID-Card-price">
                  <FaRupeeSign className="ID-price-symbol" />
                  {item.price}
                </div>
                {userid && cartData.length !== 0 ? (
                  <>
                    {cartData.find(
                      (newItem) => newItem.productid === item.uuid
                    ) ? (
                      <div className="cart-div-icon">
                        <div className="icon-div">
                          <p
                            className="cart-icons"
                            onClick={() => {
                              cartData.find(
                                (newItem) => newItem.productid === item.uuid
                              ).count == 1
                                ? handleOutFocus(
                                    "remove",
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).productid,
                                    0,
                                    0,
                                    2
                                  )
                                : handleInputValue(
                                    "-",
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).productid,
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).count,
                                    cartData.find(
                                      (newItem) =>
                                        newItem.productid === item.uuid
                                    ).returnclinder,
                                    1
                                  );
                            }}
                          >
                            {"-"}
                          </p>
                        </div>
                        <input
                          type="text"
                          className="cart-input"
                          value={Number(
                            cartData.find(
                              (newItem) => newItem.productid === item.uuid
                            ).count
                          )}
                          onChange={(e) => {
                            e.target.value == 0 || e.target.value.length === 0
                              ? handleOutFocus("", item.productid, 0, 0, 2)
                              : handleInputValue(
                                  "inputchange",
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).productid,
                                  Number(e.target.value),
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).returnclinder,
                                  1
                                );

                            setCartData((pre) =>
                              pre.map((val, ind) =>
                                val.id ===
                                cartData.find(
                                  (newItem) => newItem.productid === item.uuid
                                ).id
                                  ? {
                                      ...val,
                                      count: Number(e.target.value),
                                    }
                                  : { ...val }
                              )
                            );
                          }}
                          onBlur={(e) => {
                            e.target.value == 0 || e.target.value.length === 0
                              ? handleOutFocus(
                                  "remove",
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).productid,
                                  0,
                                  0,
                                  2
                                )
                              : handleInputValue(
                                  "inputchange",
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).productid,
                                  Number(e.target.value),
                                  cartData.find(
                                    (newItem) => newItem.productid === item.uuid
                                  ).returnclinder,
                                  1
                                );
                          }}
                        />
                        <div className="icon-div">
                          <p
                            className="cart-icons"
                            onClick={() =>
                              handleInputValue(
                                "+",
                                cartData.find(
                                  (newItem) => newItem.productid === item.uuid
                                ).productid,
                                cartData.find(
                                  (newItem) => newItem.productid === item.uuid
                                ).count,
                                cartData.find(
                                  (newItem) => newItem.productid === item.uuid
                                ).returnclinder,
                                1
                              )
                            }
                          >
                            {"+"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="ID-card-btn"
                        onClick={() => HandleAddToCart(item.uuid)}
                      >
                        Add to Cart
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="ID-card-btn"
                    onClick={() => {
                      store.dispatch(get_show_login(true));                    }}
                  >
                    Add to Cart
                  </button>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="spinner-div">
            <Oval
              height={60}
              width={60}
              color="white"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#169e49"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          </div>
        )}
        <button className="compare-btn" onClick={() => setShowCompare(true)}>
          Compare
        </button>
      </div>
      {showCompare == true && (
        <Modal
          title="Compare Cylinder"
          footer={null}
          className="CompProd-modal"
          style={{
            top: 20,
          }}
          zIndex={"10"}
          okButtonProps="false"
          onOk={() => setShowCompare(false)}
          onCancel={() => setShowCompare(false)}
          open={showCompare}
        >
          <CompareProduct data={fullProductList} />
        </Modal>
      )}
    </div>
  );
};
