import { combineReducers, createStore } from "redux";
import {
  loginDataReducer,
  mobileNoReducer,
  orderDatareducer,
  setShowLogin,
} from "./reducers.js";
import DataSlice from "./DataSlice.js";
import { configureStore } from "@reduxjs/toolkit";

// const rootReducer = combineReducers({
//     mobileNo: mobileNoReducer,
//     loginData: loginDataReducer,
//     orderData: orderDatareducer,
//     showLogin: setShowLogin
// });

const store = configureStore({
  reducer: {
    data: DataSlice,
  },
});
export default store;
