import React, { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { Modal, Tooltip } from "antd";
import axios from "axios";
import { referralGenerateAPI } from "../../../ApiRouter";
import { LoginPopUp } from "../../Cards/LoginPopUp";
import { useSelector } from "react-redux";

export const Refferal = () => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [reffaralSuccess, setReffaralSuccess] = useState(false);

    const {userid} = useSelector((state) => state.data);


  const handleSuccessPopup = () => {
    setReffaralSuccess(false);
  };

  const handleGenerateToken = async (e) => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("ingress", 4);
    const response = await axios.post(referralGenerateAPI, body);
    console.log(response);
    if (response.data.success === true) {
      setCode(response.data.referralcode);
      setMessage(response.data.message);
      setReffaralSuccess(true);
    } else {
      console.log("np");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
  };
  return (
    <div className="Reff">
      <div className="Reff-1st">
        <div className="Reff-1st-01">Refer to</div>
        <button
          className="Reff-1st-btn"
          onClick={() => {
            handleGenerateToken();
          }}
        >
          Generate Referral Code
        </button>
        {code !== "" && (
          <div className="Reff-1st-code">
            <p>Your referal code is: {code} </p>{" "}
            <p className="copy">
              {/* {copyHover && <p>Copy to ClipBoard</p>} */}
              <Tooltip title="Copy to ClipBoard">
                <MdContentCopy
                  // onMouseEnter={() => setCopyHover(true)}
                  // onMouseLeave={() => setCopyHover(false)}
                  onClick={copyToClipboard}
                  className="Reff-copy"
                />
              </Tooltip>
            </p>
          </div>
        )}
        {reffaralSuccess == true && (
          <Modal
            centered={true}
            className="center"
            footer={null}
            header={null}
            closeIcon={false}
            closable="false"
            open={reffaralSuccess}
            okButtonProps="false"
            // onCancel={() => setIsModalOpen(false)}
          >
            <LoginPopUp
              handleSuccessPopup={handleSuccessPopup}
              Message={message}
            />
          </Modal>
        )}
      </div>
      <div className="Reff-1st">
        You are Refered By : VERAMASA IT AND EDUCATION CONSULTANCY PRIVATE
        LIMITED
      </div>
    </div>
  );
};
