import React from "react";

export const ShowBranche = ({ data, closeShowBranche }) => {
  console.log(data);

  return (
    <div className="SB-main">
      <div className="SB-items">
        <div className="SB-item">
          <div className="SB-key">User ID</div>
          <div className="SB-value">
            <span>:</span>
            {data.userid}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Email</div>
          <div className="SB-value">
            <span>:</span>
            {data.email}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Mobile Number</div>
          <div className="SB-value">
            <span>:</span>
            {data.mobile}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">GST</div>
          <div className="SB-value">
            <span>:</span>
            {data.gst}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">PAN</div>
          <div className="SB-value">
            <span>:</span>
            {data.pan}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Legal Name</div>
          <div className="SB-value">
            <span>:</span>
            {data.legalname}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Trade Name</div>
          <div className="SB-value">
            <span>:</span>
            {data.tradename}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Address</div>
          <div className="SB-value">
            <span>:</span>
            {data.address}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">District</div>
          <div className="SB-value">
            <span>:</span>
            {data.district}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">State</div>
          <div className="SB-value">
            <span>:</span>
            {data.state}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Pin Code</div>
          <div className="SB-value">
            <span>:</span>
            {data.pincode}
          </div>
        </div>
        <div className="SB-item">
          <div className="SB-key">Branch Code</div>
          <div className="SB-value">
            <span>:</span>
            {data.branch}
          </div>
        </div>
      </div>
    </div>
  );
};
