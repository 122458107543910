import React from "react";
import Carousel from "./smallComponent/Carousel";
import Product from "../06Product/Product";

export const Home = () => {
  return (
    <div className="home">
      <Carousel />
      <Product />
    </div>
  );
};
