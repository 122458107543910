import "../../App.css";
import { motion } from "framer-motion";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export default function CloseErrorAnimation({ className }) {
  // const circleRadius = 80;
  // const centerX = 300;
  // const centerY = 300;
  // const lineLength = 40;

  // const circleRadius = 200;
  // const centerX = 300;
  // const centerY = 300;
  // const lineLength = 80;

  const circleRadius = 250;
  const centerX = 300;
  const centerY = 300;
  const lineLength = 100;

  return (
    <motion.svg
      style={{ height: "100px", width: "300px", padding: "0", margin: "0" }}
      viewBox="0 0 600 600"
      initial="hidden"
      animate="visible"
      className={className}
    >
      <circle
        cx={centerX}
        cy={centerY}
        r={circleRadius}
        style={{ strokeWidth: "15px" }}
        stroke="red"
        strokeWidth="1"
        fill="transparent"
      />
      <motion.line
        x1={centerX - lineLength}
        y1={centerY - lineLength}
        x2={centerX + lineLength}
        y2={centerY + lineLength}
        style={{ strokeWidth: "15px" }}
        stroke="red"
        strokeWidth="10"
        custom={0}
        variants={draw}
      />
      <motion.line
        x1={centerX - lineLength}
        y1={centerY + lineLength}
        x2={centerX + lineLength}
        y2={centerY - lineLength}
        style={{ strokeWidth: "15px" }}
        stroke="red"
        strokeWidth="10"
        custom={0.1}
        variants={draw}
      />
    </motion.svg>
  );
}
