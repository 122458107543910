import React from 'react'
import { Navbar } from '../Components/01header/Navbar'
import { Footer } from '../Components/05Home/smallComponent/Footer'
import { Outlet } from 'react-router-dom'

export const Layout = () => {
  return (
    <div className="App">
      <Navbar />
      <div style={{ paddingTop: "60px", minHeight: "74vh" }}>
        <Outlet/>
      </div>
      <Footer />
    </div>
  )
}
