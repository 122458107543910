import { Pagination } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  orderListAPI,
  productListAPI,
  accountReportsAPI,
} from "../../../ApiRouter";
import { useNavigate } from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import { DateRange } from "./childComponent/DateRangePicker";
import moment from "moment";
import useDebounce from "../../../Hook/Debouncing";
import { PaginationCreated } from "../../../Comp/Pagination";
import { Select } from "../../../Comp/Input/Input";
import { NoData } from "../../../Comp/NoData";

export const Account = () => {
  const [orderList, setOrderList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [entries, setEntries] = useState(10);
  const [searchItem, setSearchItem] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isZero, seIsZero] = useState(true);
  const [isDatePicker, setIsDatePicker] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [serviceType, setServiceType] = useState("all");
  const [productType, setProductType] = useState("all");
  const [paymentType, setPaymentType] = useState("all");
  const [dateRange, setDateRange] = useState([]);
  const [sDate, setSDate] = useState(moment(new Date()).format("ll"));
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 30);
  const [eDate, setEDate] = useState(moment(todayDate).format("ll"));
  const [pageCount, setPageCount] = useState(1);

  const debouncedSearchItem = useDebounce(searchItem, 1000);

  const { userid } = useSelector((state) => state.data);
  const navigate = useNavigate();
  const handleSetRange = (range) => setDateRange(range);
  const closeModel = () => setIsDatePicker(false);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchItem]);

  useEffect(() => {
    fetchData();
  }, [eDate, sDate, paymentType, productType, serviceType]);

  const fetchData = async () => {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", "bydate");
    body.append("sdate", moment(eDate).format("YYYY-MM-DD"));
    body.append("edate", moment(sDate).format("YYYY-MM-DD"));
    body.append("stype", serviceType);
    body.append("ctype", productType);
    body.append("ptype", paymentType);
    const response = await axios.post(accountReportsAPI, body);

    if (searchItem.length === 0) {
      setOrderList(response.data.data);
      var count =
        response.data.data !== undefined ? response.data.data.length : 0;
      setTotalCount(count);
    } else if (searchItem.length !== 0) {
      const data1 = response.data.data;
      const filtered = data1.filter((v, i) => {
        return (
          v.orderid.includes(searchItem) ||
          v.paymentmode.toLowerCase().includes(searchItem.toLowerCase())
        );
      });
      setOrderList(filtered);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      var count1 = Math.ceil((totalCount / entries) * 10);
      setPageCount(count1);
    };
    fetchData();
  }, [totalCount, entries]);

  useEffect(() => {
    const fetchData = () => {
      if (dateRange.length > 0) {
        setSDate(moment(dateRange[0].endDate).format("YYYY-MM-DD"));
        setEDate(moment(dateRange[0].startDate).format("YYYY-MM-DD"));
      }
    };
    fetchData();
  }, [dateRange]);

  useEffect(() => {
    const fetchData = async () => {
      const body = new FormData();
      body.append("userid", userid);
      const response = await axios.post(productListAPI, body);
      const data = response.data.data;
      setProductList(data);
    };
    fetchData();
  }, []);

  const onChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchItem(e.target.value);
  };

  const handleProductList = (str) => {
    const newData = str.split(",");

    var k = [];
    for (var i = 0; i < newData.length; i++) {
      var j = newData[i].split("~~~");
      k.push(j);
    }
    var data = k.map((item) => {
      return `${item[0]} (${item[1]} - ₹  ${item[2]})`;
    });

    return data;
  };

  return (
    <>
      <div className="Active-Order">
        <div className="Acc">
          <div className="Acc-form-main-1st">
            <Select
            lable={"Service Type:"}
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}>
            <option className="select-one" value="all">
                  All
                </option>
                <option className="select-one" value="LPG">
                  LPG
                </option>
                <option className="select-one" value="Nitrogen/Oxigen/Others">
                  Nitrogen/Oxigen/Others
                </option>
            </Select>
            <Select
            lable={" Product Type:"}
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            >
             <option className="select-one" value="all">
                  All
                </option>
                {productList.length > 0 && (
                  <>
                    {productList.map((item, i) => (
                      <option className="select-one" key={i} value={item.uuid}>
                        {item.description}
                      </option>
                    ))}
                  </>
                )}
            </Select>
            <Select
            lable={"Payment Type:"}
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            >
              <option className="select-one" value="all">
                  All
                </option>
                <option className="select-one" value="PO">
                  PO
                </option>
                <option className="select-one" value="COD">
                  COD
                </option>
                <option className="select-one" value=" Credit">
                  Credit
                </option>
                <option className="select-one" value="Net Banking">
                  Net Banking
                </option>
                <option className="select-one" value="UPI">
                  UPI
                </option>
            </Select>
           
            <div className="Add-form-div">
              <label className="Acc-form-lable">
                Filter by Date: <i style={{ color: "red" }}>*</i>
              </label>
              <div

                className="Acc-form-input select-one Add-input"
                onClick={() => setIsDatePicker(true)}
              >
                <SlCalender style={{ paddingRight: "5px" }} />

                {dateRange.length > 0 ? (
                  <>
                    {moment(dateRange[0].startDate).format("ll")} to{" "}
                    {moment(dateRange[0].endDate).format("ll")}
                  </>
                ) : (
                  `${moment(new Date()).format("ll")} to ${moment(
                    todayDate
                  ).format("ll")}`
                )}
              </div>
              {isDatePicker && (
                <DateRange
                  closeModel={closeModel}
                  handleSetRange={handleSetRange}
                />
              )}
            </div>
          </div>
        </div>

        <div>
          <h3>Total Order</h3>
        </div>

        <PaginationCreated
          setEntries={setEntries}
          setPageNo={setPageNo}
          pageNo={pageNo}
          onChange={onChange}
          totelCount={pageCount}
          searchChange={handleSearch}
          totalActiveOrder={pageCount}
        >
          {
            orderList?.length > 0 ?
          <div className="OH">
            <table className="OH-table">
              <tr className="OH-tr-th">
                <th className="OH-head-row OH-head-row-td1">Order ID</th>
                <th className="OH-head-row OH-head-row-td1"> Payment Mode</th>
                <th className="OH-head-row OH-head-row-td1"> Order Status</th>
                <th className="OH-head-row OH-head-row-td1"> Product</th>
                <th className="OH-head-row OH-head-row-td1"> Amount</th>
                <th className="OH-head-row OH-head-row-td1">Date</th>
              </tr>
              {orderList
                ?.slice(entries * pageNo - entries, entries * pageNo)
                .map((item, i) => (
                  <tr className="OH-tr-th" key={i}>
                    <td className="OH-head-row2 OH-head-row-td1">
                      {item.orderid}
                    </td>
                    <td className="OH-head-row2 OH-head-row-td1">
                      {item.paymentmode}
                    </td>
                    <td className="OH-head-row2 OH-head-row-td1">
                      {item.orderstatustext}
                    </td>
                    <td className="OH-head-row2 OH-head-row-td1">
                      {handleProductList(item.productslist)}
                    </td>
                    <td className="OH-head-row-td1 OH-head-row2">
                      ₹ {item.totalamount}
                    </td>
                    <td className="OH-head-row-td1 OH-head-row2">
                      {moment(item.datetime).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
           :<NoData/>
          }
        </PaginationCreated>
      </div>
    </>
  );
};
