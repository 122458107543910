export const ContactUsAPI = "https://gaswala.vensframe.com/api/customer/contactus";
export const verifyUser = "https://gaswala.vensframe.com/api/customer/verify_user";
export const loginUser = "https://gaswala.vensframe.com/api/customer/login";
export const ProductList = "https://gaswala.vensframe.com/api/customer/productlist";
export const itemDescription = "https://gaswala.vensframe.com/api/customer/listbyid";
export const serviceContact = "https://gaswala.vensframe.com/api/customer/services";
export const orderListAPI = "https://gaswala.vensframe.com/api/customer/orderslist";
export const orderPDFDownloadAPI = "https://gaswala.vensframe.com/api/customer/TCPDF/pdfgenerate"
export const orderByIdAPI = "https://gaswala.vensframe.com/api/customer/orderbyid";
export const reorderAPI = "https://gaswala.vensframe.com/api/customer/reorder";
export const productListAPI = "https://gaswala.vensframe.com/api/customer/productlist";
export const creditDetailsAPI = "https://gaswala.vensframe.com/api/customer/credit-details";
export const quotationlistAPI = "https://gaswala.vensframe.com/api/quotationlist";
export const purchaseOrderListAPI = "https://gaswala.vensframe.com/api/po-list.php";
export const ticketListAPI = "https://gaswala.vensframe.com/api/customer/ticket-list";
export const feedbackUpdateAPI = "https://gaswala.vensframe.com/api/customer/feedbackupdate";
export const accountReportsAPI = "https://gaswala.vensframe.com/api/customer/reports";
export const profileAPI = "https://gaswala.vensframe.com/api/customer/profile";
export const updateProfileAPI = "https://gaswala.vensframe.com/api/customer/updateprofile";
export const addressListAPI = "https://gaswala.vensframe.com/api/customer/addresslist";
export const deleteAddressAPI = "https://gaswala.vensframe.com/api/customer/deleteaddress";
export const completeListAPI = "https://gaswala.vensframe.com/api/customer/completelist";
export const branchListAPI = "https://gaswala.vensframe.com/api/customer/branchlist";
export const createBranchAPI = "https://gaswala.vensframe.com/api/customer/createbranch"
export const deleteBranchAPI = "https://gaswala.vensframe.com/api/customer/deletebranch"
export const changePasswordAPI = "https://gaswala.vensframe.com/api/customer/changepassword";
export const cartListAPI = "https://gaswala.vensframe.com/api/customer/cartlist";
export const cartAddAPI = "https://gaswala.vensframe.com/api/customer/tempcart";
export const createQuoteAPI = "https://gaswala.vensframe.com/api/create-quotation";
export const generatePurchaseOrderAPI = "https://gaswala.vensframe.com/api/generate-po";
export const createAddressAPI = "https://gaswala.vensframe.com/api/customer/createaddress";
export const referralGenerateAPI = "https://gaswala.vensframe.com/api/referral";
export const updateShippingAPI = "https://gaswala.vensframe.com/api/customer/updateshipping"
export const GSTNumberValideAPI = "https://gaswala.vensframe.com/api/customer/gstfetch"
export const ProductSearch = "https://gaswala.vensframe.com/api/customer/search"
export const paymentListAPI = "https://gaswala.vensframe.com/api/customer/paymentlist"
export const createOrderAPI = "https://gaswala.vensframe.com/api/customer/createorder"