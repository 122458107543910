import React from "react";
import CloseErrorAnimation from "./CloseErrorAnimation";

export const UnSuccessError = ({ handleUnSuccessPopup, Message }) => {
  return (
    <div>
      <div className="log-pop-ok-div">
        <CloseErrorAnimation className="np" />
      </div>
      <div className="log-pop-ok-div" style={{ fontSize: "20px" }}>
        {Message}
      </div>
      <div className="log-pop-ok-div">
        <button
          style={{ backgroundColor: "#7066e0", width: "auto" }}
          onClick={handleUnSuccessPopup}
          className="log-pop-ok"
        >
          Ok
        </button>
      </div>
    </div>
  );
};
