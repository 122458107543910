import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../CSS/02Carousel.css";
import "swiper/css/navigation";

import CaroImg01 from "../../../Assets/Images/product/Carousel/GW-Carousel-1st.jpeg";
import CaroImg02 from "../../../Assets/Images/product/Carousel/GW-Carousel-2nd.png";
import CaroImg03 from "../../../Assets/Images/product/Carousel/GW-Carousel-3rd.jpeg";
import CaroImg04 from "../../../Assets/Images/product/Carousel/GW-Carousel-4th.jpeg";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function Carousel() {
  return (
    <div className="slider-main">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper mySwiper2"
      >
        <SwiperSlide className="slider-content">
          <img src={CaroImg01} alt="" className="img-slider" />
        </SwiperSlide>

        <SwiperSlide className="slider-content">
          <img src={CaroImg02} alt="" className="img-slider" />
        </SwiperSlide>
        <SwiperSlide className="slider-content">
          <img src={CaroImg03} alt="" className="img-slider" />
        </SwiperSlide>
        <SwiperSlide className="slider-content">
          <img src={CaroImg04} alt="" className="img-slider" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
