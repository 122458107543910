import React, { useState } from "react";
import "./11Dashboard.css";
import { ActiveOrder } from "./smallComponent/ActiveOrder";
import { OrderHistory } from "./smallComponent/OrderHistory";
import { Account } from "./smallComponent/Account";
import { Credit } from "./smallComponent/Credit";
import { Quotation } from "./smallComponent/Quotation";
import { PurchaseOrder } from "./smallComponent/PurchaseOrder";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const [itemLink, setItemLink] = useState("ActiveOrder");
  const navigate = useNavigate()
  const location = useLocation()
  const {pathname} = location;
  // const {pathName}
  return (
    <>
      <div className="DB">
        <div className="DB-1st">
          <ul className="DB-1st-ul">
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/ActiveOrder" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/ActiveOrder")}
            >
              <p>Active Orders</p>
              <p>{">"}</p>
            </li>
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/OrderHistory" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/OrderHistory")}
            >
              <p>Orders History</p>
              <p>{">"}</p>
            </li>
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/Account" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/Account")}
            >
              <p>Account</p>
              <p>{">"}</p>
            </li>
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/Credit" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/Credit")}
            >
              <p>Credit</p>
              <p>{">"}</p>
            </li>
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/Quotation" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/Quotation")}
            >
              <p>Quotation</p>
              <p>{">"}</p>
            </li>
            <li
              className={`DB-1st-li ${
                pathname === "/dashboard/PurchaseOrder" ? "item-link" : ""
              }`}
              onClick={() => navigate("/dashboard/PurchaseOrder")}
            >
              <p>Purchase Order</p>
              <p>{">"}</p>
            </li>
          </ul>
        </div>
        <div className="DB-2nd">
          <Outlet/>
        </div>
      </div>
    </>
  );
};