export const MOBILE_NO = "MOBILE_NO";
export const LOGIN_DATA = "LOGIN_DATA";
export const ORDER_DATA = "ORDER_DATA";
export const SHOW_LOGIN = "SHOW_LOGIN";

export const setMobileNo = (mobile) => {
  return {
    type: MOBILE_NO,
    payload: mobile,
  };
};

export const setLoginData = (data) => {
  return {
    type: LOGIN_DATA,
    payload: data,
  };
};

export const setOrderData = (data) => {
  return {
    type: ORDER_DATA,
    payload: data,
  };
};
export const setShowLogin = (data) => {
  console.log(data);
  return {
    type: SHOW_LOGIN,
    payload: data,
  };
};