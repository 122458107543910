import React, { useEffect, useState } from "react";
import "../CSS/05Login.css";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { BiShow, BiSolidHide } from "react-icons/bi";
import axios from "axios";
import { verifyUser, loginUser } from "../../../ApiRouter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginData } from "../../../Redux/actions";
import { Oval } from "react-loader-spinner";
// import { LoginPopUp } from '..';
import { LoginPopUp } from "../../Cards/LoginPopUp";
import { Modal } from "antd";
import { UnSuccessError } from "../../Cards/UnSuccessError";
import store from "../../../Redux/store";
import { get_login_data, get_userid } from "../../../Redux/DataSlice";

export const Login = ({ closeModel }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpNumber, setOTPNumber] = useState("");
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [mobileInvalid, setMobileInvalid] = useState("");
  const [MoNoResponce, setMoNoResponce] = useState(false);
  const [useridResponce, setUserIdResponce] = useState(false);
  const [showpassword, setShowPassword] = useState(true);
  const [verifyUserResponse, setVerifyUserResponse] = useState(false);
  const [passAndOtpInvalid, setPassAndOtpInvalid] = useState("");
  const [loader, setLoader] = useState(false);
  const [ids, setIds] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginfalse, setLoginFalse] = useState(false);

  const handleSuccessPopup = () => {
    setLoginSuccess(false);
    closeModel();
  };
  const handleUnSuccessPopup = () => {
    setLoginFalse(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChangeOTP = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setOTPNumber(inputValue);
    }
    setPassAndOtpInvalid("");
  };

  const handleInputChangeMobile = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]+$/.test(inputValue)) {
      if (/^\d{0,10}$/.test(inputValue)) {
        setMobileNumber(inputValue);
      }
    } else {
      setMobileNumber("");
      setUserId(inputValue);
      setMobileInvalid("");
    }
    setMoNoResponce(false);
    setUserIdResponce(false);
    setPassword("");
    setOTPNumber("");
    setVerifyUserResponse(false);
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "scroll");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (verifyUserResponse === false) {
      if (mobileNumber.length === 10 || userid.length > 3) {
        if (mobileNumber.length === 10) {
          const body = new FormData();
          body.append("username", mobileNumber);
          try {
            const response = await axios.post(verifyUser, body);
            if (response.data.success === false) {
              setMoNoResponce(false);
              setMobileInvalid(response.data.message);
              setVerifyUserResponse(false);
            } else {
              setMoNoResponce(true);
              setMobileInvalid("");
              setVerifyUserResponse(true);
              setIds(response.data.data.id);
            }
          } catch (error) {
            console.log(error);
          }
        } else if (userid.length >= 8 && userid.length <= 12) {
          const body = new FormData();
          body.append("username", userid);
          const response = await axios.post(verifyUser, body);
          if (response.data.success === false) {
            setMoNoResponce(false);
            setMobileInvalid(response.data.message);
            setVerifyUserResponse(false);
          } else {
            setUserIdResponce(true);
            setMobileInvalid("");
            setVerifyUserResponse(true);
          }
        } else {
          if (userid.length < 8) {
            setMobileInvalid("Please enter at least 8 characters");
          } else if (userid.length > 12) {
            setMobileInvalid("Please enter no more than 12 characters.");
          }
        }
      } else {
        if (userid.length < 8) {
          setMobileInvalid("Please enter at least 8 characters");
        } else if (userid.length > 12) {
          setMobileInvalid("Please enter no more than 12 characters.");
        }
      }
    } else if (verifyUserResponse === true) {
      if (otpNumber.length === 4) {
        const body = new FormData();
        body.append("username", mobileNumber);
        body.append("userid", ids);
        body.append("otp", otpNumber);
        try {
          const response = await axios.post(loginUser, body);
          console.log(response);
          if (response.data.success === false) {
            alert("error");
            setLoginFalse(true);
          } else {
            setLoginSuccess(true);
            store.dispatch(get_login_data(response.data));
            store.dispatch(get_userid(response.data.userid));
            localStorage.setItem("userid", response.data.userid);
            navigate("/");
          }
        } catch (error) {
          console.log(error);
          setLoginFalse(true);
        }
      } else if (password.length >= 6) {
        const body = new FormData();
        body.append("username", userid);
        body.append("password", password);
        try {
          const response = await axios.post(loginUser, body);
          if (response.data.success === false) {
            setLoginFalse(true);
            setPassAndOtpInvalid(response.data.message);
          } else if (response.data.success === true) {
            setLoginSuccess(true);
            localStorage.setItem("userid", response.data.userid);
            store.dispatch(get_login_data(response.data));
            store.dispatch(get_userid(response.data.userid));        
                navigate("/");
          } else {
            console.log("error");
            setLoginFalse(true);
          }
        } catch (error) {
          setLoginFalse(true);
          console.log(error);
        }
      } else {
        if (password.length > 0 && otpNumber.length === 0) {
          setPassAndOtpInvalid("Password  at least 6 characters");
        } else if (
          password.length === 0 &&
          (otpNumber.length > 4 || otpNumber.length < 4)
        ) {
          setPassAndOtpInvalid("OTP only 4 Numeric Number");
        }
      }
    }
    setLoader(false);
  };

  return (
    <div>
      <div className="wraper"></div>
      <div className="log-content">
        <div className="log-content-head">
          <div className="log-content-head-1st">
            <FaRegUser />
            <div>Login/Register</div>
          </div>
          <div className="log-content-close">
            <AiOutlineClose onClick={closeModel} />
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="log-input">
            <lable className="log-input-lable" style={{ opacity: "0.65" }}>
              Mobile/User ID
            </lable>
            <input
              type="text"
              className="log-input-box"
              placeholder="Enter Your Mobile Number/ User Id"
              onChange={handleInputChangeMobile}
            />
            {mobileInvalid !== "" && (
              <p style={{ color: "red", padding: " .25rem 0", margin: "0" }}>
                {mobileInvalid}
              </p>
            )}
            {MoNoResponce && (
              <>
                <input
                  type="text"
                  className="log-input-box"
                  placeholder="Enter OTP"
                  onChange={handleInputChangeOTP}
                />
                {passAndOtpInvalid !== "" && (
                  <p
                    style={{ color: "red", padding: " .25rem 0", margin: "0" }}
                  >
                    {passAndOtpInvalid}
                  </p>
                )}
              </>
            )}
            {useridResponce && (
              <>
                <input
                  type={showpassword ? "password" : "text"}
                  className="log-input-box"
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPassAndOtpInvalid("");
                  }}
                />
                <div className="log-passwod">
                  {showpassword ? (
                    <BiShow
                      className="log-eye"
                      onClick={(e) => setShowPassword(!showpassword)}
                    />
                  ) : (
                    <BiSolidHide
                      className="log-eye"
                      onClick={(e) => setShowPassword(!showpassword)}
                    />
                  )}
                </div>
                {passAndOtpInvalid !== "" && (
                  <p
                    style={{ color: "red", padding: " .25rem 0", margin: "0" }}
                  >
                    {passAndOtpInvalid}
                  </p>
                )}
              </>
            )}
            <div className="log-btn-div">
              {loader ? (
                <div className="log-new">
                  <Oval
                    height={30}
                    width={30}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass="spinner"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="white"
                    strokeWidth={6}
                    strokeWidthSecondary={6}
                  >
                    <BsArrowRight className="log-btn" />
                  </Oval>
                </div>
              ) : (
                <button className="log-btn-btn" onClick={handleSubmit}>
                  <BsArrowRight className="log-btn" />
                </button>
              )}
            </div>
          </form>
          {loginSuccess == true && (
            <Modal
              centered={true}
              className="centernow"
              footer={null}
              header={null}
              closeIcon={false}
              style={{
                translate: "transform(-50% -50%)",
              }}
              closable="false"
              open={loginSuccess}
              okButtonProps="false"
              // onCancel={() => setIsModalOpen(false)}
            >
              {loginSuccess && (
                <LoginPopUp
                  handleSuccessPopup={handleSuccessPopup}
                  Message={"Login Successfull"}
                />
              )}
            </Modal>
          )}

          {loginfalse == true && (
            <Modal
              centered={true}
              className="centernow"
              footer={null}
              header={null}
              closeIcon={false}
              style={{
                translate: "transform(-50% -50%)",
              }}
              closable="false"
              open={loginfalse}
              okButtonProps="false"
            >
              {loginfalse && (
                <UnSuccessError
                  handleUnSuccessPopup={handleUnSuccessPopup}
                  Message={"Login Failed"}
                />
              )}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};
