import { Pagination } from "antd";
import React from "react";

export const PaginationCreated = ({
  setEntries,
  setPageNo,
  searchChange,
  children,
  pageNo,
  onChange,
  totelCount,
}) => {
  return (
    <>
      <div className="AO-2nd">
        <div className="AO-2nd-entries">
          Show
          <select
            className="AO-2nd-ent-select"
            onChange={(e) => {
              setEntries(e.target.value);
              setPageNo(1);
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </select>
          entries
        </div>
        <div className="AO-2nd-search">
          <input
            type="text"
            className="AO-search"
            placeholder="Search..."
            onChange={searchChange}
          />
        </div>
      </div>
      <div className="AO-OL">
        <div className="AO-OL-1st">
          Total Active Orders - {totelCount}
        </div>
      </div>

      {children}
      <div className="pagination">
        <Pagination current={pageNo} onChange={onChange} total={totelCount} />
      </div>
    </>
  );
};
